<template>
  <div>
    <span class="label">
      Modalità
      <span class="light" :class="theme=='light' ? 'active' : ''" @click="toggleTheme('light')"></span>
      <span class="dark" :class="theme=='dark' ? 'active' : ''" @click="toggleTheme('dark')"></span>
    </span>
  </div>
</template>

<script>
  export default {
    props: {
      current_user: Object,
    },

    data(){
      return {
        theme: this.current_user.theme,
      }
    },

    methods: {
      toggleTheme(theme){
        if(this.theme!=theme){
          document.getElementById('body').classList.toggle('dark-mode');
          var params = {theme: theme};
          this.axios.put('/api/users/'+this.current_user.id, params).then(response => {
            var obj = response.data;
            if(obj.data.success){
              this.theme = theme;
            }
            else{
              console.log(obj);
            }
          });
        }
      }
    }
  }
</script>