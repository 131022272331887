<template>
  <div>
    <select :class="modelValue===null ? 'wo-value' : ''" :id="id" :name="name" :value="modelValue" :disabled="disabled" @change="emitValue">
      <template v-if="placeholder && placeholder!=''">
        <option value="">{{placeholder}}</option>
      </template>
      <option v-for="option in options" :value="option.value">{{option.localized_label}}</option>
    </select>
  </div>
</template>

<script>
  export default {
    props: {
      placeholder: String,
      id: String,
      name: String,
      options: Array,
      disabled: Boolean,
      modelValue: Number,
    },

    data(){
      return {

      }
    },

    methods: {
      emitValue($event){
        var emitted_value = $event.target.value!=="" ? parseInt($event.target.value) : null;
        this.$emit('update:modelValue', emitted_value);
      },
    }
  }
</script>