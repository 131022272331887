<template>
  <div class="delete-wrapper">
    <span class="icon" @click="toggleBoxShown()"></span>
    <div class="box-wrapper" v-if="box_shown">
      <h4>Sei sicuro di voler eliminare il CEP?</h4>
      <span class="save-btn" @click="deleteCep()">Elimina</span>
    </div>
  </div>
</template>

<script>
  import * as helperFunctions from "../../helpers/helpers"

  export default {
    props: {
      current_user: Object,
      cep_id: Number,
    },

    data(){
      return {
        box_shown: false,
      }
    },

    methods: {
      ...helperFunctions,

      toggleBoxShown(){
        this.box_shown = !this.box_shown;
      },

      deleteCep(){
        this.axios.delete('/api/ceps/'+this.cep_id).then(response => {
          var obj = response.data;
          if(obj.data.success){
            window.location.href = '/ceps';
          }
          else{
            console.log(obj);
          }
        });
      },
    }
  }
</script>