<template>
  <div class="autocomplete-wrapper">
    <input :id="id" :name="name" type="text" :placeholder="placeholder" v-model="search_term" @focus="addFocus()" @blur="removeFocus()">
    <ul v-if="on_focus && results && results.length>0">
      <li v-for="result in results" @click="selectValue(result)">{{result[view_field]}}</li>
    </ul>
    <ul v-if="on_focus && results && results.length==0">
      <li class="not-clickable">Nessun risultato</li>
    </ul>
  </div>
</template>

<script>
  export default {
    props: {
      id: String,
      name: String,
      placeholder: String,
      list: Array,
      search_fields: Array,
      value_field: String,
      view_field: String,
      modelValue: Number,
    },

    data(){
      return {
        search_term: '',
        awaiting_search: false,
        select_flag: false,

        on_focus: false,

        results: [],
      }
    },

    watch: {
      search_term: function(val){
        if(!this.select_flag){
          if(!this.awaiting_search){
            setTimeout(() => {
              this.searchInList();
              this.awaiting_search = false;
            }, 750);
          }
          this.awaiting_search = true;
        }
      },
    },

    created(){
      this.searchInList();
    },

    methods: {
      addFocus(){
        this.on_focus = true;
      },

      removeFocus(){
        setTimeout(function(){
          this.on_focus = false;
        }.bind(this), 250)
      },

      filterValues(item){
        var flag = false;
        var search_term = this.search_term.toLowerCase();
        this.search_fields.forEach(function(field){
          if(item[field] && item[field].toLowerCase().includes(search_term)){
            flag = true;
          }
        });
        return flag;
      },

      selectValue(result){
        this.select_flag = true;
        this.search_term = result[this.view_field];
        this.$emit('update:modelValue', result[this.value_field]);
        this.results = [];
        setTimeout(() => {
          this.select_flag = false;
        }, 900);
      },

      searchInList(){
        var results = [];
        // if(this.search_term && this.search_term.length>0){
          results = this.list.filter(this.filterValues);
        // }
        this.results = results;
      },
    }
  }
</script>