<template>
  <div class="cep-form-handler">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12 col-md-3">
          <a class="project-code" :class="status" :href="'/ceps/'+cep.id" :title="cep.project_code">{{cep.project_code}}</a>
        </div>
        <div class="col-12 col-md-4">
          <div class="alert-number-wrapper">
            <span class="alert-number" v-if="alert_number>0"><strong>Attenzione!</strong> Controlla gli avvisi</span>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <span class="label">Prezzo di vendita</span>
          <span class="value">{{$niceMoney(total_price)}}</span>
        </div>
        <div class="col-12 col-md-2">
          <span class="save-btn" :class="btn_class" @click="saveCep()">Salva dati</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as helperFunctions from "../../helpers/helpers"

  export default {
    props: {
      cep: Object,
      alert_number: Number,
      total_price: Number,
      status: String,
    },

    data(){
      return {
        btn_class: '',
      }
    },

    methods: {
      ...helperFunctions,

      saveCep(){
        this.btn_class = 'loading';
        this.$emit('saveCep');
      },
    }
  }
</script>