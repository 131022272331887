<template>
  <div>
    <div class="row">
      <div class="col-12">
        <p class="section-ot">Link di progetto</p>
        <div class="form-box-wrapper">
          <div class="row">
            <div class="col-12">
              <div class="value-row">
                <div class="row">
                  <div class="col-12 col-md-3">
                    <label for="drive_url">Google drive url</label>
                    <CInput id="drive_url" name="drive_url" placeholder="https://..." :readonly="false" v-model="cep_data.drive_url"></CInput>
                  </div>
                  <div class="col-12 col-md-3">
                    <label for="ht_url">Hard Turn url</label>
                    <CInput id="ht_url" name="ht_url" placeholder="https://..." :readonly="false" v-model="cep_data.ht_url"></CInput>
                  </div>
                  <div class="col-12 col-md-3">
                    <label for="ac_url">Active Collab url</label>
                    <CInput id="ac_url" name="ac_url" placeholder="https://..." :readonly="false" v-model="cep_data.ac_url"></CInput>
                  </div>
                  <div class="col-12 col-md-3">
                    <label for="quote_url">Preventivo url</label>
                    <CInput id="quote_url" name="quote_url" placeholder="https://..." :readonly="false" v-model="cep_data.quote_url"></CInput>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ctm-banner success" :class="success_banner_class">
      <p>Le modifiche sono state salvate con successo</p>
    </div>
    <div class="ctm-banner error" :class="error_banner_class">
      <p>Si è verificato un problema durante il salvataggio delle modifiche</p>
    </div>
  </div>
</template>

<script>
  import CInput from '../form/CInput.vue'

  export default {
    components:{
      CInput,
    },

    props: {
      cep: Object,
    },

    data(){
      return {
        ctm_json: null,

        cep_data: {
          drive_url: this.cep.drive_url,
          ht_url: this.cep.ht_url,
          ac_url: this.cep.ac_url,
          quote_url: this.cep.quote_url,
        },

        btn_class: '',
        success_banner_class: '',
        error_banner_class: '',
      }
    },

    watch: {
      ctm_json: function(){
        if(ctm_json){
          var parsed_json = JSON.parse(this.ctm_json);
          var keys = [
            'drive_url',
            'ht_url',
            'ac_url',
            'quote_url',
          ];

          for(var i=0;i<keys.length;i++){
            var key = keys[i];
            this.cep_data[key] = parsed_json.in[key];
          }
        }
      },
    },

    methods: {
      saveCep(){
        this.btn_class = 'loading';
        var params = {
          cep: {
            // Comp section
              comp_section: 'l',
            // In
              drive_url: this.cep_data.drive_url,
              ht_url: this.cep_data.ht_url,
              ac_url: this.cep_data.ac_url,
              quote_url: this.cep_data.quote_url,
          }
        };
        var inst = this;
        return new Promise(resolve => {
          inst.axios.put('/api/ceps/'+inst.cep.id, params).then(response => {
            var obj = response.data;
            if(obj.data.success){
              inst.btn_class = '';
              inst.success_banner_class = 'shown';
              setTimeout(() => inst.success_banner_class = '', 3000);
              resolve({data: {status: 'Ok', message: 'L\'operazione è stata eseguita con successo'}});
            }
            else{
              inst.btn_class = '';
              inst.error_banner_class = 'shown';
              setTimeout(() => inst.error_banner_class = '', 3000);
              resolve({data: {status: 'Error', message: 'Si è verificato un errore'}});
            }
          });
        });
      }
    }
  }
</script>
