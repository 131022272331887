<template>
  <div>
    <div class="row">
      <div class="col-12">
        <p class="section-ot">Link di progetto</p>
        <div class="form-box-wrapper">
          <div class="row">
            <div class="col-12">
              <div class="value-row">
                <div class="row">
                  <div class="col-12 col-md-3">
                    <label for="drive_url">Google drive url</label>
                    <span class="visual-value">{{$niceField(cep_data.drive_url)}}</span>
                  </div>
                  <div class="col-12 col-md-3">
                    <label for="ht_url">Hard Turn url</label>
                    <span class="visual-value">{{$niceField(cep_data.ht_url)}}</span>
                  </div>
                  <div class="col-12 col-md-3">
                    <label for="ac_url">Active Collab url</label>
                    <span class="visual-value">{{$niceField(cep_data.ac_url)}}</span>
                  </div>
                  <div class="col-12 col-md-3">
                    <label for="quote_url">Preventivo url</label>
                    <span class="visual-value">{{$niceField(cep_data.quote_url)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as helperFunctions from "../../helpers/helpers"

  export default {
    props: {
      cep: Object,
    },

    data(){
      return {
        cep_data: {
          drive_url: this.cep.drive_url,
          ht_url: this.cep.ht_url,
          ac_url: this.cep.ac_url,
          quote_url: this.cep.quote_url,
        },
      }
    },

    methods: {
      ...helperFunctions,
    }
  }
</script>