<template>
  <div>
    <div class="filters-wrapper">
      <CSelect id="status" name="status" placeholder="Stato" :options="cep_statuses" :disabled="false" v-model.number="filters.status" @change="getInitValues('filters')"></CSelect>
      <CSelect id="ea_id" name="ea_id" placeholder="EA" :options="eas" :disabled="false" v-model.number="filters.ea_id" @change="getInitValues('filters')"></CSelect>
      <CSelect id="pl_id" name="pl_id" placeholder="PL" :options="pls" :disabled="false" v-model.number="filters.pl_id" @change="getInitValues('filters')"></CSelect>
    </div>
    <template v-if="ceps.length>0">
      <table class="table-wrapper">
        <thead>
          <tr>
            <th>Cod. progetto</th>
            <th>Stato</th>
            <th>EA</th>
            <th>PL</th>
            <th>Venduto anno {{cea.year}}</th>
            <th>Fatturato anno {{cea.year}}</th>
            <th>Incassato anno {{cea.year}}</th>
          </tr>
        </thead>
        <tbody>
          <tr class="table-row table-content" v-for="cep in ceps">
            <td class="table-cell p-code">{{$niceField(cep.project_code)}}</td>
            <td class="table-cell status"><span class="status" :class="cep.status">{{$getEnumLabel(cep_statuses, cep.status)}}</span></td>
            <td class="table-cell">{{$niceField(cep.ea_name)}}</td>
            <td class="table-cell">{{$niceField(cep.pl_name)}}</td>
            <td class="table-cell">{{$niceMoney(cep.sold)}}</td>
            <td class="table-cell">{{$niceMoney(cep.tover)}}</td>
            <td class="table-cell">{{$niceMoney(cep.coll)}}</td>
          </tr>
        </tbody>
      </table>
      <div class="pagination" v-if="page_number>1">
        <span class="prev" @click="prevPage"></span>
        <span v-for="page in page_range" class="item" :class="page==current_page ? 'active' : ''" @click="selectPage(page)">{{page}}</span>
        <span class="next" @click="nextPage"></span>
        <span class="total-page">di {{page_number}}</span>
      </div>
    </template>
    <template v-else>
      <p class="no-rows" v-if="ceps.length==0">La ricerca non ha prodotto alcun risultato.</p>
    </template>
  </div>
</template>

<script>
  import CSelect from "../form/CSelect.vue"

  import * as helperFunctions from "../../helpers/helpers"

  export default {
    components:{
      CSelect,
    },

    props: {
      cea: Object,
      users: Array,
      cep_statuses: Array,
    },

    data(){
      return {
        ceps: [],

        filters: {
          status: null,
          ea_id: null,
          pl_id: null,
        },

        limit: 50,
        offset: 0,
        current_page: 1,
        page_number: 0,
        page_range: [],
        page_shown: 7,
      }
    },

    created(){
      this.getInitValues();
    },

    computed: {
      eas: function(){
        return this.users.filter((item) => { return item.is_ea; }).map((item) => { return {label: this.$niceUserFullName(item), value: item.id, localized_label: this.$niceUserFullName(item)}; });
      },
      pls: function(){
        return this.users.filter((item) => { return item.is_pl; }).map((item) => { return {label: this.$niceUserFullName(item), value: item.id, localized_label: this.$niceUserFullName(item)}; });
      },
    },

    methods: {
      ...helperFunctions,

      prevPage(){
        if(this.current_page>1){
          this.current_page--;
          this.offset--;
          this.getInitValues();
        }
      },

      selectPage(page_index){
        if(this.current_page!=page_index){
          this.current_page=page_index;
          this.offset=page_index-1;
          this.getInitValues();
        }
      },

      nextPage(){
        if(this.current_page<this.page_number){
          this.current_page++;
          this.offset++;
          this.getInitValues();
        }
      },

      calculatePageRange(){
        var temp_page_range = [];
        var start = 0;
        var end = this.page_shown;
        if(this.page_number>this.page_shown){
          if(this.current_page<4){
            start = 0;
            end = this.page_shown;
          }
          else if(this.current_page>(this.page_number-4)){
            start = this.page_number-this.page_shown;
            end = this.page_number;
          }
          else{
            start = this.current_page-Math.ceil(this.page_shown/2);
            end = this.current_page+Math.floor(this.page_shown/2);
          }
        }
        else{
          end = this.page_number;
        }
        for(var i=start;i<end;i++){
          temp_page_range.push(parseInt(i+1));
        }
        this.page_range = temp_page_range;
      },

      calculateOffset(){
        return this.limit*this.offset;
      },

      getInitValues(type=null){
        if(type=='filters'){
          this.offset = 0;
        }
        var params = {params: {limit: this.limit, offset: this.calculateOffset(), filters: JSON.parse(JSON.stringify(this.filters))}};
        this.axios.get('/api/ceas/'+this.cea.id+'/ceps', params).then(response => {
          var obj = response.data;
          if(obj.data.success){
            this.ceps = obj.data.ceps;

            if(type=='filters'){
              this.current_page = 1;
            }

            this.page_number = Math.ceil(obj.data.ceps_count/this.limit);
            this.calculatePageRange();
          }
          else{
            console.log(obj);
          }
        });
      },
    }
  }
</script>