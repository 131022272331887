<template>
  <div>
    <textarea :id="id" :name="name" :placeholder="placeholder" :value="modelValue" :readonly="readonly" @change="emitValue"></textarea>
  </div>
</template>

<script>
  export default {
    props: {
      placeholder: String,
      id: String,
      name: String,
      readonly: Boolean,
      modelValue: String,
    },

    data(){
      return {

      }
    },

    methods: {
      emitValue($event){
        var emitted_value = $event.target.value!=="" ? $event.target.value : null;
        this.$emit('update:modelValue', emitted_value);
      },
    }
  }
</script>