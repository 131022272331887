<template>
  <div>
    <input :id="id" :name="name" type="number" :placeholder="placeholder" :value="modelValue" :readonly="readonly" @change="emitValue">
  </div>
</template>

<script>
  export default {
    props: {
      placeholder: String,
      id: String,
      name: String,
      readonly: Boolean,
      modelValue: Number,
    },

    data(){
      return {

      }
    },

    methods: {
      emitValue($event){
        var emitted_value = $event.target.value!=="" ? $event.target.value : null;
        this.$emit('update:modelValue', emitted_value);
      },
    }
  }
</script>

<style scoped>
  div{
    position:relative;
  }
  div>input{
    padding-right:20px;
  }
  div:after{
    right:0;
    top:50%;
    width:20px;
    content:"h";
    font-size:14px;
    text-align:center;
    position:absolute;
    transform:translateY(-50%);
  }
</style>