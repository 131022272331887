<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-8">
        <p class="section-ot">Dettaglio costi diretti</p>
        <div class="form-box-wrapper purple" :class="expanded_boxes.includes('sales_01') ? 'expanded' : ''">
          <span class="expand-btn" @click="toggleExpandBox('sales_01')"></span>
          <div class="heading">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p><strong>0.1</strong> Sales <br><span>Fee commerciali standard</span></p>
              </div>
              <div class="col-12 col-md-4">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceMoney(calcd_01_tot_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceIntPerc(calcd_01_tot_perc)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="expanded_boxes.includes('sales_01')">
            <hr>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label>Prezzo senza 0.1, 2.4 e 2.5</label>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(calcd_01_prezzo_senza)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label>Fee commerciale</label>
                  <template v-if="current_user && current_user.role=='admin'">
                    <div class="row">
                      <div class="col-12">
                        <label for="is_without_fee_comm">Nessuna Fee commerciale da riconoscere</label>
                        <select name="is_without_fee_comm" v-model="is_without_fee_comm" @change="updateNewAndUpSellPerc()">
                          <option :value="0">No</option>
                          <option :value="1">Si</option>
                        </select>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(calcd_01_fee_comm)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_01_new_sell_ext_who_partner_id">New sell esterno (Chiunque tranne EA di progetto o Twig)</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <CSelect id="in_01_new_sell_ext_who_partner_id" name="in_01_new_sell_ext_who_partner_id" placeholder="Seleziona un partner" :options="new_sell_partners" :disabled="false" v-model.number="in_01_new_sell_ext_who_partner_id"></CSelect>
                    </div>
                    <div class="col-12 col-md-4">
                      <CSelect id="in_01_new_sell_ext_kind" name="in_01_new_sell_ext_kind" :options="item_general_kinds" :disabled="true" v-model.number="in_01_new_sell_ext_kind"></CSelect>
                    </div>
                    <div class="col-12 col-md-4">
                      <CInputPerc id="in_01_new_sell_ext_perc" name="in_01_new_sell_ext_perc" placeholder="0,00" :readonly="false" v-model.number="in_01_new_sell_ext_perc"></CInputPerc>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(calcd_01_new_sell_ext_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(calcd_01_new_sell_tot_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_01_new_sell_oth_who_id">New sell interno (Altri: chiunque di Twig tranne EA di progetto)</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <CSelect id="in_01_new_sell_oth_who_id" name="in_01_new_sell_oth_who_id" placeholder="Chi" :options="new_sell_oth_who_list" :disabled="false" v-model.number="in_01_new_sell_oth_who_id"></CSelect>
                    </div>
                    <div class="col-12 col-md-4">
                      <CSelect id="in_01_new_sell_oth_kind" name="in_01_new_sell_oth_kind" :options="item_general_kinds" :disabled="true" v-model.number="in_01_new_sell_oth_kind"></CSelect>
                    </div>
                    <div class="col-12 col-md-4">
                      <CInputPerc id="in_01_new_sell_oth_perc" name="in_01_new_sell_oth_perc" placeholder="0,00" :readonly="false" v-model.number="in_01_new_sell_oth_perc"></CInputPerc>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(calcd_01_new_sell_oth_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_01_new_sell_int_who_pl_id">New sell interno (EA: quando è anche EA di progetto)</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <CSelect id="in_01_new_sell_int_who_pl_id" name="in_01_new_sell_int_who_pl_id" placeholder="Chi" :options="eas" :disabled="false" v-model.number="in_01_new_sell_int_who_pl_id"></CSelect>
                    </div>
                    <div class="col-12 col-md-4">
                      <CSelect id="in_01_new_sell_int_kind" name="in_01_new_sell_int_kind" :options="item_general_kinds" :disabled="true" v-model.number="in_01_new_sell_int_kind"></CSelect>
                    </div>
                    <div class="col-12 col-md-4">
                      <CInputPerc id="in_01_new_sell_int_perc" name="in_01_new_sell_int_perc" placeholder="0,00" :readonly="false" v-model.number="in_01_new_sell_int_perc"></CInputPerc>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(calcd_01_new_sell_int_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_01_upsell_int_who_pl_id">Upsell interno (EA/PL)</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <CSelect id="in_01_upsell_int_who_pl_id" name="in_01_upsell_int_who_pl_id" placeholder="Chi" :options="null_or_ea" :disabled="false" v-model.number="in_01_upsell_int_who_pl_id"></CSelect>
                    </div>
                    <div class="col-12 col-md-4">
                      <CSelect id="in_01_upsell_int_kind" name="in_01_upsell_int_kind" :options="item_general_kinds" :disabled="true" v-model.number="in_01_upsell_int_kind"></CSelect>
                    </div>
                    <div class="col-12 col-md-4">
                      <CInputPerc id="in_01_upsell_int_perc" name="in_01_upsell_int_perc" placeholder="0,00" :readonly="false" v-model.number="in_01_upsell_int_perc"></CInputPerc>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(calcd_01_upsell_int_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6"></div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="form-box-wrapper purple" :class="expanded_boxes.includes('sales_02') ? 'expanded' : ''">
          <span class="expand-btn" @click="toggleExpandBox('sales_02')"></span>
          <div class="heading">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p><strong>0.2</strong> Sales <br><span>Plusvalore per partner</span></p>
              </div>
              <div class="col-12 col-md-4">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceMoney(calcd_02_extra_margine_tot_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceIntPerc(calcd_02_extra_margine_tot_perc)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="expanded_boxes.includes('sales_02')">
            <hr>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_02_extra_margine_partner_who_partner_id">Extra-margine per partner</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <CSelect id="in_02_extra_margine_partner_who_partner_id" name="in_02_extra_margine_partner_who_partner_id" placeholder="Seleziona un partner" :options="extra_margine_partners" :disabled="false" v-model.number="in_02_extra_margine_partner_who_partner_id"></CSelect>
                    </div>
                    <div class="col-12 col-md-4">
                      <CSelect id="in_02_extra_margine_partner_kind" name="in_02_extra_margine_partner_kind" :options="item_general_kinds" :disabled="true" v-model.number="in_02_extra_margine_partner_kind"></CSelect>
                    </div>
                    <div class="col-12 col-md-4">
                      <CInputMoney id="in_02_extra_margine_partner_eur" name="in_02_extra_margine_partner_eur" placeholder="0,00" :readonly="false" v-model.number="in_02_extra_margine_partner_eur"></CInputMoney>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="form-box-wrapper purple" :class="expanded_boxes.includes('sales_03') ? 'expanded' : ''">
          <span class="expand-btn" @click="toggleExpandBox('sales_03')"></span>
          <div class="heading">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p><strong>0.3</strong> Sales <br><span>Extra-margine</span></p>
              </div>
              <div class="col-12 col-md-4">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceMoney(calcd_03_extra_margine_bonus_tot_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceIntPerc(calcd_03_extra_margine_bonus_tot_perc)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="expanded_boxes.includes('sales_03')">
            <hr>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_03_extra_margine_bonus_who_user_id">Bonus 25% sull'extra-margine (EA)</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <CSelect id="in_03_extra_margine_bonus_who_user_id" name="in_03_extra_margine_bonus_who_user_id" placeholder="Seleziona un'opzione" :options="null_or_ea" :disabled="false" v-model.number="in_03_extra_margine_bonus_who_user_id"></CSelect>
                    </div>
                    <div class="col-12 col-md-4">
                      <CSelect id="in_03_extra_margine_bonus_kind" name="in_03_extra_margine_bonus_kind" :options="item_general_kinds" :disabled="true" v-model.number="in_03_extra_margine_bonus_kind"></CSelect>
                    </div>
                  </div>
                  <!-- <div class="row">
                    <div class="col-12 col-md-4">
                      <label for="is_pl">PL senza EA</label>
                      <select name="is_pl" v-model="is_pl">
                        <option :value="false">No</option>
                        <option :value="true">Si</option>
                      </select>
                    </div>
                  </div> -->
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(calcd_03_extra_margine_bonus_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6"></div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="form-box-wrapper light-blue" :class="expanded_boxes.includes('direzione_11') ? 'expanded' : ''">
          <span class="expand-btn" @click="toggleExpandBox('direzione_11')"></span>
          <div class="heading">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p><strong>1.1</strong> Direzione <br><span>Executive Advisor</span></p>
              </div>
              <div class="col-12 col-md-4">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceMoney(calcd_11_direzione_tot_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceIntPerc(calcd_11_direzione_tot_perc)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="expanded_boxes.includes('direzione_11')">
            <hr>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_11_direzione_team_fisso_kind">Team fisso</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <CSelect id="in_11_direzione_team_fisso_kind" name="in_11_direzione_team_fisso_kind" :options="item_general_kinds" :disabled="true" v-model.number="in_11_direzione_team_fisso_kind"></CSelect>
                    </div>
                    <div class="col-12 col-md-4">
                      <CInputPerc id="in_11_direzione_team_fisso_input_perc" name="in_11_direzione_team_fisso_input_perc" placeholder="0,00" :readonly="false" v-model.number="in_11_direzione_team_fisso_input_perc"></CInputPerc>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(calcd_11_direzione_team_fisso_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(calcd_11_direzione_team_fisso_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_11_direzione_team_piva_kind">Team a p.iva</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <CSelect id="in_11_direzione_team_piva_kind" name="in_11_direzione_team_piva_kind" :options="item_general_kinds" :disabled="true" v-model.number="in_11_direzione_team_piva_kind"></CSelect>
                    </div>
                    <div class="col-12 col-md-4">
                      <CInputPerc id="in_11_direzione_team_piva_input_perc" name="in_11_direzione_team_piva_input_perc" placeholder="0,00" :readonly="false" v-model.number="in_11_direzione_team_piva_input_perc"></CInputPerc>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(calcd_11_direzione_team_piva_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(calcd_11_direzione_team_piva_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_11_direzione_fornit_kind">Fornitori</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <CSelect id="in_11_direzione_fornit_kind" name="in_11_direzione_fornit_kind" :options="item_general_kinds" :disabled="true" v-model.number="in_11_direzione_fornit_kind"></CSelect>
                    </div>
                    <div class="col-12 col-md-4">
                      <CInputPerc id="in_11_direzione_fornit_input_perc" name="in_11_direzione_fornit_input_perc" placeholder="0,00" :readonly="false" v-model.number="in_11_direzione_fornit_input_perc"></CInputPerc>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(calcd_11_direzione_fornit_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(calcd_11_direzione_fornit_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_11_direzione_consul_kind">CT - Consulenza</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <CSelect id="in_11_direzione_consul_kind" name="in_11_direzione_consul_kind" :options="item_general_kinds" :disabled="true" v-model.number="in_11_direzione_consul_kind"></CSelect>
                    </div>
                    <div class="col-12 col-md-4">
                      <CInputMoney id="in_11_direzione_consul_input_eur" name="in_11_direzione_consul_input_eur" placeholder="0,00" :readonly="false" v-model.number="in_11_direzione_consul_input_eur"></CInputMoney>
                    </div>
                    <div class="col-12 col-md-4">
                      <CInputHour id="in_11_direzione_consul_input_h_qty" name="in_11_direzione_consul_input_h_qty" placeholder="0,00" :readonly="false" v-model.number="in_11_direzione_consul_input_h_qty"></CInputHour>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(calcd_11_direzione_consul_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(calcd_11_direzione_consul_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="form-box-wrapper green" :class="expanded_boxes.includes('produzione_21') ? 'expanded' : ''">
          <span class="expand-btn" @click="toggleExpandBox('produzione_21')"></span>
          <div class="heading">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p><strong>2.1</strong> Produzione <br><span>Team a stipendio</span></p>
              </div>
              <div class="col-12 col-md-4">
                <div class="row">
                  <div class="col-12 col-md-4">
                    <span class="value">{{$niceNumber(calcd_21_prod_team_stip_tot_h)}} h</span>
                  </div>
                  <div class="col-12 col-md-4">
                    <span class="value">{{$niceMoney(calcd_21_prod_team_stip_tot_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-4">
                    <span class="value">{{$niceIntPerc(calcd_21_prod_team_stip_tot_perc)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="expanded_boxes.includes('produzione_21')">
            <hr>
            <template v-for="(prod_team_stip_line, index) in prod_team_stip_lines">
              <div class="value-row">
                <div class="row align-items-center">
                  <div class="col-12 col-md-8">
                    <template v-if="index==0">
                      <label for="in_21_prod_team_stip_act_type">Attività</label>
                    </template>
                    <div class="repeatable-wrapper">
                      <div class="row">
                        <div class="col-12 col-md-4">
                          <CSelect id="in_21_prod_team_stip_act_type" name="in_21_prod_team_stip_act_type" placeholder="Tipologia" :options="stip_act_types" :disabled="true" v-model.number="prod_team_stip_line.in_21_prod_team_stip_act_type"></CSelect>
                        </div>
                        <template v-if="prod_team_stip_line.in_21_prod_team_stip_act_type!==null">
                          <div class="col-12 col-md-4">
                            <CInput id="in_21_prod_team_stip_who_str" name="in_21_prod_team_stip_who_str" placeholder="Chi" :readonly="false" v-model="prod_team_stip_line.in_21_prod_team_stip_who_str"></CInput>
                          </div>
                          <div class="col-12 col-md-4">
                            <CSelect id="in_21_prod_team_stip_kind" name="in_21_prod_team_stip_kind" :options="item_general_kinds" :disabled="true" v-model.number="prod_team_stip_line.in_21_prod_team_stip_kind"></CSelect>
                          </div>
                          <div class="col-12 col-md-4">
                            <CInputMoney id="in_21_prod_team_stip_h_cost_eur" name="in_21_prod_team_stip_h_cost_eur" placeholder="0,00" :readonly="false" v-model.number="prod_team_stip_line.in_21_prod_team_stip_h_cost_eur"></CInputMoney>
                          </div>
                          <div class="col-12 col-md-4">
                            <CInputHour id="in_21_prod_team_stip_h_qty" name="in_21_prod_team_stip_h_qty" placeholder="0,00" :readonly="false" v-model.number="prod_team_stip_line.in_21_prod_team_stip_h_qty"></CInputHour>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="row">
                      <div class="col-12 col-md-4"></div>
                      <div class="col-12 col-md-4">
                        <span class="value">{{$niceMoney(calcd_21_prod_team_stip_eur[index])}}</span>
                      </div>
                      <div class="col-12 col-md-4">
                        <span class="value">{{$niceIntPerc(calcd_21_prod_team_stip_perc[index])}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
        <div class="form-box-wrapper green" :class="expanded_boxes.includes('produzione_22') ? 'expanded' : ''">
          <span class="expand-btn" @click="toggleExpandBox('produzione_22')"></span>
          <div class="heading">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p><strong>2.2</strong> Produzione <br><span>Team a p.iva</span></p>
              </div>
              <div class="col-12 col-md-4">
                <div class="row">
                  <div class="col-12 col-md-4">
                    <span class="value">{{$niceNumber(calcd_22_prod_team_piva_tot_h)}} h</span>
                  </div>
                  <div class="col-12 col-md-4">
                    <span class="value">{{$niceMoney(calcd_22_prod_team_piva_tot_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-4">
                    <span class="value">{{$niceIntPerc(calcd_22_prod_team_piva_tot_perc)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="expanded_boxes.includes('produzione_22')">
            <hr>
            <template v-for="(prod_team_piva_line, index) in prod_team_piva_lines">
              <div class="value-row">
                <div class="row align-items-center">
                  <div class="col-12 col-md-8">
                    <template v-if="index==0">
                      <label for="in_22_prod_team_piva_act_type">Attività</label>
                    </template>
                    <div class="repeatable-wrapper">
                      <div class="row">
                        <div class="col-12 col-md-4">
                          <CSelect id="in_22_prod_team_piva_act_type" name="in_22_prod_team_piva_act_type" placeholder="Tipologia" :options="stip_act_types" :disabled="false" v-model.number="prod_team_piva_line.in_22_prod_team_piva_act_type"></CSelect>
                        </div>
                        <template v-if="prod_team_piva_line.in_22_prod_team_piva_act_type!==null">
                          <div class="col-12 col-md-4">
                            <CSelect :class="$getRequiredFieldClass(prod_team_piva_line.in_22_prod_team_piva_who_user_id)" id="in_22_prod_team_piva_who_user_id" name="in_22_prod_team_piva_who_user_id" placeholder="Chi" :options="prod_team_piva_users" :disabled="false" v-model.number="prod_team_piva_line.in_22_prod_team_piva_who_user_id"></CSelect>
                          </div>
                          <div class="col-12 col-md-4">
                            <CSelect id="in_22_prod_team_piva_kind" name="in_22_prod_team_piva_kind" :options="item_general_kinds" :disabled="true" v-model.number="prod_team_piva_line.in_22_prod_team_piva_kind"></CSelect>
                          </div>
                          <div class="col-12 col-md-4">
                            <CInputMoney :class="$getRequiredFieldClass(prod_team_piva_line.in_22_prod_team_piva_h_cost_eur)" id="in_22_prod_team_piva_h_cost_eur" name="in_22_prod_team_piva_h_cost_eur" placeholder="0,00" :readonly="false" v-model.number="prod_team_piva_line.in_22_prod_team_piva_h_cost_eur"></CInputMoney>
                          </div>
                          <div class="col-12 col-md-4">
                            <CInputHour :class="$getRequiredFieldClass(prod_team_piva_line.in_22_prod_team_piva_h_qty)" id="in_22_prod_team_piva_h_qty" name="in_22_prod_team_piva_h_qty" placeholder="0,00" :readonly="false" v-model.number="prod_team_piva_line.in_22_prod_team_piva_h_qty"></CInputHour>
                          </div>
                        </template>
                      </div>
                      <span class="remove-row" @click="removeProdLine('team_piva', index)"></span>
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="row">
                      <div class="col-12 col-md-4"></div>
                      <div class="col-12 col-md-4">
                        <span class="value">{{$niceMoney(calcd_22_prod_team_piva_eur[index])}}</span>
                      </div>
                      <div class="col-12 col-md-4">
                        <span class="value">{{$niceIntPerc(calcd_22_prod_team_piva_perc[index])}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <span class="add-row" @click="addProdLine('team_piva')"></span>
          </template>
        </div>
        <div class="form-box-wrapper green" :class="expanded_boxes.includes('produzione_23') ? 'expanded' : ''">
          <span class="expand-btn" @click="toggleExpandBox('produzione_23')"></span>
          <div class="heading">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p><strong>2.3</strong> Produzione <br><span>Fornitori a p.iva</span></p>
              </div>
              <div class="col-12 col-md-4">
                <div class="row">
                  <div class="col-12 col-md-4">
                  </div>
                  <div class="col-12 col-md-4">
                    <span class="value">{{$niceMoney(calcd_23_prod_fornit_piva_tot_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-4">
                    <span class="value">{{$niceIntPerc(calcd_23_prod_fornit_piva_tot_perc)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="expanded_boxes.includes('produzione_23')">
            <hr>
            <template v-for="(prod_fornit_piva_line, index) in prod_fornit_piva_lines">
              <div class="value-row">
                <div class="row align-items-center">
                  <div class="col-12 col-md-8">
                    <template v-if="index==0">
                      <label for="in_23_prod_fornit_piva_act_desc">Attività</label>
                    </template>
                    <div class="repeatable-wrapper">
                      <div class="row">
                        <div class="col-12 col-md-3">
                          <CInput id="in_23_prod_fornit_piva_act_desc" name="in_23_prod_fornit_piva_act_desc" placeholder="Descrizione" :readonly="false" v-model="prod_fornit_piva_line.in_23_prod_fornit_piva_act_desc"></CInput>
                        </div>
                        <template v-if="prod_fornit_piva_line.in_23_prod_fornit_piva_act_desc!==null">
                          <div class="col-12 col-md-3">
                            <CInput id="in_23_prod_fornit_piva_who_str" name="in_23_prod_fornit_piva_who_str" placeholder="Chi" :readonly="false" v-model="prod_fornit_piva_line.in_23_prod_fornit_piva_who_str"></CInput>
                          </div>
                          <div class="col-12 col-md-3">
                            <CSelect id="in_23_prod_fornit_piva_kind" name="in_23_prod_fornit_piva_kind" :options="item_general_kinds" :disabled="true" v-model.number="prod_fornit_piva_line.in_23_prod_fornit_piva_kind"></CSelect>
                          </div>
                          <div class="col-12 col-md-3">
                            <CInputMoney :class="$getRequiredFieldClass(prod_fornit_piva_line.in_23_prod_fornit_piva_eur)" id="in_23_prod_fornit_piva_eur" name="in_23_prod_fornit_piva_eur" placeholder="0,00" :readonly="false" v-model.number="prod_fornit_piva_line.in_23_prod_fornit_piva_eur"></CInputMoney>
                          </div>
                        </template>
                      </div>
                      <span class="remove-row" @click="removeProdLine('fornit_piva', index)"></span>
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="row">
                      <div class="col-12 col-md-4"></div>
                      <div class="col-12 col-md-4"></div>
                      <div class="col-12 col-md-4">
                        <span class="value">{{$niceIntPerc(calcd_23_prod_fornit_piva_perc[index])}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <span class="add-row" @click="addProdLine('fornit_piva')"></span>
          </template>
        </div>
        <div class="form-box-wrapper green" :class="expanded_boxes.includes('produzione_24') ? 'expanded' : ''">
          <span class="expand-btn" @click="toggleExpandBox('produzione_24')"></span>
          <div class="heading">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p><strong>2.4</strong> Produzione <br><span>Spazi media</span></p>
              </div>
              <div class="col-12 col-md-4">
                <div class="row">
                  <div class="col-12 col-md-4">
                  </div>
                  <div class="col-12 col-md-4">
                    <span class="value">{{$niceMoney(calcd_24_prod_media_tot_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-4">
                    <span class="value">{{$niceIntPerc(calcd_24_prod_media_tot_perc)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="expanded_boxes.includes('produzione_24')">
            <hr>
            <template v-for="(prod_media_line, index) in prod_media_lines">
              <div class="value-row">
                <div class="row align-items-center">
                  <div class="col-12 col-md-8">
                    <template v-if="index==0">
                      <label for="in_24_prod_media_desc">Attività</label>
                    </template>
                    <div class="repeatable-wrapper">
                      <div class="row">
                        <div class="col-12 col-md-3">
                          <CInput id="in_24_prod_media_desc" name="in_24_prod_media_desc" placeholder="Descrizione" :readonly="false" v-model="prod_media_line.in_24_prod_media_desc"></CInput>
                        </div>
                        <template v-if="prod_media_line.in_24_prod_media_desc!==null">
                          <div class="col-12 col-md-3">
                            <CInput id="in_24_prod_media_desc1" name="in_24_prod_media_desc1" placeholder="Canale" :readonly="false" v-model="prod_media_line.in_24_prod_media_desc1"></CInput>
                          </div>
                          <div class="col-12 col-md-3">
                            <CSelect id="in_24_prod_media_kind" name="in_24_prod_media_kind" :options="item_general_kinds" :disabled="true" v-model.number="prod_media_line.in_24_prod_media_kind"></CSelect>
                          </div>
                          <div class="col-12 col-md-3">
                            <CInputMoney :class="$getRequiredFieldClass(prod_media_line.in_24_prod_media_eur)" id="in_24_prod_media_eur" name="in_24_prod_media_eur" placeholder="0,00" :readonly="false" v-model.number="prod_media_line.in_24_prod_media_eur"></CInputMoney>
                          </div>
                        </template>
                      </div>
                      <span class="remove-row" @click="removeProdLine('media', index)"></span>
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="row">
                      <div class="col-12 col-md-4"></div>
                      <div class="col-12 col-md-4"></div>
                      <div class="col-12 col-md-4">
                        <span class="value">{{$niceIntPerc(calcd_24_prod_media_perc[index])}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <span class="add-row" @click="addProdLine('media')"></span>
          </template>
        </div>
        <div class="form-box-wrapper green" :class="expanded_boxes.includes('produzione_25') ? 'expanded' : ''">
          <span class="expand-btn" @click="toggleExpandBox('produzione_25')"></span>
          <div class="heading">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p><strong>2.5</strong> Produzione <br><span>Altri</span></p>
              </div>
              <div class="col-12 col-md-4">
                <div class="row">
                  <div class="col-12 col-md-4">
                  </div>
                  <div class="col-12 col-md-4">
                    <span class="value">{{$niceMoney(calcd_25_prod_others_tot_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-4">
                    <span class="value">{{$niceIntPerc(calcd_25_prod_others_tot_perc)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="expanded_boxes.includes('produzione_25')">
            <hr>
            <template v-for="(prod_others_line, index) in prod_others_lines">
              <div class="value-row">
                <div class="row align-items-center">
                  <div class="col-12 col-md-8">
                    <template v-if="index==0">
                      <label for="in_25_prod_others_type">Attività</label>
                    </template>
                    <div class="repeatable-wrapper">
                      <div class="row">
                        <div class="col-12 col-md-3">
                          <CSelect id="in_25_prod_others_type" name="in_25_prod_others_type" placeholder="Tipologia" :options="prod_others_types" :disabled="false" v-model.number="prod_others_line.in_25_prod_others_type"></CSelect>
                        </div>
                        <template v-if="prod_others_line.in_25_prod_others_type!==null">
                          <div class="col-12 col-md-3">
                            <CInput id="in_25_prod_others_desc" name="in_25_prod_others_desc" placeholder="Descrizione" :readonly="false" v-model="prod_others_line.in_25_prod_others_desc"></CInput>
                          </div>
                          <div class="col-12 col-md-3">
                            <CSelect id="in_25_prod_others_kind" name="in_25_prod_others_kind" :options="item_general_kinds" :disabled="true" v-model.number="prod_others_line.in_25_prod_others_kind"></CSelect>
                          </div>
                          <div class="col-12 col-md-3">
                            <CInputMoney :class="$getRequiredFieldClass(prod_others_line.in_25_prod_others_eur)" id="in_25_prod_others_eur" name="in_25_prod_others_eur" placeholder="0,00" :readonly="false" v-model.number="prod_others_line.in_25_prod_others_eur"></CInputMoney>
                          </div>
                        </template>
                      </div>
                      <span class="remove-row" @click="removeProdLine('others', index)"></span>
                    </div>
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="row">
                      <div class="col-12 col-md-4"></div>
                      <div class="col-12 col-md-4"></div>
                      <div class="col-12 col-md-4">
                        <span class="value">{{$niceIntPerc(calcd_25_prod_others_perc[index])}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <span class="add-row" @click="addProdLine('others')"></span>
          </template>
        </div>
        <p class="section-ot">Dettaglio costi indiretti</p>
        <div class="form-box-wrapper blue" :class="expanded_boxes.includes('gestione_12') ? 'expanded' : ''">
          <span class="expand-btn" @click="toggleExpandBox('gestione_12')"></span>
          <div class="heading">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p class="ns"><strong>1.2</strong> Gestione</p>
              </div>
              <div class="col-12 col-md-4">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceMoney(calcd_12_gest_tot_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceIntPerc(calcd_12_gest_tot_perc)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="expanded_boxes.includes('gestione_12')">
            <hr>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_12_gest_alloc_fisso_piva_kind">Allocazione team fisso/p.iva</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <CSelect id="in_12_gest_alloc_fisso_piva_kind" name="in_12_gest_alloc_fisso_piva_kind" :options="item_general_kinds" :disabled="true" v-model.number="in_12_gest_alloc_fisso_piva_kind"></CSelect>
                    </div>
                    <div class="col-12 col-md-4">
                      <CInputPerc id="in_12_gest_alloc_fisso_piva_input_perc" name="in_12_gest_alloc_fisso_piva_input_perc" placeholder="0,00" :readonly="false" v-model.number="in_12_gest_alloc_fisso_piva_input_perc"></CInputPerc>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(calcd_12_gest_alloc_fisso_piva_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(calcd_12_gest_alloc_fisso_piva_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_12_gest_superv_fornit_kind">Supervisione fornitori</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <CSelect id="in_12_gest_superv_fornit_kind" name="in_12_gest_superv_fornit_kind" :options="item_general_kinds" :disabled="true" v-model.number="in_12_gest_superv_fornit_kind"></CSelect>
                    </div>
                    <div class="col-12 col-md-4">
                      <CInputPerc id="in_12_gest_superv_fornit_input_perc" name="in_12_gest_superv_fornit_input_perc" placeholder="0,00" :readonly="false" v-model.number="in_12_gest_superv_fornit_input_perc"></CInputPerc>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(calcd_12_gest_superv_fornit_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(calcd_12_gest_superv_fornit_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_12_gest_superv_event_kind">Supervisione produzioni/eventi</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <CSelect id="in_12_gest_superv_event_kind" name="in_12_gest_superv_event_kind" :options="item_general_kinds" :disabled="true" v-model.number="in_12_gest_superv_event_kind"></CSelect>
                    </div>
                    <div class="col-12 col-md-4">
                      <CInputMoney id="in_12_gest_superv_event_eur" name="in_12_gest_superv_event_eur" placeholder="0,00" :readonly="false" v-model.number="in_12_gest_superv_event_eur"></CInputMoney>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6"></div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(calcd_12_gest_superv_event_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_12_gest_comp_plus_kind">Compensazione da plusvalenza</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <CSelect id="in_12_gest_comp_plus_kind" name="in_12_gest_comp_plus_kind" :options="item_general_kinds" :disabled="true" v-model.number="in_12_gest_comp_plus_kind"></CSelect>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(calcd_12_gest_comp_plus_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(calcd_12_gest_comp_plus_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="form-box-wrapper yellow" :class="expanded_boxes.includes('struttura_30') ? 'expanded' : ''">
          <span class="expand-btn" @click="toggleExpandBox('struttura_30')"></span>
          <div class="heading">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p class="ns"><strong>3.0</strong> Struttura</p>
              </div>
              <div class="col-12 col-md-4">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceMoney(calcd_3_struct_tot_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceIntPerc(calcd_3_struct_tot_perc)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="expanded_boxes.includes('struttura_30')">
            <hr>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_3_struct_dir_az_kind">Direzione aziendale</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <CSelect id="in_3_struct_dir_az_kind" name="in_3_struct_dir_az_kind" :options="item_general_kinds" :disabled="true" v-model.number="in_3_struct_dir_az_kind"></CSelect>
                    </div>
                    <div class="col-12 col-md-4">
                      <CInputPerc id="in_3_struct_dir_az_input_perc" name="in_3_struct_dir_az_input_perc" placeholder="0,00" :readonly="false" v-model.number="in_3_struct_dir_az_input_perc"></CInputPerc>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(calcd_3_struct_dir_az_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(calcd_3_struct_dir_az_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_3_struct_sede_kind">Sede operativa e servizi</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <CSelect id="in_3_struct_sede_kind" name="in_3_struct_sede_kind" :options="item_general_kinds" :disabled="true" v-model.number="in_3_struct_sede_kind"></CSelect>
                    </div>
                    <div class="col-12 col-md-4">
                      <CInputPerc id="in_3_struct_sede_input_perc" name="in_3_struct_sede_input_perc" placeholder="0,00" :readonly="false" v-model.number="in_3_struct_sede_input_perc"></CInputPerc>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(calcd_3_struct_sede_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(calcd_3_struct_sede_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_3_struct_rappr_kind">Costi di rappresentanza</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <CSelect id="in_3_struct_rappr_kind" name="in_3_struct_rappr_kind" :options="item_general_kinds" :disabled="true" v-model.number="in_3_struct_rappr_kind"></CSelect>
                    </div>
                    <div class="col-12 col-md-4">
                      <CInputPerc id="in_3_struct_rappr_input_perc" name="in_3_struct_rappr_input_perc" placeholder="0,00" :readonly="false" v-model.number="in_3_struct_rappr_input_perc"></CInputPerc>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(calcd_3_struct_rappr_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(calcd_3_struct_rappr_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_3_struct_ant_fin_kind">Anticipi finanziari</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <CSelect id="in_3_struct_ant_fin_kind" name="in_3_struct_ant_fin_kind" :options="item_general_kinds" :disabled="true" v-model.number="in_3_struct_ant_fin_kind"></CSelect>
                    </div>
                    <div class="col-12 col-md-4">
                      <CInputPerc id="in_3_struct_ant_fin_input_perc" name="in_3_struct_ant_fin_input_perc" placeholder="0,00" :readonly="false" v-model.number="in_3_struct_ant_fin_input_perc"></CInputPerc>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(calcd_3_struct_ant_fin_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(calcd_3_struct_ant_fin_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_3_struct_comp_plus_kind">Compensazione da plusvalenza</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <CSelect id="in_3_struct_comp_plus_kind" name="in_3_struct_comp_plus_kind" :options="item_general_kinds" :disabled="true" v-model.number="in_3_struct_comp_plus_kind"></CSelect>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(calcd_3_struct_comp_plus_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(calcd_3_struct_comp_plus_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="form-box-wrapper red" :class="expanded_boxes.includes('brand_40') ? 'expanded' : ''">
          <span class="expand-btn" @click="toggleExpandBox('brand_40')"></span>
          <div class="heading">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p class="ns"><strong>4.0</strong> Brand</p>
              </div>
              <div class="col-12 col-md-4">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceMoney(calcd_4_brand_tot_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceIntPerc(calcd_4_brand_tot_perc)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="expanded_boxes.includes('brand_40')">
            <hr>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_4_brand_coo_sales_kind">Coordinamento delle vendite</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <CSelect id="in_4_brand_coo_sales_kind" name="in_4_brand_coo_sales_kind" :options="item_general_kinds" :disabled="true" v-model.number="in_4_brand_coo_sales_kind"></CSelect>
                    </div>
                    <div class="col-12 col-md-4">
                      <CInputPerc id="in_4_brand_coo_sales_input_perc" name="in_4_brand_coo_sales_input_perc" placeholder="0,00" :readonly="false" v-model.number="in_4_brand_coo_sales_input_perc"></CInputPerc>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(calcd_4_brand_coo_sales_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(calcd_4_brand_coo_sales_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_4_brand_coo_com_kind">Coordinamento della comunicazione</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <CSelect id="in_4_brand_coo_com_kind" name="in_4_brand_coo_com_kind" :options="item_general_kinds" :disabled="true" v-model.number="in_4_brand_coo_com_kind"></CSelect>
                    </div>
                    <div class="col-12 col-md-4">
                      <CInputPerc id="in_4_brand_coo_com_input_perc" name="in_4_brand_coo_com_input_perc" placeholder="0,00" :readonly="false" v-model.number="in_4_brand_coo_com_input_perc"></CInputPerc>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(calcd_4_brand_coo_com_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(calcd_4_brand_coo_com_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_4_brand_comp_plus_kind">Compensazione da plusvalenza</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <CSelect id="in_4_brand_comp_plus_kind" name="in_4_brand_comp_plus_kind" :options="item_general_kinds" :disabled="true" v-model.number="in_4_brand_comp_plus_kind"></CSelect>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(calcd_4_brand_comp_plus_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(calcd_4_brand_comp_plus_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <p class="section-ot">Margine</p>
        <div class="form-box-wrapper orange" :class="expanded_boxes.includes('margine_50') ? 'expanded' : ''">
          <span class="expand-btn" @click="toggleExpandBox('margine_50')"></span>
          <div class="heading">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p class="ns"><strong>5.0</strong> Margine</p>
              </div>
              <div class="col-12 col-md-4"></div>
            </div>
          </div>
          <template v-if="expanded_boxes.includes('margine_50')">
            <hr>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_mar_extra_margine_eur">Extra-Margine</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <CInputMoney id="in_mar_extra_margine_eur" name="in_mar_extra_margine_eur" placeholder="0,00" :readonly="false" v-model.number="in_mar_extra_margine_eur"></CInputMoney>
                    </div>
                    <div class="col-12 col-md-4">
                      <span class="ctm-extra-m-btn" @click="showExtraMarginCalculator()"></span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6"></div>
                    <div class="col-12 col-md-6"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_mar_bonus_plus_kind">Bonus plusvalenza all'EA</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <CSelect id="in_mar_bonus_plus_kind" name="in_mar_bonus_plus_kind" :options="item_general_kinds" :disabled="true" v-model.number="in_mar_bonus_plus_kind"></CSelect>
                    </div>
                    <div class="col-12 col-md-4">
                      <CInputPerc id="in_mar_bonus_plus_perc" name="in_mar_bonus_plus_perc" placeholder="Seleziona un'opzione" :readonly="false" v-model.number="in_mar_bonus_plus_perc"></CInputPerc>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(calcd_mar_bonus_plus_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_mar_acc_gest_kind">Accantonamento per gestione (1.2)</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <CSelect id="in_mar_acc_gest_kind" name="in_mar_acc_gest_kind" :options="item_general_kinds" :disabled="true" v-model.number="in_mar_acc_gest_kind"></CSelect>
                    </div>
                    <div class="col-12 col-md-4">
                      <CInputPerc id="in_mar_acc_gest_perc" name="in_mar_acc_gest_perc" placeholder="Seleziona un'opzione" :readonly="false" v-model.number="in_mar_acc_gest_perc"></CInputPerc>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(calcd_mar_acc_gest_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_mar_acc_struct_kind">Accantonamento per struttura (3)</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <CSelect id="in_mar_acc_struct_kind" name="in_mar_acc_struct_kind" :options="item_general_kinds" :disabled="true" v-model.number="in_mar_acc_struct_kind"></CSelect>
                    </div>
                    <div class="col-12 col-md-4">
                      <CInputPerc id="in_mar_acc_struct_perc" name="in_mar_acc_struct_perc" placeholder="Seleziona un'opzione" :readonly="false" v-model.number="in_mar_acc_struct_perc"></CInputPerc>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(calcd_mar_acc_struct_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_mar_acc_brand_kind">Accantonamento per brand (4)</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <CSelect id="in_mar_acc_brand_kind" name="in_mar_acc_brand_kind" :options="item_general_kinds" :disabled="true" v-model.number="in_mar_acc_brand_kind"></CSelect>
                    </div>
                    <div class="col-12 col-md-4">
                      <CInputPerc id="in_mar_acc_brand_perc" name="in_mar_acc_brand_perc" placeholder="0,00" :readonly="false" v-model.number="in_mar_acc_brand_perc"></CInputPerc>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(calcd_mar_acc_brand_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6"></div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="form-box-wrapper orange" :class="expanded_boxes.includes('margine_51') ? 'expanded' : ''">
          <span class="expand-btn" @click="toggleExpandBox('margine_51')"></span>
          <div class="heading">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p><strong>5.1</strong> Margine <br><span>Attività</span></p>
              </div>
              <div class="col-12 col-md-4">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceMoney(calcd_51_mar_tot_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceIntPerc(calcd_51_mar_tot_perc)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="expanded_boxes.includes('margine_51')">
            <hr>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_51_mar_team_fisso_kind">Margine su team fisso</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <CSelect id="in_51_mar_team_fisso_kind" name="in_51_mar_team_fisso_kind" :options="item_general_kinds" :disabled="true" v-model.number="in_51_mar_team_fisso_kind"></CSelect>
                    </div>
                    <div class="col-12 col-md-4">
                      <CInputPerc id="in_51_mar_fisso_input_perc" name="in_51_mar_fisso_input_perc" placeholder="0,00" :readonly="false" v-model.number="in_51_mar_fisso_input_perc"></CInputPerc>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(calcd_51_mar_team_fisso_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(calcd_51_mar_team_fisso_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_51_mar_team_piva_kind">Margine su team a p.iva</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <CSelect id="in_51_mar_team_piva_kind" name="in_51_mar_team_piva_kind" :options="item_general_kinds" :disabled="true" v-model.number="in_51_mar_team_piva_kind"></CSelect>
                    </div>
                    <div class="col-12 col-md-4">
                      <CInputPerc id="in_51_mar_team_piva_input_perc" name="in_51_mar_team_piva_input_perc" placeholder="0,00" :readonly="false" v-model.number="in_51_mar_team_piva_input_perc"></CInputPerc>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(calcd_51_mar_team_piva_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(calcd_51_mar_team_piva_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_51_mar_fornit_kind">Margine su fornitori</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <CSelect id="in_51_mar_fornit_kind" name="in_51_mar_fornit_kind" :options="item_general_kinds" :disabled="true" v-model.number="in_51_mar_fornit_kind"></CSelect>
                    </div>
                    <div class="col-12 col-md-4">
                      <CInputPerc id="in_51_mar_fornit_input_perc" name="in_51_mar_fornit_input_perc" placeholder="0,00" :readonly="false" v-model.number="in_51_mar_fornit_input_perc"></CInputPerc>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(calcd_51_mar_fornit_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(calcd_51_mar_fornit_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="form-box-wrapper orange" :class="expanded_boxes.includes('margine_53') ? 'expanded' : ''">
          <span class="expand-btn" @click="toggleExpandBox('margine_53')"></span>
          <div class="heading">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p><strong>5.3</strong> Margine <br><span>Fee commerciale</span></p>
              </div>
              <div class="col-12 col-md-4">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceMoney(calcd_53_mar_fee_tot_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceIntPerc(calcd_53_mar_fee_tot_perc)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="expanded_boxes.includes('margine_53')">
            <hr>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_53_mar_fee_comm_kind">Margine su fee commerciale</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <CSelect id="in_53_mar_fee_comm_kind" name="in_53_mar_fee_comm_kind" :options="item_general_kinds" :disabled="true" v-model.number="in_53_mar_fee_comm_kind"></CSelect>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(calcd_53_mar_fee_comm_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(calcd_53_mar_fee_comm_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="recap-box-wrapper">
          <h3 class="rpb">Composizione per soggetti produttivi</h3>
          <div class="custom-perc-chart-title">
            <h4>Media dei progetti</h4>
          </div>
          <div>
            <div class="custom-perc-chart-wrapper half avg" :style="'width:calc('+$roundOneDecimal(avg_calcd_csp_fissi_tot_perc)+'% - 6px)'">
              <span class="fissi-a" :style="'width:'+getRelativePerc('avg_fissi', avg_values.avg_fissi_prod_int_tot_perc)+'%'"></span>
              <span class="fissi-b" :style="'width:'+getRelativePerc('avg_fissi', avg_values.avg_fissi_mol_tot_perc)+'%'"></span>
            </div>
            <div class="custom-perc-chart-wrapper half avg" :style="'width:calc('+$roundOneDecimal(avg_calcd_csp_var_tot_perc)+'% - 6px)'">
              <span class="variabili-c" :style="'width:'+getRelativePerc('avg_variabili', avg_values.avg_var_segnal_tot_perc)+'%'"></span>
              <span class="variabili-d" :style="'width:'+getRelativePerc('avg_variabili', avg_values.avg_var_ea_tot_perc)+'%'"></span>
              <span class="variabili-e" :style="'width:'+getRelativePerc('avg_variabili', avg_values.avg_var_prod_int_piva_tot_perc)+'%'"></span>
              <span class="variabili-f" :style="'width:'+getRelativePerc('avg_variabili', avg_values.avg_var_prod_est_piva_tot_perc)+'%'"></span>
              <span class="variabili-g" :style="'width:'+getRelativePerc('avg_variabili', avg_values.avg_var_prod_other_est_perc)+'%'"></span>
            </div>
          </div>
          <div>
            <div class="custom-perc-chart-wrapper half" :style="'width:calc('+$roundOneDecimal(calcd_csp_fissi_tot_perc)+'% - 6px)'">
              <span class="fissi-a" :style="'width:'+getRelativePerc('fissi', calcd_csp_fissi_prod_int_tot_perc)+'%'">A</span>
              <span class="fissi-b" :style="'width:'+getRelativePerc('fissi', calcd_csp_fissi_mol_tot_perc)+'%'">B</span>
            </div>
            <div class="custom-perc-chart-wrapper half" :style="'width:calc('+$roundOneDecimal(calcd_csp_var_tot_perc)+'% - 6px)'">
              <span class="variabili-c" :style="'width:'+getRelativePerc('variabili', calcd_csp_var_segnal_tot_perc)+'%'">C</span>
              <span class="variabili-d" :style="'width:'+getRelativePerc('variabili', calcd_csp_var_ea_tot_perc)+'%'">D</span>
              <span class="variabili-e" :style="'width:'+getRelativePerc('variabili', calcd_csp_var_prod_int_piva_tot_perc)+'%'">E</span>
              <span class="variabili-f" :style="'width:'+getRelativePerc('variabili', calcd_csp_var_prod_est_piva_tot_perc)+'%'">F</span>
              <span class="variabili-g" :style="'width:'+getRelativePerc('variabili', calcd_csp_var_prod_other_est_perc)+'%'">G</span>
            </div>
          </div>
          <div class="custom-perc-chart-wrapper">
            <span class="fissi" :style="'width:'+calcd_csp_fissi_tot_perc+'%'">
              <span class="tooltip">{{$niceIntPerc(calcd_csp_fissi_tot_perc)}}</span>
            </span>
            <span class="variabili" :style="'width:'+calcd_csp_var_tot_perc+'%'">
              <span class="tooltip">{{$niceIntPerc(calcd_csp_var_tot_perc)}}</span>
            </span>
          </div>
          <div class="custom-chart-legend-wrapper">
            <h4>Fissi<span class="fissi"></span> {{$niceIntPerc(calcd_csp_fissi_tot_perc)}}</h4>
            <div class="values-row">
              <span class="label">A. Produzione interna</span>
              <span class="hour-value">{{$niceNumber(calcd_csp_fissi_prod_int_tot_h)}}h</span>
              <span class="eur-value">{{$niceMoney(calcd_csp_fissi_prod_int_tot_eur)}}</span>
              <span class="perc-value fissi">{{$niceIntPerc(calcd_csp_fissi_prod_int_tot_perc)}}</span>
            </div>
            <div class="values-row">
              <span class="label">B. MOL</span>
              <span class="hour-value"></span>
              <span class="eur-value">{{$niceMoney(calcd_csp_fissi_mol_tot_eur)}}</span>
              <span class="perc-value fissi">{{$niceIntPerc(calcd_csp_fissi_mol_tot_perc)}}</span>
            </div>
            <h4>Variabili<span class="variabili"></span> {{$niceIntPerc(calcd_csp_var_tot_perc)}}</h4>
            <div class="values-row">
              <span class="label">C. Segnalatori</span>
              <span class="hour-value"></span>
              <span class="eur-value">{{$niceMoney(calcd_csp_var_segnal_tot_eur)}}</span>
              <span class="perc-value variabili">{{$niceIntPerc(calcd_csp_var_segnal_tot_perc)}}</span>
            </div>
            <div class="values-row">
              <span class="label">D. Executive Advisor</span>
              <span class="hour-value"></span>
              <span class="eur-value">{{$niceMoney(calcd_csp_var_ea_tot_eur)}}</span>
              <span class="perc-value variabili">{{$niceIntPerc(calcd_csp_var_ea_tot_perc)}}</span>
            </div>
            <div class="values-row">
              <span class="label">E. Produzione interna p.iva</span>
              <span class="hour-value">{{$niceNumber(calcd_csp_var_prod_piva_tot_h)}}h</span>
              <span class="eur-value">{{$niceMoney(calcd_csp_var_prod_int_piva_tot_eur)}}</span>
              <span class="perc-value variabili">{{$niceIntPerc(calcd_csp_var_prod_int_piva_tot_perc)}}</span>
            </div>
            <div class="values-row">
              <span class="label">F. Produzione esterna p.iva</span>
              <span class="hour-value"></span>
              <span class="eur-value">{{$niceMoney(calcd_csp_var_prod_est_piva_tot_eur)}}</span>
              <span class="perc-value variabili">{{$niceIntPerc(calcd_csp_var_prod_est_piva_tot_perc)}}</span>
            </div>
            <div class="values-row">
              <span class="label">G. Altri costi di produzione esterna</span>
              <span class="hour-value"></span>
              <span class="eur-value">{{$niceMoney(calcd_csp_var_prod_other_est_eur)}}</span>
              <span class="perc-value variabili">{{$niceIntPerc(calcd_csp_var_prod_other_est_perc)}}</span>
            </div>
          </div>
          <hr>
          <h3>Composizione per voci di bilancio</h3>
          <div class="outer-chart-wrapper">
            <div class="pie-chart-wrapper" ref="pie_chart"></div>
            <span class="chart-big-value">{{$niceMoney(calcd_prezzo_vendita_tot_eur)}}</span>
          </div>
          <div class="chart-legend-wrapper">
            <div>
              <span class="label comm">Costi commerciali</span>
              <span class="eur-value">{{$niceMoney(calcd_cvb_comm_tot_eur)}}</span>
              <span class="perc-value comm">{{$niceIntPerc(calcd_cvb_comm_tot_perc)}}</span>
            </div>
            <div>
              <span class="label dir">Costi di direzione</span>
              <span class="eur-value">{{$niceMoney(calcd_cvb_dir_tot_eur)}}</span>
              <span class="perc-value dir">{{$niceIntPerc(calcd_cvb_dir_tot_perc)}}</span>
            </div>
            <div>
              <span class="label prod">Costi di produzione</span>
              <span class="eur-value">{{$niceMoney(calcd_cvb_prod_tot_eur)}}</span>
              <span class="perc-value prod">{{$niceIntPerc(calcd_cvb_prod_tot_perc)}}</span>
            </div>
            <div>
              <span class="label mol">MOL</span>
              <span class="eur-value">{{$niceMoney(calcd_cvb_mol_tot_eur)}}</span>
              <span class="perc-value mol">{{$niceIntPerc(calcd_cvb_mol_tot_perc)}}</span>
            </div>
          </div>
          <hr>
          <div class="outer-chart-wrapper">
            <div class="radar-chart-wrapper" ref="radar_chart"></div>
          </div>
          <div class="chart-legend-wrapper">
            <div>
              <span class="label proj">Progetto corrente</span>
            </div>
            <div>
              <span class="label avg">Media dei progetti</span>
            </div>
          </div>
          <hr>
          <h3>Prezzo di vendita</h3>
          <span class="big-value">{{$niceMoney(calcd_prezzo_vendita_tot_eur)}}</span>
        </div>
      </div>
    </div>

    <div class="extra-margin-calculator-wrapper" v-if="extra_margin_calculator">
      <div class="box-wrapper">
        <span class="close" @click="hideExtraMarginCalculator()"></span>
        <h4>A che cifra vuoi arrivare?</h4>
        <div class="form-wrapper">
          <CInputMoney id="extra_margin_calculator_value" name="extra_margin_calculator_value" placeholder="..." v-model="extra_margin_calculator_value"></CInputMoney>
        </div>
        <div>
          <span class="save-btn" :class="calculator_btn_class" @click="applyExtraMarginCalculatorValue()">Applica</span>
        </div>
      </div>
    </div>

    <div class="ctm-banner success" :class="success_banner_class">
      <p>Le modifiche sono state salvate con successo</p>
    </div>
    <div class="ctm-banner error" :class="error_banner_class">
      <p>Si è verificato un problema durante il salvataggio delle modifiche</p>
    </div>
  </div>
</template>

<script>
  import CSelect from '../form/CSelect.vue'
  import CInput from '../form/CInput.vue'
  import CTextarea from '../form/CTextarea.vue'
  import CInputDate from '../form/CInputDate.vue'
  import CInputPerc from '../form/CInputPerc.vue'
  import CInputMoney from '../form/CInputMoney.vue'
  import CInputHour from '../form/CInputHour.vue'

  import * as helperFunctions from "../../helpers/helpers"

  import * as am5 from '@amcharts/amcharts5'
  import * as am5percent from '@amcharts/amcharts5/percent'
  import * as am5radar from '@amcharts/amcharts5/radar'
  import * as am5xy from "@amcharts/amcharts5/xy";
  import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'

  export default {
    components:{
      CSelect,
      CInput,
      CTextarea,
      CInputDate,
      CInputPerc,
      CInputMoney,
      CInputHour,
    },

    props: {
      cep: Object,
      current_user: Object,
      item_general_kinds: Array,
      stip_act_types: Array,
      prod_others_types: Array,
      default_values: Object,
      cep_lines: Object,
      users: Array,
      partners: Array,
      avg_values: Object,
    },

    data(){
      return {
        ctm_json: null,

        is_test: false,

        expanded_boxes: [
          'sales_01',
          'sales_02',
          'sales_03',
          'direzione_11',
          'produzione_21',
          'produzione_22',
          'produzione_23',
          'produzione_24',
          'produzione_25',
          'gestione_12',
          'struttura_30',
          'brand_40',
          'margine_50',
          'margine_51',
          'margine_53',
        ],

        is_pl: false,
        is_without_fee_comm: this.cep.is_without_fee_comm,

        // -------------------
        // VIOLETTO
        // -------------------
          in_01_new_sell_ext_who_partner_id: this.cep.in_01_new_sell_ext_who_partner_id ? this.cep.in_01_new_sell_ext_who_partner_id : null,
          in_01_new_sell_ext_kind: this.cep.in_01_new_sell_ext_kind ? this.cep.in_01_new_sell_ext_kind : 0,
          in_01_new_sell_ext_perc: this.$stringToFloat(this.cep.in_01_new_sell_ext_perc ? this.cep.in_01_new_sell_ext_perc : null),
          in_01_new_sell_oth_who_id: this.cep.in_01_new_sell_oth_who_id ? this.cep.in_01_new_sell_oth_who_id : null,
          in_01_new_sell_oth_kind: this.cep.in_01_new_sell_oth_kind ? this.cep.in_01_new_sell_oth_kind : 0,
          in_01_new_sell_oth_perc: this.$stringToFloat(this.cep.in_01_new_sell_oth_perc ? this.cep.in_01_new_sell_oth_perc : null),
          in_01_new_sell_int_who_pl_id: this.cep.in_01_new_sell_int_who_pl_id ? this.cep.in_01_new_sell_int_who_pl_id : null,
          in_01_new_sell_int_kind: this.cep.in_01_new_sell_int_kind ? this.cep.in_01_new_sell_int_kind : 0,
          in_01_new_sell_int_perc: this.$stringToFloat(this.cep.in_01_new_sell_int_perc ? this.cep.in_01_new_sell_int_perc : null),
          in_01_upsell_int_who_pl_id: this.initUpsellIntWhoPlId(), // Se c'è EA è uguale a ea_id, se non c'è EA ma c'è PL è uguale a pl_id, altrimenti è null
          in_01_upsell_int_kind: this.cep.in_01_upsell_int_kind ? this.cep.in_01_upsell_int_kind : 0,
          in_01_upsell_int_perc: this.$stringToFloat(this.cep.in_01_upsell_int_perc ? this.cep.in_01_upsell_int_perc : null),
          in_02_extra_margine_partner_who_partner_id: this.cep.in_02_extra_margine_partner_who_partner_id ? this.cep.in_02_extra_margine_partner_who_partner_id : null,
          in_02_extra_margine_partner_kind: this.cep.in_02_extra_margine_partner_kind ? this.cep.in_02_extra_margine_partner_kind : 0,
          in_02_extra_margine_partner_eur: this.$fromCentToEur(this.cep.in_02_extra_margine_partner_eur ? this.cep.in_02_extra_margine_partner_eur : null),
          in_03_extra_margine_bonus_who_user_id: this.cep.ea_id,
          in_03_extra_margine_bonus_kind: this.cep.in_03_extra_margine_bonus_kind ? this.cep.in_03_extra_margine_bonus_kind : 0,

        // -------------------
        // BLU
        // -------------------
          in_11_direzione_team_fisso_kind: this.cep.in_11_direzione_team_fisso_kind ? this.cep.in_11_direzione_team_fisso_kind : 0,
          in_11_direzione_team_fisso_input_perc: this.$stringToFloat(this.cep.in_11_direzione_team_fisso_input_perc ? this.cep.in_11_direzione_team_fisso_input_perc : this.default_values.in_11_direzione_team_fisso_input_perc),
          in_11_direzione_team_piva_kind: this.cep.in_11_direzione_team_piva_kind ? this.cep.in_11_direzione_team_piva_kind : 0,
          in_11_direzione_team_piva_input_perc: this.$stringToFloat(this.cep.in_11_direzione_team_piva_input_perc ? this.cep.in_11_direzione_team_piva_input_perc : this.default_values.in_11_direzione_team_piva_input_perc),
          in_11_direzione_fornit_kind: this.cep.in_11_direzione_fornit_kind ? this.cep.in_11_direzione_fornit_kind : 0,
          in_11_direzione_fornit_input_perc: this.$stringToFloat(this.cep.in_11_direzione_fornit_input_perc ? this.cep.in_11_direzione_fornit_input_perc : this.default_values.in_11_direzione_fornit_input_perc),
          in_11_direzione_consul_kind: this.cep.in_11_direzione_consul_kind ? this.cep.in_11_direzione_consul_kind : 0,
          in_11_direzione_consul_input_eur: this.$fromCentToEur(this.cep.in_11_direzione_consul_input_eur ? this.cep.in_11_direzione_consul_input_eur : null),
          in_11_direzione_consul_input_h_qty: this.$stringToFloat(this.cep.in_11_direzione_consul_input_h_qty ? this.cep.in_11_direzione_consul_input_h_qty : 0),

        // -------------------
        // VERDE
        // -------------------
          prod_team_stip_lines: [],
          prod_team_piva_lines: [],
          prod_fornit_piva_lines: [],
          prod_media_lines: [],
          prod_others_lines: [],

        // -------------------
        // BLU
        // -------------------
          in_12_gest_alloc_fisso_piva_kind: this.cep.in_12_gest_alloc_fisso_piva_kind ? this.cep.in_12_gest_alloc_fisso_piva_kind : 1,
          in_12_gest_alloc_fisso_piva_input_perc: this.$stringToFloat(this.cep.in_12_gest_alloc_fisso_piva_input_perc ? this.cep.in_12_gest_alloc_fisso_piva_input_perc : this.default_values.in_12_gest_alloc_fisso_piva_input_perc),
          in_12_gest_superv_fornit_kind: this.cep.in_12_gest_superv_fornit_kind ? this.cep.in_12_gest_superv_fornit_kind : 1,
          in_12_gest_superv_fornit_input_perc: this.$stringToFloat(this.cep.in_12_gest_superv_fornit_input_perc ? this.cep.in_12_gest_superv_fornit_input_perc : this.default_values.in_12_gest_superv_fornit_input_perc),
          in_12_gest_superv_event_kind: this.cep.in_12_gest_superv_event_kind ? this.cep.in_12_gest_superv_event_kind : 1,
          in_12_gest_superv_event_eur: this.$fromCentToEur(this.cep.in_12_gest_superv_event_eur ? this.cep.in_12_gest_superv_event_eur : null),
          in_12_gest_comp_plus_kind: this.cep.in_12_gest_comp_plus_kind ? this.cep.in_12_gest_comp_plus_kind : 1,

        // -------------------
        // GIALLO
        // -------------------
          in_3_struct_dir_az_kind: this.cep.in_3_struct_dir_az_kind ? this.cep.in_3_struct_dir_az_kind : 1,
          in_3_struct_dir_az_input_perc: this.$stringToFloat(this.cep.in_3_struct_dir_az_input_perc ? this.cep.in_3_struct_dir_az_input_perc : this.default_values.in_3_struct_dir_az_input_perc),
          in_3_struct_sede_kind: this.cep.in_3_struct_sede_kind ? this.cep.in_3_struct_sede_kind : 1,
          in_3_struct_sede_input_perc: this.$stringToFloat(this.cep.in_3_struct_sede_input_perc ? this.cep.in_3_struct_sede_input_perc : this.default_values.in_3_struct_sede_input_perc),
          in_3_struct_rappr_kind: this.cep.in_3_struct_rappr_kind ? this.cep.in_3_struct_rappr_kind : 1,
          in_3_struct_rappr_input_perc: this.$stringToFloat(this.cep.in_3_struct_rappr_input_perc ? this.cep.in_3_struct_rappr_input_perc : this.default_values.in_3_struct_rappr_input_perc),
          in_3_struct_ant_fin_kind: this.cep.in_3_struct_ant_fin_kind ? this.cep.in_3_struct_ant_fin_kind : 1,
          in_3_struct_ant_fin_input_perc: this.$stringToFloat(this.cep.in_3_struct_ant_fin_input_perc ? this.cep.in_3_struct_ant_fin_input_perc : this.default_values.in_3_struct_ant_fin_input_perc),
          in_3_struct_comp_plus_kind: this.cep.in_3_struct_comp_plus_kind ? this.cep.in_3_struct_comp_plus_kind : 1,

        // -------------------
        // ROSINO
        // -------------------
          in_4_brand_coo_sales_kind: this.cep.in_4_brand_coo_sales_kind ? this.cep.in_4_brand_coo_sales_kind : 1,
          in_4_brand_coo_sales_input_perc: this.$stringToFloat(this.cep.in_4_brand_coo_sales_input_perc ? this.cep.in_4_brand_coo_sales_input_perc : this.default_values.in_4_brand_coo_sales_input_perc),
          in_4_brand_coo_com_kind: this.cep.in_4_brand_coo_com_kind ? this.cep.in_4_brand_coo_com_kind : 1,
          in_4_brand_coo_com_input_perc: this.$stringToFloat(this.cep.in_4_brand_coo_com_input_perc ? this.cep.in_4_brand_coo_com_input_perc : this.default_values.in_4_brand_coo_com_input_perc),
          in_4_brand_comp_plus_kind: this.cep.in_4_brand_comp_plus_kind ? this.cep.in_4_brand_comp_plus_kind : 1,

        // -------------------
        // BIANCO
        // -------------------
          in_mar_extra_margine_eur: this.$fromCentToEur(this.cep.in_mar_extra_margine_eur ? this.cep.in_mar_extra_margine_eur : 0),
          extra_margin_calculator: false,
          extra_margin_calculator_value: 0,

        // -------------------
        // VIOLETTO
        // -------------------
          in_mar_bonus_plus_kind: this.cep.in_mar_bonus_plus_kind ? this.cep.in_mar_bonus_plus_kind : 0,
          in_mar_bonus_plus_perc: this.$stringToFloat(this.cep.in_mar_bonus_plus_perc ? this.cep.in_mar_bonus_plus_perc : this.default_values.in_mar_bonus_plus_perc),

        // -------------------
        // AZZURRINO
        // -------------------
          in_mar_acc_gest_kind: this.cep.in_mar_acc_gest_kind ? this.cep.in_mar_acc_gest_kind : 1,
          in_mar_acc_gest_perc: this.$stringToFloat(this.cep.in_mar_acc_gest_perc ? this.cep.in_mar_acc_gest_perc : this.default_values.in_mar_acc_gest_perc),

        // -------------------
        // GIALLO
        // -------------------
          in_mar_acc_struct_kind: this.cep.in_mar_acc_struct_kind ? this.cep.in_mar_acc_struct_kind : 1,
          in_mar_acc_struct_perc: this.$stringToFloat(this.cep.in_mar_acc_struct_perc ? this.cep.in_mar_acc_struct_perc : this.default_values.in_mar_acc_struct_perc),

        // -------------------
        // ROSINO
        // -------------------
          in_mar_acc_brand_kind: this.cep.in_mar_acc_brand_kind ? this.cep.in_mar_acc_brand_kind : 1,
          in_mar_acc_brand_perc: this.$stringToFloat(this.cep.in_mar_acc_brand_perc ? this.cep.in_mar_acc_brand_perc : this.default_values.in_mar_acc_brand_perc),

        // -------------------
        // ARANCIONE
        // -------------------
          in_51_mar_team_fisso_kind: this.cep.in_51_mar_team_fisso_kind ? this.cep.in_51_mar_team_fisso_kind : 1,
          in_51_mar_fisso_input_perc: this.$stringToFloat(this.cep.in_51_mar_fisso_input_perc ? this.cep.in_51_mar_fisso_input_perc : this.default_values.in_51_mar_fisso_input_perc),
          in_51_mar_team_piva_kind: this.cep.in_51_mar_team_piva_kind ? this.cep.in_51_mar_team_piva_kind : 1,
          in_51_mar_team_piva_input_perc: this.$stringToFloat(this.cep.in_51_mar_team_piva_input_perc ? this.cep.in_51_mar_team_piva_input_perc : this.default_values.in_51_mar_team_piva_input_perc),
          in_51_mar_fornit_kind: this.cep.in_51_mar_fornit_kind ? this.cep.in_51_mar_fornit_kind : 1,
          in_51_mar_fornit_input_perc: this.$stringToFloat(this.cep.in_51_mar_fornit_input_perc ? this.cep.in_51_mar_fornit_input_perc : this.default_values.in_51_mar_fornit_input_perc),
          in_53_mar_fee_comm_kind: this.cep.in_53_mar_fee_comm_kind ? this.cep.in_53_mar_fee_comm_kind : 1,

        pie_chart: null,
        pie_chart_series: null,
        radar_chart: null,
        radar_chart_series: null,

        btn_class: '',
        success_banner_class: '',
        error_banner_class: '',

        calculator_btn_class: '',
      }
    },

    watch: {
      calcd_cvb_comm_tot_perc: function(){
        if(this.$refs.pie_chart){
          this.renderPieChart();
        }
        if(this.$refs.radar_chart){
          this.renderRadarChart();
        }
      },

      calcd_cvb_dir_tot_perc: function(){
        if(this.$refs.pie_chart){
          this.renderPieChart();
        }
        if(this.$refs.radar_chart){
          this.renderRadarChart();
        }
      },

      calcd_cvb_prod_tot_perc: function(){
        if(this.$refs.pie_chart){
          this.renderPieChart();
        }
        if(this.$refs.radar_chart){
          this.renderRadarChart();
        }
      },

      calcd_cvb_mol_tot_perc: function(){
        if(this.$refs.pie_chart){
          this.renderPieChart();
        }
        if(this.$refs.radar_chart){
          this.renderRadarChart();
        }
      },

      ctm_json: function(){
        if(ctm_json){
          var parsed_json = JSON.parse(this.ctm_json);
          var keys = [
            'in_01_new_sell_ext_kind',
            'in_01_new_sell_ext_perc',
            'in_01_new_sell_ext_who_partner_id',
            'in_01_new_sell_int_kind',
            'in_01_new_sell_int_perc',
            'in_01_new_sell_int_who_pl_id',
            'in_01_new_sell_oth_kind',
            'in_01_new_sell_oth_perc',
            'in_01_new_sell_oth_who_id',
            'in_01_upsell_int_kind',
            'in_01_upsell_int_perc',
            'in_01_upsell_int_who_pl_id',
            'in_02_extra_margine_partner_eur',
            'in_02_extra_margine_partner_kind',
            'in_02_extra_margine_partner_who_partner_id',
            'in_03_extra_margine_bonus_kind',
            'in_03_extra_margine_bonus_who_user_id',
            'in_11_direzione_consul_input_eur',
            'in_11_direzione_consul_input_h_qty',
            'in_11_direzione_consul_kind',
            'in_11_direzione_fornit_input_perc',
            'in_11_direzione_fornit_kind',
            'in_11_direzione_team_fisso_input_perc',
            'in_11_direzione_team_fisso_kind',
            'in_11_direzione_team_piva_input_perc',
            'in_11_direzione_team_piva_kind',
            'in_12_gest_alloc_fisso_piva_input_perc',
            'in_12_gest_alloc_fisso_piva_kind',
            'in_12_gest_comp_plus_kind',
            'in_12_gest_superv_event_eur',
            'in_12_gest_superv_event_kind',
            'in_12_gest_superv_fornit_input_perc',
            'in_12_gest_superv_fornit_kind',
            'in_3_struct_ant_fin_input_perc',
            'in_3_struct_ant_fin_kind',
            'in_3_struct_comp_plus_kind',
            'in_3_struct_dir_az_input_perc',
            'in_3_struct_dir_az_kind',
            'in_3_struct_rappr_input_perc',
            'in_3_struct_rappr_kind',
            'in_3_struct_sede_input_perc',
            'in_3_struct_sede_kind',
            'in_4_brand_comp_plus_kind',
            'in_4_brand_coo_com_input_perc',
            'in_4_brand_coo_com_kind',
            'in_4_brand_coo_sales_input_perc',
            'in_4_brand_coo_sales_kind',
            'in_51_mar_fisso_input_perc',
            'in_51_mar_fornit_input_perc',
            'in_51_mar_fornit_kind',
            'in_51_mar_team_fisso_kind',
            'in_51_mar_team_piva_input_perc',
            'in_51_mar_team_piva_kind',
            'in_53_mar_fee_comm_kind',
            'in_mar_acc_brand_kind',
            'in_mar_acc_brand_perc',
            'in_mar_acc_gest_kind',
            'in_mar_acc_gest_perc',
            'in_mar_acc_struct_kind',
            'in_mar_acc_struct_perc',
            'in_mar_bonus_plus_kind',
            'in_mar_bonus_plus_perc',
            'in_mar_extra_margine_eur',
            'prod_fornit_piva_lines',
            'prod_media_lines',
            'prod_others_lines',
            'prod_team_piva_lines',
            'prod_team_stip_lines',
          ];

          for(var i=0;i<keys.length;i++){
            var key = keys[i];
            if(key.includes('_eur')){
              this[key] = this.$fromCentToEur(parsed_json.in[key]);
            }
            else if(key.includes('_lines')){
              switch(key){
                case 'prod_team_stip_lines':
                  var arr = [];
                  for(var x=0;x<this.stip_act_types.length;x++){
                    var val = this.stip_act_types[x];
                    var obj = this.objProdTeamStipLine();
                    obj.in_21_prod_team_stip_act_type = val.value;
                    if(parsed_json.in[key] && parsed_json.in[key].length>0){
                      var res = parsed_json.in[key].filter((item) => { return val.value==item.in_21_prod_team_stip_act_type; });
                      if(res && res.length>0){
                        var in_21_prod_team_stip_who_str = null;
                        var in_21_prod_team_stip_h_cost_eur = null;
                        var in_21_prod_team_stip_h_qty = null;
                        for(var y=0;y<res.length;y++){
                          var res_obj = res[y];
                          in_21_prod_team_stip_who_str = res_obj.in_21_prod_team_stip_who_str;
                          in_21_prod_team_stip_h_cost_eur = this.$fromCentToEur(res_obj.in_21_prod_team_stip_h_cost_eur);
                          in_21_prod_team_stip_h_qty += res_obj.in_21_prod_team_stip_h_qty;
                        }
                        obj.in_21_prod_team_stip_who_str = in_21_prod_team_stip_who_str;
                        obj.in_21_prod_team_stip_h_cost_eur = in_21_prod_team_stip_h_cost_eur;
                        obj.in_21_prod_team_stip_h_qty = in_21_prod_team_stip_h_qty;
                      }
                    }
                    arr.push(obj);
                  }
                  this.prod_team_stip_lines = arr;
                  break;
                case 'prod_team_piva_lines':
                  var arr = [];
                  for(var x=0;x<parsed_json.in[key].length;x++){
                    var obj = {
                      in_22_prod_team_piva_act_type: parsed_json.in[key][x].in_22_prod_team_piva_act_type,
                      in_22_prod_team_piva_who_user_id: parsed_json.in[key][x].in_22_prod_team_piva_who_user_id,
                      in_22_prod_team_piva_kind: parsed_json.in[key][x].in_22_prod_team_piva_kind,
                      in_22_prod_team_piva_h_cost_eur: this.$fromCentToEur(parsed_json.in[key][x].in_22_prod_team_piva_h_cost_eur),
                      in_22_prod_team_piva_h_qty: parsed_json.in[key][x].in_22_prod_team_piva_h_qty,
                    };
                    arr.push(obj);
                  }
                  if(arr && arr.length>0){
                    this.prod_team_piva_lines = arr;
                  }
                  else{
                    this.prod_team_piva_lines = [this.objProdTeamPivaLine()];
                  }
                  break;
                case 'prod_fornit_piva_lines':
                  var arr = [];
                  for(var x=0;x<parsed_json.in[key].length;x++){
                    var obj = {
                      in_23_prod_fornit_piva_act_desc: parsed_json.in[key][x].in_23_prod_fornit_piva_act_desc ? parsed_json.in[key][x].in_23_prod_fornit_piva_act_desc : parsed_json.in[key][x].in_23_prod_fornit_piva_who_str,
                      in_23_prod_fornit_piva_who_str: parsed_json.in[key][x].in_23_prod_fornit_piva_who_str,
                      in_23_prod_fornit_piva_kind: parsed_json.in[key][x].in_23_prod_fornit_piva_kind,
                      in_23_prod_fornit_piva_eur: this.$fromCentToEur(parsed_json.in[key][x].in_23_prod_fornit_piva_eur),
                    };
                    arr.push(obj);
                  }
                  if(arr && arr.length>0){
                    this.prod_fornit_piva_lines = arr;
                  }
                  else{
                    this.prod_fornit_piva_lines = [this.objProdFornitPivaLine()];
                  }
                  break;
                case 'prod_media_lines':
                  var arr = [];
                  for(var x=0;x<parsed_json.in[key].length;x++){
                    var obj = {
                      in_24_prod_media_desc: parsed_json.in[key][x].in_24_prod_media_desc,
                      in_24_prod_media_desc1: parsed_json.in[key][x].in_24_prod_media_desc1,
                      in_24_prod_media_kind: parsed_json.in[key][x].in_24_prod_media_kind,
                      in_24_prod_media_eur: this.$fromCentToEur(parsed_json.in[key][x].in_24_prod_media_eur),
                    };
                    arr.push(obj);
                  }
                  if(arr && arr.length>0){
                    this.prod_media_lines = arr;
                  }
                  else{
                    this.prod_media_lines = [this.objProdMediaLine()];
                  }
                  break;
                case 'prod_others_lines':
                  var arr = [];
                  for(var x=0;x<parsed_json.in[key].length;x++){
                    var obj = {
                      in_25_prod_others_type: parsed_json.in[key][x].in_25_prod_others_type,
                      in_25_prod_others_desc: parsed_json.in[key][x].in_25_prod_others_desc,
                      in_25_prod_others_kind: parsed_json.in[key][x].in_25_prod_others_kind,
                      in_25_prod_others_eur: this.$fromCentToEur(parsed_json.in[key][x].in_25_prod_others_eur),
                    };
                    arr.push(obj);
                  }
                  if(arr && arr.length>0){
                    this.prod_others_lines = arr;
                  }
                  else{
                    this.prod_others_lines = [this.objProdOthersLine()];
                  }
                  break;
                default:break;
              }
            }
            else{
              this[key] = parsed_json.in[key];
            }
          }
        }
      },
    },

    created(){
      this.initProdTeamStipLines();
      if((!this.prod_team_piva_lines || this.prod_team_piva_lines.length==0) && this.cep_lines && this.cep_lines.prod_team_piva_lines && this.cep_lines.prod_team_piva_lines.length>0){
        this.initLines(this.cep_lines.prod_team_piva_lines, 'team_piva');
      }
      else{
        this.prod_team_piva_lines = [this.objProdTeamPivaLine()];
      }
      if((!this.prod_fornit_piva_lines || this.prod_fornit_piva_lines.length==0) && this.cep_lines && this.cep_lines.prod_fornit_piva_lines && this.cep_lines.prod_fornit_piva_lines.length>0){
        this.initLines(this.cep_lines.prod_fornit_piva_lines, 'fornit_piva');
      }
      else{
        this.prod_fornit_piva_lines = [this.objProdFornitPivaLine()];
      }
      if((!this.prod_media_lines || this.prod_media_lines.length==0) && this.cep_lines && this.cep_lines.prod_media_lines && this.cep_lines.prod_media_lines.length>0){
        this.initLines(this.cep_lines.prod_media_lines, 'media');
      }
      else{
        this.prod_media_lines = [this.objProdMediaLine()];
      }
      if((!this.prod_others_lines || this.prod_others_lines.length==0) && this.cep_lines && this.cep_lines.prod_others_lines && this.cep_lines.prod_others_lines.length>0){
        this.initLines(this.cep_lines.prod_others_lines, 'others');
      }
      else{
        this.prod_others_lines = [this.objProdOthersLine()];
      }
    },

    mounted(){
      this.renderPieChart();
      this.renderRadarChart();
    },

    beforeDestroy(){
      if(this.pie_chart){
        this.pie_chart.dispose();
      }
      if(this.radar_chart){
        this.radar_chart.dispose();
      }
    },

    computed: {
      eas: function(){
        return this.users.filter((item) => { return item.is_ea; }).map((item) => { return {label: this.$niceUserFullName(item), value: item.id, localized_label: this.$niceUserFullName(item)}; });
      },
      pls: function(){
        return this.users.filter((item) => { return item.is_pl; }).map((item) => { return {label: this.$niceUserFullName(item), value: item.id, localized_label: this.$niceUserFullName(item)}; });
      },
      pcs: function(){
        return this.users.filter((item) => { return item.is_pc; }).map((item) => { return {label: this.$niceUserFullName(item), value: item.id, localized_label: this.$niceUserFullName(item)}; });
      },
      eas_pls: function(){
        var t_arr = this.eas.concat(this.pls);
        return t_arr.filter((item, pos) => t_arr.indexOf(item)===pos);
      },
      null_or_ea: function(){
        var id = null;
        if(this.cep.ea_id){
          id = this.cep.ea_id;
        }
        else if(this.cep.pl_id){
          id = this.cep.pl_id;
        }
        var t_arr = this.eas_pls.filter((item, pos) => item.value===id);
        return t_arr;
      },
      prod_team_piva_users: function(){
        return this.users.filter((item) => { return item.is_prod_team_piva; }).map((item) => { return {label: this.$niceUserFullName(item), value: item.id, localized_label: this.$niceUserFullName(item)}; });
      },
      new_sell_oth_who_list: function(){
        return this.users.filter((item) => { return item.is_new_sell_oth_internal; }).map((item) => { return {label: this.$niceUserFullName(item), value: item.id, localized_label: this.$niceUserFullName(item)}; });
      },
      new_sell_partners: function(){
        return this.partners.filter((item) => { return item.is_new_sell_external; }).map((item) => { return {label: item.name, value: item.id, localized_label: item.name}; });
      },
      extra_margine_partners: function(){
        return this.partners.filter((item) => { return item.has_extra_margin; }).map((item) => { return {label: item.name, value: item.id, localized_label: item.name}; });
      },

      // -------------------
      // VIOLETTO
      // -------------------
        calcd_01_tot_eur: function(){
          // Formula F5 -> =F8+F10+F9+F11
          var v = this.$roundIntNumber(this.calcd_01_new_sell_ext_eur+this.calcd_01_new_sell_int_eur+this.calcd_01_new_sell_oth_eur+this.calcd_01_upsell_int_eur);
          return v;
        },
        calcd_01_tot_perc: function(){
          // Formula G5 -> =F5/F96
          var v = 0;
          if(this.calcd_prezzo_vendita_tot_eur){
            v = this.$calculatePerc(this.calcd_01_tot_eur/this.calcd_prezzo_vendita_tot_eur);
          }
          return v;
        },
        calcd_01_prezzo_senza: function(){
          // Formula F6 -> =F14+F16+F21+F32+F43+F67+F72+F78+F89
          var v = Math.round(this.calcd_03_extra_margine_bonus_tot_eur+this.calcd_11_direzione_tot_eur+this.calcd_21_prod_team_stip_tot_eur+this.calcd_22_prod_team_piva_tot_eur+this.calcd_23_prod_fornit_piva_tot_eur+this.calcd_12_gest_tot_eur+this.calcd_3_struct_tot_eur+this.calcd_4_brand_tot_eur+this.calcd_51_mar_tot_eur);
          return v;
        },
        calcd_01_fee_comm: function(){
          // Formula F7 -> =(F6/0,8)-F6
          var v = 0;
          if(this.is_without_fee_comm==0){
            v = Math.round((this.calcd_01_prezzo_senza/0.8)-this.calcd_01_prezzo_senza);
          }
          return v;
        },
        calcd_01_new_sell_tot_perc: function(){
          // Formula E8 -> =SOMMA(G8:G11)
          var v = this.in_01_new_sell_ext_perc+this.in_01_new_sell_oth_perc+this.in_01_new_sell_int_perc+this.in_01_upsell_int_perc;
          return v;
        },
        calcd_01_new_sell_ext_eur: function(){
          // Formula F8 -> =G8*(F7+F6)
          var v = this.$roundIntNumber((this.in_01_new_sell_ext_perc/100.0*(this.calcd_01_fee_comm+this.calcd_01_prezzo_senza)));
          return v;
        },
        calcd_01_new_sell_oth_eur: function(){
          // Formula F9 -> =G9*(F7+F6)
          var v = this.$roundIntNumber((this.in_01_new_sell_oth_perc/100.0*(this.calcd_01_fee_comm+this.calcd_01_prezzo_senza)));
          return v;
        },
        calcd_01_new_sell_int_eur: function(){
          // Formula F10 -> =G10*(F7+F6)
          var v = this.$roundIntNumber((this.in_01_new_sell_int_perc/100.0*(this.calcd_01_fee_comm+this.calcd_01_prezzo_senza)));
          return v;
        },
        calcd_01_upsell_int_eur: function(){
          // Formula F11 -> =G11*(F7+F6)
          var v = this.$roundIntNumber((this.in_01_upsell_int_perc/100.0*(this.calcd_01_fee_comm+this.calcd_01_prezzo_senza)));
          return v;
        },
        calcd_02_extra_margine_tot_eur: function(){
          // Formula F12 -> =F13
          var v = this.$roundIntNumber(this.$fromEurToCent(this.in_02_extra_margine_partner_eur));
          return v;
        },
        calcd_02_extra_margine_tot_perc: function(){
          // Formula G12 -> =F12/F96
          var v = 0;
          if(this.calcd_prezzo_vendita_tot_eur){
            v = this.$calculatePerc(this.calcd_02_extra_margine_tot_eur/this.calcd_prezzo_vendita_tot_eur);
          }
          return v;
        },
        calcd_03_extra_margine_bonus_tot_eur: function(){
          // Formula F14 -> =F15
          var v = this.$roundIntNumber(this.calcd_03_extra_margine_bonus_eur);
          return v;
        },
        calcd_03_extra_margine_bonus_tot_perc: function(){
          // Formula G14 -> =F14/F96
          var v = 0;
          if(this.calcd_prezzo_vendita_tot_eur){
            v = this.$calculatePerc(this.calcd_03_extra_margine_bonus_tot_eur/this.calcd_prezzo_vendita_tot_eur);
          }
          return v;
        },
        calcd_03_extra_margine_bonus_eur: function(){
          // Formula F15 -> =F85
          var v = 0;
          if(this.in_03_extra_margine_bonus_who_user_id){
            v = this.$roundIntNumber(this.calcd_mar_bonus_plus_eur);
          }
          return v;
        },

      // -------------------
      // BLU
      // -------------------
        calcd_11_direzione_who_user_id: function(){
          // Uguale all'EA di progetto
          var v = this.cep.pl_id;
          return v;
        },
        calcd_11_direzione_tot_h: function(){
          // Formula E16 -> =E20
          var v = this.in_11_direzione_consul_input_h_qty;
          return v;
        },
        calcd_11_direzione_tot_eur: function(){
          // Formula F16 -> =SOMMA(F17:F20)
          var v = this.$roundIntNumber(this.calcd_11_direzione_team_fisso_eur+this.calcd_11_direzione_team_piva_eur+this.calcd_11_direzione_fornit_eur+this.calcd_11_direzione_consul_eur);
          return v;
        },
        calcd_11_direzione_tot_perc: function(){
          // Formula G16 -> =F16/F96
          var v = 0;
          if(this.calcd_prezzo_vendita_tot_eur){
            v = this.$calculatePerc(this.calcd_11_direzione_tot_eur/this.calcd_prezzo_vendita_tot_eur);
          }
          return v;
        },
        calcd_11_direzione_team_fisso_eur: function(){
          // Formula F17 -> =(E17*F21)
          var v = this.$roundIntNumber((this.in_11_direzione_team_fisso_input_perc/100.0*this.calcd_21_prod_team_stip_tot_eur));
          return v;
        },
        calcd_11_direzione_team_fisso_perc: function(){
          // Formula G17 -> =F17/F16
          var v = 0;
          if(this.calcd_11_direzione_tot_eur){
            v = this.$calculatePerc(this.calcd_11_direzione_team_fisso_eur/this.calcd_11_direzione_tot_eur);
          }
          return v;
        },
        calcd_11_direzione_team_piva_eur: function(){
          // Formula F18 -> =(E18*F32)
          var v = this.$roundIntNumber((this.in_11_direzione_team_piva_input_perc/100.0*this.calcd_22_prod_team_piva_tot_eur));
          return v;
        },
        calcd_11_direzione_team_piva_perc: function(){
          // Formula G18 -> =F18/F16
          var v = 0;
          if(this.calcd_11_direzione_tot_eur){
            v = this.$calculatePerc(this.calcd_11_direzione_team_piva_eur/this.calcd_11_direzione_tot_eur);
          }
          return v;
        },
        calcd_11_direzione_fornit_eur: function(){
          // Formula F19 -> =E19*F43
          var v = this.$roundIntNumber((this.in_11_direzione_fornit_input_perc/100.0*this.calcd_23_prod_fornit_piva_tot_eur));
          return v;
        },
        calcd_11_direzione_fornit_perc: function(){
          // Formula G19 -> =F19/F16
          var v = 0;
          if(this.calcd_11_direzione_tot_eur){
            v = this.$calculatePerc(this.calcd_11_direzione_fornit_eur/this.calcd_11_direzione_tot_eur);
          }
          return v;
        },
        calcd_11_direzione_consul_eur: function(){
          // Formula F20 -> =D20*E20
          var v = this.$roundIntNumber(this.$fromEurToCent(this.in_11_direzione_consul_input_eur)*this.in_11_direzione_consul_input_h_qty);
          return v;
        },
        calcd_11_direzione_consul_perc: function(){
          // Formula G20 -> =F20/F16
          var v = 0;
          if(this.calcd_11_direzione_tot_eur){
            v = this.$calculatePerc(this.calcd_11_direzione_consul_eur/this.calcd_11_direzione_tot_eur);
          }
          return v;
        },

      // -------------------
      // VERDE
      // -------------------
        calcd_21_prod_team_stip_tot_h: function(){
          // Formula E21 -> =SOMMA(E22:E31)
          var v = this.prod_team_stip_lines.filter((obj) => { return obj.in_21_prod_team_stip_act_type!==null; }).reduce((n, {in_21_prod_team_stip_h_qty}) => n+in_21_prod_team_stip_h_qty, 0);
          return v;
        },
        calcd_21_prod_team_stip_tot_eur: function(){
          // Formula F21 -> =SOMMA(F22:F31)
          var v = this.$roundIntNumber(this.prod_team_stip_lines.filter((obj) => { return obj.in_21_prod_team_stip_act_type!==null; }).reduce((n, {in_21_prod_team_stip_h_cost_eur, in_21_prod_team_stip_h_qty}) => n+this.$fromEurToCent(in_21_prod_team_stip_h_cost_eur)*in_21_prod_team_stip_h_qty, 0));
          return v;
        },
        calcd_21_prod_team_stip_tot_perc: function(){
          // Formula G21 -> =F21/F96
          var v = 0;
          if(this.calcd_prezzo_vendita_tot_eur){
            v = this.$calculatePerc(this.calcd_21_prod_team_stip_tot_eur/this.calcd_prezzo_vendita_tot_eur);
          }
          return v;
        },
        calcd_21_prod_team_stip_eur: function(){
          // Formula F22 -> =D22*E22
          return this.prod_team_stip_lines.map((obj) => {
            var v = 0;
            if(obj.in_21_prod_team_stip_act_type!==null){
              v = this.$roundIntNumber(this.$fromEurToCent(obj.in_21_prod_team_stip_h_cost_eur)*obj.in_21_prod_team_stip_h_qty);
            }
            return v;
          });
        },
        calcd_21_prod_team_stip_perc: function(){
          // Formula G22 -> =F22/F21
          return this.prod_team_stip_lines.map((obj) => {
            var v = 0;
            if(obj.in_21_prod_team_stip_act_type!==null){
              if(this.calcd_21_prod_team_stip_tot_eur){
                v = this.$calculatePerc(this.$fromEurToCent(obj.in_21_prod_team_stip_h_cost_eur)*obj.in_21_prod_team_stip_h_qty/this.calcd_21_prod_team_stip_tot_eur);
              }
            }
            return v;
          });
        },
        calcd_22_prod_team_piva_tot_h: function(){
          // Formula E32 -> =SOMMA(E33:E42)
          var v = this.prod_team_piva_lines.filter((obj) => { return obj.in_22_prod_team_piva_act_type!==null; }).reduce((n, {in_22_prod_team_piva_h_qty}) => n+in_22_prod_team_piva_h_qty, 0);
          return v;
        },
        calcd_22_prod_team_piva_tot_eur: function(){
          // Formula F32 -> =SOMMA(F33:F42)
          var v = this.$roundIntNumber(this.prod_team_piva_lines.filter((obj) => { return obj.in_22_prod_team_piva_act_type!==null; }).reduce((n, {in_22_prod_team_piva_h_cost_eur, in_22_prod_team_piva_h_qty}) => n+this.$fromEurToCent(in_22_prod_team_piva_h_cost_eur)*in_22_prod_team_piva_h_qty, 0));
          return v;
        },
        calcd_22_prod_team_piva_tot_perc: function(){
          // Formula G32 -> =F32/F96
          var v = 0;
          if(this.calcd_prezzo_vendita_tot_eur){
            v = this.$calculatePerc(this.calcd_22_prod_team_piva_tot_eur/this.calcd_prezzo_vendita_tot_eur);
          }
          return v;
        },
        calcd_22_prod_team_piva_eur: function(){
          // Formula F33 -> =D33*E33
          return this.prod_team_piva_lines.map((obj) => {
            var v = 0;
            if(obj.in_22_prod_team_piva_act_type!==null){
              v = this.$roundIntNumber(this.$fromEurToCent(obj.in_22_prod_team_piva_h_cost_eur)*obj.in_22_prod_team_piva_h_qty);
            }
            return v;
          });
        },
        calcd_22_prod_team_piva_perc: function(){
          // Formula G33 -> =F33/F32
          return this.prod_team_piva_lines.map((obj) => {
            var v = 0;
            if(obj.in_22_prod_team_piva_act_type!==null){
              if(this.calcd_22_prod_team_piva_tot_eur){
                v = this.$calculatePerc(this.$fromEurToCent(obj.in_22_prod_team_piva_h_cost_eur)*obj.in_22_prod_team_piva_h_qty/this.calcd_22_prod_team_piva_tot_eur);
              }
            }
            return v;
          });
        },
        calcd_23_prod_fornit_piva_tot_eur: function(){
          // Formula F43 -> =SOMMA(F44:F49)
          var v = this.$roundIntNumber(this.prod_fornit_piva_lines.filter((obj) => { return obj.in_23_prod_fornit_piva_act_desc!==null; }).reduce((n, {in_23_prod_fornit_piva_eur, in_22_prod_team_piva_h_qty}) => n+this.$fromEurToCent(in_23_prod_fornit_piva_eur), 0));
          return v;
        },
        calcd_23_prod_fornit_piva_tot_perc: function(){
          // Formula G43 -> =F43/F96
          var v = 0;
          if(this.calcd_prezzo_vendita_tot_eur){
            v = this.$calculatePerc(this.calcd_23_prod_fornit_piva_tot_eur/this.calcd_prezzo_vendita_tot_eur);
          }
          return v;
        },
        calcd_23_prod_fornit_piva_perc: function(){
          // Formula G44 -> =F44/F43
          return this.prod_fornit_piva_lines.map((obj) => {
            var v = 0;
            if(obj.in_23_prod_fornit_piva_act_desc!==null){
              if(this.calcd_23_prod_fornit_piva_tot_eur){
                v = this.$calculatePerc(this.$fromEurToCent(obj.in_23_prod_fornit_piva_eur)/this.calcd_23_prod_fornit_piva_tot_eur);
              }
            }
            return v;
          });
        },
        calcd_24_prod_media_tot_eur: function(){
          // Formula F50 -> =SOMMA(F51:F56)
          var v = this.$roundIntNumber(this.prod_media_lines.filter((obj) => { return obj.in_24_prod_media_desc!==null; }).reduce((n, {in_24_prod_media_eur}) => n+this.$fromEurToCent(in_24_prod_media_eur), 0));
          return v;
        },
        calcd_24_prod_media_tot_perc: function(){
          // Formula G50 -> =F50/F96
          var v = 0;
          if(this.calcd_prezzo_vendita_tot_eur){
            v = this.$calculatePerc(this.calcd_24_prod_media_tot_eur/this.calcd_prezzo_vendita_tot_eur);
          }
          return v;
        },
        calcd_24_prod_media_perc: function(){
          // Formula G51 -> =F51/F50
          return this.prod_media_lines.map((obj) => {
            var v = 0;
            if(obj.in_24_prod_media_desc!==null){
              if(this.calcd_24_prod_media_tot_eur){
                v = this.$calculatePerc(this.$fromEurToCent(obj.in_24_prod_media_eur)/this.calcd_24_prod_media_tot_eur);
              }
            }
            return v;
          });
        },
        calcd_25_prod_others_tot_eur: function(){
          // Formula F57 -> =SOMMA(F58:F63)
          var v = this.$roundIntNumber(this.prod_others_lines.filter((obj) => { return obj.in_25_prod_others_type!==null; }).reduce((n, {in_25_prod_others_eur}) => n+this.$fromEurToCent(in_25_prod_others_eur), 0));
          return v;
        },
        calcd_25_prod_others_tot_perc: function(){
          // Formula G57 -> =F57/F96
          var v = 0;
          if(this.calcd_prezzo_vendita_tot_eur){
            v = this.$calculatePerc(this.calcd_25_prod_others_tot_eur/this.calcd_prezzo_vendita_tot_eur);
          }
          return v;
        },
        calcd_25_prod_others_perc: function(){
          // Formula G58 -> =F58/F57
          return this.prod_others_lines.map((obj) => {
            var v = 0;
            if(obj.in_25_prod_others_type!==null){
              if(this.calcd_25_prod_others_tot_eur){
                v = this.$calculatePerc(this.$fromEurToCent(obj.in_25_prod_others_eur)/this.calcd_25_prod_others_tot_eur);
              }
            }
            return v;
          });
        },

      // -------------------
      // BLU
      // -------------------
        calcd_12_gest_tot_eur: function(){
          // Formula F67 -> =SOMMA(F68:F71)
          var v = this.$roundIntNumber(this.calcd_12_gest_alloc_fisso_piva_eur+this.calcd_12_gest_superv_fornit_eur+this.$fromEurToCent(this.in_12_gest_superv_event_eur)+this.calcd_12_gest_comp_plus_eur);
          return v;
        },
        calcd_12_gest_tot_perc: function(){
          // Formula G67 -> =F67/F96
          var v = 0;
          if(this.calcd_prezzo_vendita_tot_eur){
            v = this.$calculatePerc(this.calcd_12_gest_tot_eur/this.calcd_prezzo_vendita_tot_eur);
          }
          return v;
        },
        calcd_12_gest_alloc_fisso_piva_eur: function(){
          // Formula F68 -> =(E68*F21)+(E68*F32)
          var v = this.$roundIntNumber((this.in_12_gest_alloc_fisso_piva_input_perc/100.0*this.calcd_21_prod_team_stip_tot_eur)+(this.in_12_gest_alloc_fisso_piva_input_perc/100.0*this.calcd_22_prod_team_piva_tot_eur));
          return v;
        },
        calcd_12_gest_alloc_fisso_piva_perc: function(){
          // Formula G68 -> =F68/F67
          var v = 0;
          if(this.calcd_12_gest_tot_eur){
            v = this.$calculatePerc(this.calcd_12_gest_alloc_fisso_piva_eur/this.calcd_12_gest_tot_eur);
          }
          return v;
        },
        calcd_12_gest_superv_fornit_eur: function(){
          // Formula F69 -> =E69*F43
          var v = this.$roundIntNumber(this.in_12_gest_superv_fornit_input_perc/100.0*this.calcd_23_prod_fornit_piva_tot_eur);
          return v;
        },
        calcd_12_gest_superv_fornit_perc: function(){
          // Formula G69 -> =F69/F67
          var v = 0;
          if(this.calcd_12_gest_tot_eur){
            v = this.$calculatePerc(this.calcd_12_gest_superv_fornit_eur/this.calcd_12_gest_tot_eur);
          }
          return v;
        },
        calcd_12_gest_superv_event_perc: function(){
          // Formula G70 -> =F70/F67
          var v = 0;
          if(this.calcd_12_gest_tot_eur){
            v = this.$calculatePerc(this.$fromEurToCent(this.in_12_gest_superv_event_eur)/this.calcd_12_gest_tot_eur);
          }
          return v;
        },
        calcd_12_gest_comp_plus_eur: function(){
          // Formula F71 -> =F86
          var v = this.$roundIntNumber(this.calcd_mar_acc_gest_eur);
          return v;
        },
        calcd_12_gest_comp_plus_perc: function(){
          // Formula G71 -> =F71/F67
          var v = 0;
          if(this.calcd_12_gest_tot_eur){
            v = this.$calculatePerc(this.calcd_12_gest_comp_plus_eur/this.calcd_12_gest_tot_eur);
          }
          return v;
        },

      // -------------------
      // GIALLO
      // -------------------
        calcd_3_struct_tot_eur: function(){
          // Formula F72 -> =SOMMA(F73:F77)
          var v = this.$roundIntNumber(this.calcd_3_struct_dir_az_eur+this.calcd_3_struct_sede_eur+this.calcd_3_struct_rappr_eur+this.calcd_3_struct_ant_fin_eur+this.calcd_3_struct_comp_plus_eur);
          return v;
        },
        calcd_3_struct_tot_perc: function(){
          // Formula G72 -> =F72/F96
          var v = 0;
          if(this.calcd_prezzo_vendita_tot_eur){
            v = this.$calculatePerc(this.calcd_3_struct_tot_eur/this.calcd_prezzo_vendita_tot_eur);
          }
          return v;
        },
        calcd_3_struct_dir_az_eur: function(){
          // Formula F73 -> =(E73*F21)+(E73*F32)
          var v = this.$roundIntNumber((this.in_3_struct_dir_az_input_perc/100.0*this.calcd_21_prod_team_stip_tot_eur)+(this.in_3_struct_dir_az_input_perc/100.0*this.calcd_22_prod_team_piva_tot_eur));
          return v;
        },
        calcd_3_struct_dir_az_perc: function(){
          // Formula G73 -> =F73/F72
          var v = 0;
          if(this.calcd_3_struct_tot_eur){
            v = this.$calculatePerc(this.calcd_3_struct_dir_az_eur/this.calcd_3_struct_tot_eur);
          }
          return v;
        },
        calcd_3_struct_sede_eur: function(){
          // Formula F74 -> =(E74*F21)+(E74*F32)
          var v = this.$roundIntNumber((this.in_3_struct_sede_input_perc/100.0*this.calcd_21_prod_team_stip_tot_eur)+(this.in_3_struct_sede_input_perc/100.0*this.calcd_22_prod_team_piva_tot_eur));
          return v;
        },
        calcd_3_struct_sede_perc: function(){
          // Formula G74 -> =F74/F72
          var v = 0;
          if(this.calcd_3_struct_tot_eur){
            v = this.$calculatePerc(this.calcd_3_struct_sede_eur/this.calcd_3_struct_tot_eur);
          }
          return v;
        },
        calcd_3_struct_rappr_eur: function(){
          // Formula F75 -> =(E75*F21)+(E75*F32)+(E75*F43)
          var v = this.$roundIntNumber((this.in_3_struct_rappr_input_perc/100.0*this.calcd_21_prod_team_stip_tot_eur)+(this.in_3_struct_rappr_input_perc/100.0*this.calcd_22_prod_team_piva_tot_eur)+(this.in_3_struct_rappr_input_perc/100.0*this.calcd_23_prod_fornit_piva_tot_eur));
          return v;
        },
        calcd_3_struct_rappr_perc: function(){
          // Formula G75 -> =F75/F72
          var v = 0;
          if(this.calcd_3_struct_tot_eur){
            v = this.$calculatePerc(this.calcd_3_struct_rappr_eur/this.calcd_3_struct_tot_eur);
          }
          return v;
        },
        calcd_3_struct_ant_fin_eur: function(){
          // Formula F76 -> =E76*F50
          var v = this.$roundIntNumber((this.in_3_struct_ant_fin_input_perc/100.0*this.calcd_24_prod_media_tot_eur));
          return v;
        },
        calcd_3_struct_ant_fin_perc: function(){
          // Formula G76 -> =F76/F72
          var v = 0;
          if(this.calcd_3_struct_tot_eur){
            v = this.$calculatePerc(this.calcd_3_struct_ant_fin_eur/this.calcd_3_struct_tot_eur);
          }
          return v;
        },
        calcd_3_struct_comp_plus_eur: function(){
          // Formula F77 -> =F87
          var v = this.$roundIntNumber(this.calcd_mar_acc_struct_eur);
          return v;
        },
        calcd_3_struct_comp_plus_perc: function(){
          // Formula G77 -> =F77/F72
          var v = 0;
          if(this.calcd_3_struct_tot_eur){
            v = this.$calculatePerc(this.calcd_3_struct_comp_plus_eur/this.calcd_3_struct_tot_eur);
          }
          return v;
        },

      // -------------------
      // ROSINO
      // -------------------
        calcd_4_brand_tot_eur: function(){
          // Formula F78 -> =SOMMA(F79:F81)
          var v = this.$roundIntNumber(this.calcd_4_brand_coo_sales_eur+this.calcd_4_brand_coo_com_eur+this.calcd_4_brand_comp_plus_eur);
          return v;
        },
        calcd_4_brand_tot_perc: function(){
          // Formula G78 -> =F78/F96
          var v = 0;
          if(this.calcd_prezzo_vendita_tot_eur){
            v = this.$calculatePerc(this.calcd_4_brand_tot_eur/this.calcd_prezzo_vendita_tot_eur);
          }
          return v;
        },
        calcd_4_brand_coo_sales_eur: function(){
          // Formula F79 -> =(E79*F21)+(E79*F32)
          var v = this.$roundIntNumber((this.in_4_brand_coo_sales_input_perc/100.0*this.calcd_21_prod_team_stip_tot_eur)+(this.in_4_brand_coo_sales_input_perc/100.0*this.calcd_22_prod_team_piva_tot_eur));
          return v;
        },
        calcd_4_brand_coo_sales_perc: function(){
          // Formula G79 -> =F79/F78
          var v = 0;
          if(this.calcd_4_brand_tot_eur){
            v = this.$calculatePerc(this.calcd_4_brand_coo_sales_eur/this.calcd_4_brand_tot_eur);
          }
          return v;
        },
        calcd_4_brand_coo_com_eur: function(){
          // Formula F80 -> =(E80*F21)+(E80*F32)
          var v = this.$roundIntNumber((this.in_4_brand_coo_com_input_perc/100.0*this.calcd_21_prod_team_stip_tot_eur)+(this.in_4_brand_coo_com_input_perc/100.0*this.calcd_22_prod_team_piva_tot_eur));
          return v;
        },
        calcd_4_brand_coo_com_perc: function(){
          // Formula G80 -> =F80/F78
          var v = 0;
          if(this.calcd_4_brand_tot_eur){
            v = this.$calculatePerc(this.calcd_4_brand_coo_com_eur/this.calcd_4_brand_tot_eur);
          }
          return v;
        },
        calcd_4_brand_comp_plus_eur: function(){
          // Formula F81 -> =F88
          var v = this.$roundIntNumber(this.calcd_mar_acc_brand_eur);
          return v;
        },
        calcd_4_brand_comp_plus_perc: function(){
          // Formula G81 -> =F81/F78
          var v = 0;
          if(this.calcd_4_brand_tot_eur){
            v = this.$calculatePerc(this.calcd_4_brand_comp_plus_eur/this.calcd_4_brand_tot_eur);
          }
          return v;
        },

      // -------------------
      // VIOLETTO
      // -------------------
        calcd_mar_bonus_plus_eur: function(){
          // Formula F85 -> =G85*F84
          var v = this.$roundIntNumber((this.in_mar_bonus_plus_perc/100.0*this.$fromEurToCent(this.in_mar_extra_margine_eur)));
          return v;
        },

      // -------------------
      // AZZURRINO
      // -------------------
        calcd_mar_acc_gest_eur: function(){
          // Formula F86 -> =G86*F84
          var v = this.$roundIntNumber((this.in_mar_acc_gest_perc/100.0*this.$fromEurToCent(this.in_mar_extra_margine_eur)));
          return v;
        },

      // -------------------
      // GIALLO
      // -------------------
        calcd_mar_acc_struct_eur: function(){
          // Formula F87 -> =G87*F84
          var v = this.$roundIntNumber((this.in_mar_acc_struct_perc/100.0*this.$fromEurToCent(this.in_mar_extra_margine_eur)));
          return v;
        },

      // -------------------
      // ROSINO
      // -------------------
        calcd_mar_acc_brand_eur: function(){
          // Formula F88 -> =G88*F84
          var v = this.$roundIntNumber((this.in_mar_acc_brand_perc/100.0*this.$fromEurToCent(this.in_mar_extra_margine_eur)));
          return v;
        },

      // -------------------
      // ARANCIONE
      // -------------------
        calcd_51_mar_tot_eur: function(){
          // Formula F89 -> =SOMMA(F90:F92)
          var v = this.$roundIntNumber(this.calcd_51_mar_team_fisso_eur+this.calcd_51_mar_team_piva_eur+this.calcd_51_mar_fornit_eur);
          return v;
        },
        calcd_51_mar_tot_perc: function(){
          // Formula G89 -> =F89/F96
          var v = 0;
          if(this.calcd_prezzo_vendita_tot_eur){
            v = this.$calculatePerc(this.calcd_51_mar_tot_eur/this.calcd_prezzo_vendita_tot_eur);
          }
          return v;
        },
        calcd_51_mar_team_fisso_eur: function(){
          // Formula F90 -> =E90*F21
          var v = this.$roundIntNumber((this.in_51_mar_fisso_input_perc/100.0*this.calcd_21_prod_team_stip_tot_eur));
          return v;
        },
        calcd_51_mar_team_fisso_perc: function(){
          // Formula G90 -> =F90/F89
          var v = 0;
          if(this.calcd_51_mar_tot_eur){
            v = this.$calculatePerc(this.calcd_51_mar_team_fisso_eur/this.calcd_51_mar_tot_eur);
          }
          return v;
        },
        calcd_51_mar_team_piva_eur: function(){
          // Formula F91 -> =E91*F32
          var v = this.$roundIntNumber((this.in_51_mar_team_piva_input_perc/100.0*this.calcd_22_prod_team_piva_tot_eur));
          return v;
        },
        calcd_51_mar_team_piva_perc: function(){
          // Formula G91 -> =F91/F89
          var v = 0;
          if(this.calcd_51_mar_tot_eur){
            v = this.$calculatePerc(this.calcd_51_mar_team_piva_eur/this.calcd_51_mar_tot_eur);
          }
          return v;
        },
        calcd_51_mar_fornit_eur: function(){
          // Formula F92 -> =E92*F43
          var v = this.$roundIntNumber((this.in_51_mar_fornit_input_perc/100.0*this.calcd_23_prod_fornit_piva_tot_eur));
          return v;
        },
        calcd_51_mar_fornit_perc: function(){
          // Formula G92 -> =F92/F89
          var v = 0;
          if(this.calcd_51_mar_tot_eur){
            v = this.$calculatePerc(this.calcd_51_mar_fornit_eur/this.calcd_51_mar_tot_eur);
          }
          return v;
        },
        calcd_53_mar_fee_tot_eur: function(){
          // Formula F93 -> =F94
          var v = this.$roundIntNumber(this.calcd_53_mar_fee_comm_eur);
          return v;
        },
        calcd_53_mar_fee_tot_perc: function(){
          // Formula G93 -> =F93/F96
          var v = 0;
          if(this.calcd_prezzo_vendita_tot_eur){
            v = this.$calculatePerc(this.calcd_53_mar_fee_tot_eur/this.calcd_prezzo_vendita_tot_eur);
          }
          return v;
        },
        calcd_53_mar_fee_comm_eur: function(){
          // Formula F94 -> =F7-F8-F10-F9-F11
          var v = this.$roundIntNumber(this.calcd_01_fee_comm-this.calcd_01_new_sell_ext_eur-this.calcd_01_new_sell_oth_eur-this.calcd_01_new_sell_int_eur-this.calcd_01_upsell_int_eur);
          return v;
        },
        calcd_53_mar_fee_comm_perc: function(){
          // Formula G94 -> =F94/F93
          var v = 0;
          if(this.calcd_53_mar_fee_tot_eur){
            v = this.$calculatePerc(this.calcd_53_mar_fee_comm_eur/this.calcd_53_mar_fee_tot_eur);
          }
          return v;
        },

      // -------------------
      // BORDEAUX
      // -------------------
        calcd_prezzo_vendita_tot_eur: function(){
          // Formula F96 -> =F5+F12+F14+F16+F21+F32+F43+F50+F57+F67+F72+F78+F89+F93
          var v = this.$roundIntNumber(this.calcd_01_tot_eur+this.calcd_02_extra_margine_tot_eur+this.calcd_03_extra_margine_bonus_tot_eur+this.calcd_11_direzione_tot_eur+this.calcd_21_prod_team_stip_tot_eur+this.calcd_22_prod_team_piva_tot_eur+this.calcd_23_prod_fornit_piva_tot_eur+this.calcd_24_prod_media_tot_eur+this.calcd_25_prod_others_tot_eur+this.calcd_12_gest_tot_eur+this.calcd_3_struct_tot_eur+this.calcd_4_brand_tot_eur+this.calcd_51_mar_tot_eur+this.calcd_53_mar_fee_tot_eur);
          this.$emit('updateTotalPrice', v);
          return v;
        },

      // -------------------
      // GRIGIO
      // -------------------
        calcd_csp_fissi_tot_perc: function(){
          // Formula B100 -> =G100+G101
          var v = this.$roundFloatNumber(this.calcd_csp_fissi_prod_int_tot_perc+this.calcd_csp_fissi_mol_tot_perc);
          return v;
        },
        calcd_csp_fissi_prod_int_tot_h: function(){
          // Formula E100 -> =E21
          var v = this.calcd_21_prod_team_stip_tot_h;
          return v;
        },
        calcd_csp_fissi_prod_int_tot_eur: function(){
          // Formula F100 -> =F21
          var v = this.$roundIntNumber(this.calcd_21_prod_team_stip_tot_eur);
          return v;
        },
        calcd_csp_fissi_prod_int_tot_perc: function(){
          // Formula G100 -> =F100/F96
          var v = 0;
          if(this.calcd_prezzo_vendita_tot_eur){
            v = this.$calculatePerc(this.calcd_csp_fissi_prod_int_tot_eur/this.calcd_prezzo_vendita_tot_eur);
          }
          return v;
        },
        calcd_csp_fissi_mol_tot_eur: function(){
          // Formula F101 -> =F67+F72+F78+F89+F93
          var v = this.$roundIntNumber(this.calcd_12_gest_tot_eur+this.calcd_3_struct_tot_eur+this.calcd_4_brand_tot_eur+this.calcd_51_mar_tot_eur+this.calcd_53_mar_fee_tot_eur);
          return v;
        },
        calcd_csp_fissi_mol_tot_perc: function(){
          // Formula G101 -> =F101/F96
          var v = 0;
          if(this.calcd_prezzo_vendita_tot_eur){
            v = this.$calculatePerc(this.calcd_csp_fissi_mol_tot_eur/this.calcd_prezzo_vendita_tot_eur);
          }
          return v;
        },
        calcd_csp_var_tot_perc: function(){
          // Formula B102 -> =G103+G102+G104+G105+G106
          var v = this.$roundFloatNumber(this.calcd_csp_var_ea_tot_perc+this.calcd_csp_var_segnal_tot_perc+this.calcd_csp_var_prod_int_piva_tot_perc+this.calcd_csp_var_prod_est_piva_tot_perc+this.calcd_csp_var_prod_other_est_perc);
          return v;
        },
        calcd_csp_var_segnal_tot_eur: function(){
          // Formula F102 -> =F8+F9+F12
          var v = this.$roundIntNumber(this.calcd_01_new_sell_ext_eur+this.calcd_01_new_sell_oth_eur+this.calcd_02_extra_margine_tot_eur);
          return v;
        },
        calcd_csp_var_segnal_tot_perc: function(){
          // Formula G102 -> =F102/F96
          var v = 0;
          if(this.calcd_prezzo_vendita_tot_eur){
            v = this.$calculatePerc(this.calcd_csp_var_segnal_tot_eur/this.calcd_prezzo_vendita_tot_eur);
          }
          return v;
        },
        calcd_csp_var_ea_tot_eur: function(){
          // Formula F103 -> =F10+F11+F15+F17+F18+F19+F20
          var v = this.$roundIntNumber(this.calcd_01_new_sell_int_eur+this.calcd_01_upsell_int_eur+this.calcd_03_extra_margine_bonus_eur+this.calcd_11_direzione_team_fisso_eur+this.calcd_11_direzione_team_piva_eur+this.calcd_11_direzione_fornit_eur+this.calcd_11_direzione_consul_eur);
          return v;
        },
        calcd_csp_var_ea_tot_perc: function(){
          // Formula G103 -> =F103/F96
          var v = 0;
          if(this.calcd_prezzo_vendita_tot_eur){
            v = this.$calculatePerc(this.calcd_csp_var_ea_tot_eur/this.calcd_prezzo_vendita_tot_eur);
          }
          return v;
        },
        calcd_csp_var_prod_piva_tot_h: function(){
          // Formula E104 -> =E16+E32
          var v = this.calcd_11_direzione_tot_h+this.calcd_22_prod_team_piva_tot_h;
          return v;
        },
        calcd_csp_var_prod_int_piva_tot_eur: function(){
          // Formula F104 -> =F32
          var v = this.$roundIntNumber(this.calcd_22_prod_team_piva_tot_eur);
          return v;
        },
        calcd_csp_var_prod_int_piva_tot_perc: function(){
          // Formula G104 -> =F104/F96
          var v = 0;
          if(this.calcd_prezzo_vendita_tot_eur){
            v = this.$calculatePerc(this.calcd_csp_var_prod_int_piva_tot_eur/this.calcd_prezzo_vendita_tot_eur);
          }
          return v;
        },
        calcd_csp_var_prod_est_piva_tot_eur: function(){
          // Formula F105 -> =F43
          var v = this.$roundIntNumber(this.calcd_23_prod_fornit_piva_tot_eur);
          return v;
        },
        calcd_csp_var_prod_est_piva_tot_perc: function(){
          // Formula G105 -> =F105/F96
          var v = 0;
          if(this.calcd_prezzo_vendita_tot_eur){
            v = this.$calculatePerc(this.calcd_csp_var_prod_est_piva_tot_eur/this.calcd_prezzo_vendita_tot_eur);
          }
          return v;
        },
        calcd_csp_var_prod_other_est_eur: function(){
          // Formula F106 -> =F50+F57
          var v = this.$roundIntNumber(this.calcd_24_prod_media_tot_eur+this.calcd_25_prod_others_tot_eur);
          return v;
        },
        calcd_csp_var_prod_other_est_perc: function(){
          // Formula G106 -> =F106/F96
          var v = 0;
          if(this.calcd_prezzo_vendita_tot_eur){
            v = this.$calculatePerc(this.calcd_csp_var_prod_other_est_eur/this.calcd_prezzo_vendita_tot_eur);
          }
          return v;
        },

      // -------------------
      // VIOLETTO
      // -------------------
        calcd_cvb_comm_tot_eur: function(){
          // Formula F110 -> =F5+F12+F14
          var v = this.$roundIntNumber(this.calcd_01_tot_eur+this.calcd_02_extra_margine_tot_eur+this.calcd_03_extra_margine_bonus_tot_eur);
          return v;
        },
        calcd_cvb_comm_tot_perc: function(){
          // Formula G110 -> =F110/F96
          var v = 0;
          if(this.calcd_prezzo_vendita_tot_eur){
            v = this.$calculatePerc(this.calcd_cvb_comm_tot_eur/this.calcd_prezzo_vendita_tot_eur);
          }
          return v;
        },

      // -------------------
      // AZZURRINO
      // -------------------
        calcd_cvb_dir_tot_eur: function(){
          // Formula F111 -> =F16
          var v = this.$roundIntNumber(this.calcd_11_direzione_tot_eur);
          return v;
        },
        calcd_cvb_dir_tot_perc: function(){
          // Formula G111 -> =F111/F96
          var v = 0;
          if(this.calcd_prezzo_vendita_tot_eur){
            v = this.$calculatePerc(this.calcd_cvb_dir_tot_eur/this.calcd_prezzo_vendita_tot_eur);
          }
          return v;
        },

      // -------------------
      // VERDE
      // -------------------
        calcd_cvb_prod_tot_eur: function(){
          // Formula F112 -> =F21+F32+F43+F50+F57
          var v = this.$roundIntNumber(this.calcd_21_prod_team_stip_tot_eur+this.calcd_22_prod_team_piva_tot_eur+this.calcd_23_prod_fornit_piva_tot_eur+this.calcd_24_prod_media_tot_eur+this.calcd_25_prod_others_tot_eur);
          return v;
        },
        calcd_cvb_prod_tot_perc: function(){
          // Formula G112 -> =F112/F96
          var v = 0;
          if(this.calcd_prezzo_vendita_tot_eur){
            v = this.$calculatePerc(this.calcd_cvb_prod_tot_eur/this.calcd_prezzo_vendita_tot_eur);
          }
          return v;
        },

      // -------------------
      // ARANCIONE
      // -------------------
        calcd_cvb_mol_tot_eur: function(){
          // Formula F113 -> =F67+F72+F78+F89+F93
          var v = this.$roundIntNumber(this.calcd_12_gest_tot_eur+this.calcd_3_struct_tot_eur+this.calcd_4_brand_tot_eur+this.calcd_51_mar_tot_eur+this.calcd_53_mar_fee_tot_eur);
          return v;
        },
        calcd_cvb_mol_tot_perc: function(){
          // Formula G113 -> =F113/F96
          var v = 0;
          if(this.calcd_prezzo_vendita_tot_eur){
            v = this.$calculatePerc(this.calcd_cvb_mol_tot_eur/this.calcd_prezzo_vendita_tot_eur);
          }
          return v;
        },

        avg_calcd_csp_fissi_tot_perc: function(){
          return this.avg_values.avg_fissi_prod_int_tot_perc + this.avg_values.avg_fissi_mol_tot_perc;
        },

        avg_calcd_csp_var_tot_perc: function(){
          return this.avg_values.avg_var_segnal_tot_perc + this.avg_values.avg_var_ea_tot_perc + this.avg_values.avg_var_prod_int_piva_tot_perc + this.avg_values.avg_var_prod_est_piva_tot_perc + this.avg_values.avg_var_prod_other_est_perc;
        },
    },

    methods: {
      ...helperFunctions,

      updateNewAndUpSellPerc(){
        if(this.is_without_fee_comm==1){
          // Sets new and up sell percentages to 0
          this.in_01_new_sell_ext_perc = 0;
          this.in_01_new_sell_oth_perc = 0;
          this.in_01_new_sell_int_perc = 0;
          this.in_01_upsell_int_perc = 0;
        }
      },

      showExtraMarginCalculator(){
        this.extra_margin_calculator = true;
      },

      hideExtraMarginCalculator(){
        this.extra_margin_calculator = false;
        this.calculator_btn_class = '';
      },

      calculateExtraMarginValue(){
        var price_to_reach = this.extra_margin_calculator_value*100;
        if(price_to_reach && price_to_reach>0 && price_to_reach>this.calcd_prezzo_vendita_tot_eur){
          var diff = -1;
          while(diff>0.1 || diff<-0.1){
            diff = (parseInt((price_to_reach-this.calcd_prezzo_vendita_tot_eur)/2*100)/100)/100;
            this.in_mar_extra_margine_eur += diff;
          }
        }
        this.in_mar_extra_margine_eur = parseInt(this.in_mar_extra_margine_eur*100)/100;
        this.hideExtraMarginCalculator();
      },

      applyExtraMarginCalculatorValue(){
        this.calculator_btn_class = 'loading';
        setTimeout(this.calculateExtraMarginValue, 250);
      },

      getRelativePerc(type, value){
        var perc = 0;
        switch(type){
          case 'fissi':
            if(this.calcd_csp_fissi_tot_perc!=0){
              perc = value/this.calcd_csp_fissi_tot_perc*100;
            }
            break;
          case 'variabili':
            if(this.calcd_csp_var_tot_perc!=0){
              perc = value/this.calcd_csp_var_tot_perc*100;
            }
            break;
          case 'avg_fissi':
            if(this.avg_calcd_csp_fissi_tot_perc!=0){
              perc = value/this.avg_calcd_csp_fissi_tot_perc*100;
            }
            break;
          case 'avg_variabili':
            if(this.avg_calcd_csp_var_tot_perc!=0){
              perc = value/this.avg_calcd_csp_var_tot_perc*100;
            }
            break;
          default:break;
        }
        return this.$roundOneDecimal(perc);
      },

      initProdTeamStipLines(){
        var arr = [];
        for(var i=0;i<this.stip_act_types.length;i++){
          var val = this.stip_act_types[i];
          var obj = this.objProdTeamStipLine();
          obj.in_21_prod_team_stip_act_type = val.value;
          if(this.cep_lines && this.cep_lines.prod_team_stip_lines && this.cep_lines.prod_team_stip_lines.length>0){
            var res = this.cep_lines.prod_team_stip_lines.filter((item) => { return val.value==this.$getEnumValue(this.stip_act_types, item.in_21_prod_team_stip_act_type) });
            if(this.is_test){
              res = this.cep_lines.prod_team_stip_lines.filter((item) => { return val.value==item.in_21_prod_team_stip_act_type });
            }
            if(res && res.length>0){
              var in_21_prod_team_stip_who_str = null;
              var in_21_prod_team_stip_h_cost_eur = null;
              var in_21_prod_team_stip_h_qty = null;
              for(var x=0;x<res.length;x++){
                var res_obj = res[x];
                in_21_prod_team_stip_who_str = res_obj.in_21_prod_team_stip_who_str;
                in_21_prod_team_stip_h_cost_eur = this.$fromCentToEur(res_obj.in_21_prod_team_stip_h_cost_eur);
                in_21_prod_team_stip_h_qty += this.$stringToFloat(res_obj.in_21_prod_team_stip_h_qty);
              }
              obj.in_21_prod_team_stip_who_str = in_21_prod_team_stip_who_str;
              obj.in_21_prod_team_stip_h_cost_eur = in_21_prod_team_stip_h_cost_eur;
              obj.in_21_prod_team_stip_h_qty = in_21_prod_team_stip_h_qty;
            }
          }
          arr.push(obj);
        }
        this.prod_team_stip_lines = arr;
      },

      initUpsellIntWhoPlId(){
        // Se c'è EA è uguale a ea_id, se non c'è EA ma c'è PL è uguale a pl_id, altrimenti è null
        var val = null;
        if(this.cep.ea_id){
          val = this.cep.ea_id;
        }
        else if(this.cep.pl_id){
          val = this.cep.pl_id;
        }
        return val;
      },

      renderPieChart(){
        if(!this.is_test){
          if(this.pie_chart){
            var data = [
              {
                category: 'Costi commerciali',
                value: this.calcd_cvb_comm_tot_perc,
              },
              {
                category: 'Costi di direzione',
                value: this.calcd_cvb_dir_tot_perc,
              },
              {
                category: 'Costi di produzione',
                value: this.calcd_cvb_prod_tot_perc,
              },
              {
                category: 'MOL',
                value: this.calcd_cvb_mol_tot_perc,
              },
            ];
            this.pie_chart_series.data.setAll(data);
          }
          else{
            var pie_chart = am5.Root.new(this.$refs.pie_chart);
            pie_chart.setThemes([am5themes_Animated.new(pie_chart)]);

            var chart = pie_chart.container.children.push(
              am5percent.PieChart.new(pie_chart, {
                radius: am5.percent(70),
                innerRadius: am5.percent(85)
              })
            );

            // Define data
            var data = [
              {
                category: 'Costi commerciali',
                value: this.calcd_cvb_comm_tot_perc,
              },
              {
                category: 'Costi di direzione',
                value: this.calcd_cvb_dir_tot_perc,
              },
              {
                category: 'Costi di produzione',
                value: this.calcd_cvb_prod_tot_perc,
              },
              {
                category: 'MOL',
                value: this.calcd_cvb_mol_tot_perc,
              },
            ];

            this.pie_chart_series = chart.series.push(
              am5percent.PieSeries.new(pie_chart, {
                name: 'Series',
                categoryField: 'category',
                valueField: 'value'
              })
            );

            this.pie_chart_series.get('colors').set('colors', [
              am5.color(0x9B49ED),
              am5.color(0x88A9F2),
              am5.color(0x42C38C),
              am5.color(0xE8B62E)
            ]);

            this.pie_chart_series.data.setAll(data);

            this.pie_chart_series.labels.template.set('visible', false);
            this.pie_chart_series.ticks.template.set('visible', false);

            this.pie_chart = pie_chart;
          }
        }
      },

      renderRadarChart(){
        if(!this.is_test){
          if(this.radar_chart){
            // Define data
            var data = [
              {
                category: 'Costi commerciali',
                value: this.calcd_cvb_comm_tot_perc,
                avg_value: this.avg_values.avg_comm_tot_perc,
              },
              {
                category: 'Costi di direzione',
                value: this.calcd_cvb_dir_tot_perc,
                avg_value: this.avg_values.avg_dir_tot_perc,
              },
              {
                category: 'Costi di produzione',
                value: this.calcd_cvb_prod_tot_perc,
                avg_value: this.avg_values.avg_prod_tot_perc,
              },
              {
                category: 'MOL',
                value: this.calcd_cvb_mol_tot_perc,
                avg_value: this.avg_values.avg_mol_tot_perc,
              },
            ];
            this.radar_chart_series.data.setAll(data);
          }
          else{
            var radar_chart = am5.Root.new(this.$refs.radar_chart);
            radar_chart.setThemes([am5themes_Animated.new(radar_chart)]);

            var chart = radar_chart.container.children.push(
              am5radar.RadarChart.new(radar_chart, {
                panX: false,
                panY: false,
                radius: am5.percent(90),
                innerRadius: am5.percent(10),
              })
            );

            var xRenderer = am5radar.AxisRendererCircular.new(radar_chart, {});
            xRenderer.labels.template.setAll({
              radius: 10
            });

            var xAxis = chart.xAxes.push(
              am5xy.CategoryAxis.new(radar_chart, {
                maxDeviation: 0,
                categoryField: 'category',
                renderer: xRenderer
              })
            );

            xAxis.get('renderer').labels.template.adapters.add('fill', (fill, target) => {
              var color = '#9B49ED';
              if(target.dataItem && target.dataItem.dataContext){
                var category = target.dataItem.dataContext.category;
                switch(category){
                  case 'Costi commerciali':
                    color = '#9B49ED';
                    break;
                  case 'Costi di direzione':
                    color = '#88A9F2';
                    break;
                  case 'Costi di produzione':
                    color = '#42C38C';
                    break;
                  case 'MOL':
                    color = '#E8B62E';
                    break;
                  default:break;
                }
              }
              return color;
            });

            var yAxis = chart.yAxes.push(
              am5xy.ValueAxis.new(radar_chart, {
                renderer: am5radar.AxisRendererRadial.new(radar_chart, {})
              })
            );

            yAxis.get("renderer").labels.template.setAll({
              visible: false
            });

            // Define data
            var data = [
              {
                category: 'Costi commerciali',
                value: this.calcd_cvb_comm_tot_perc,
                avg_value: this.avg_values.avg_comm_tot_perc,
              },
              {
                category: 'Costi di direzione',
                value: this.calcd_cvb_dir_tot_perc,
                avg_value: this.avg_values.avg_dir_tot_perc,
              },
              {
                category: 'Costi di produzione',
                value: this.calcd_cvb_prod_tot_perc,
                avg_value: this.avg_values.avg_prod_tot_perc,
              },
              {
                category: 'MOL',
                value: this.calcd_cvb_mol_tot_perc,
                avg_value: this.avg_values.avg_mol_tot_perc,
              },
            ];

            this.radar_chart_series = chart.series.push(
              am5radar.RadarLineSeries.new(radar_chart, {
                name: 'Series',
                xAxis: xAxis,
                yAxis: yAxis,
                categoryXField: 'category',
                valueYField: 'value',
                connectEnds: true,
                stroke: am5.color('#981869'),
                tooltip: am5.Tooltip.new(radar_chart, {
                  labelText: "{categoryX}: {valueY}"
                })
              })
            );

            this.radar_chart_series.strokes.template.setAll({
              strokeWidth: 2
            });

            var avg_series = chart.series.push(
              am5radar.RadarLineSeries.new(radar_chart, {
                name: 'AVG Series',
                xAxis: xAxis,
                yAxis: yAxis,
                categoryXField: 'category',
                valueYField: 'avg_value',
                connectEnds: true,
                stroke: am5.color('#aaaaaa'),
                tooltip: am5.Tooltip.new(radar_chart, {
                  labelText: "{categoryX}: {valueY}"
                })
              })
            );

            avg_series.strokes.template.setAll({
              strokeWidth: 2
            });

            this.radar_chart_series.data.setAll(data);
            avg_series.data.setAll(data);
            xAxis.data.setAll(data);

            this.radar_chart = radar_chart;
          }
        }
      },

      toggleExpandBox(box){
        var index = this.expanded_boxes.indexOf(box);
        if(index>=0){
          this.expanded_boxes.splice(index, 1);
        }
        else{
          this.expanded_boxes.push(box);
        }
      },

      objProdTeamStipLine(){
        return {
          id: null,
          in_21_prod_team_stip_act_type: null,
          in_21_prod_team_stip_who_str: null,
          in_21_prod_team_stip_kind: 1,
          in_21_prod_team_stip_h_cost_eur: this.$fromCentToEur(this.default_values.in_21_prod_team_stip_h_cost_eur),
          in_21_prod_team_stip_h_qty: null,
        };
      },

      objProdTeamPivaLine(){
        return {
          id: null,
          in_22_prod_team_piva_act_type: null,
          in_22_prod_team_piva_who_user_id: null,
          in_22_prod_team_piva_kind: 0,
          in_22_prod_team_piva_h_cost_eur: null,
          in_22_prod_team_piva_h_qty: null,
        };
      },

      objProdFornitPivaLine(){
        return {
          id: null,
          in_23_prod_fornit_piva_act_desc: null,
          in_23_prod_fornit_piva_who_str: null,
          in_23_prod_fornit_piva_kind: 0,
          in_23_prod_fornit_piva_eur: null,
        };
      },

      objProdMediaLine(){
        return {
          id: null,
          in_24_prod_media_desc: null,
          in_24_prod_media_desc1: null,
          in_24_prod_media_kind: 0,
          in_24_prod_media_eur: null,
        };
      },

      objProdOthersLine(){
        return {
          id: null,
          in_25_prod_others_type: null,
          in_25_prod_others_desc: null,
          in_25_prod_others_kind: 0,
          in_25_prod_others_eur: null,
        };
      },

      initLines(objs, type){
        switch(type){
          case 'team_stip':
            var arr = [];
            for(var i=0;i<objs.length;i++){
              var obj = objs[i];
              var t_obj = {
                id: obj.id,
                in_21_prod_team_stip_act_type: !this.is_test ? this.$getEnumValue(this.stip_act_types, obj.in_21_prod_team_stip_act_type) : obj.in_21_prod_team_stip_act_type,
                in_21_prod_team_stip_who_str: obj.in_21_prod_team_stip_who_str,
                in_21_prod_team_stip_kind: !this.is_test ? this.$getEnumValue(this.item_general_kinds, obj.in_21_prod_team_stip_kind) : obj.in_21_prod_team_stip_kind,
                in_21_prod_team_stip_h_cost_eur: this.$fromCentToEur(obj.in_21_prod_team_stip_h_cost_eur),
                in_21_prod_team_stip_h_qty: this.$stringToFloat(obj.in_21_prod_team_stip_h_qty),
              };
              arr.push(t_obj);
            }
            this.prod_team_stip_lines = arr;
            break;
          case 'team_piva':
            var arr = [];
            for(var i=0;i<objs.length;i++){
              var obj = objs[i];
              var t_obj = {
                id: obj.id,
                in_22_prod_team_piva_act_type: !this.is_test ? this.$getEnumValue(this.stip_act_types, obj.in_22_prod_team_piva_act_type) : obj.in_22_prod_team_piva_act_type,
                in_22_prod_team_piva_who_user_id: obj.in_22_prod_team_piva_who_user_id,
                in_22_prod_team_piva_kind: !this.is_test ? this.$getEnumValue(this.item_general_kinds, obj.in_22_prod_team_piva_kind) : obj.in_22_prod_team_piva_kind,
                in_22_prod_team_piva_h_cost_eur: this.$fromCentToEur(obj.in_22_prod_team_piva_h_cost_eur),
                in_22_prod_team_piva_h_qty: this.$stringToFloat(obj.in_22_prod_team_piva_h_qty),
              };
              arr.push(t_obj);
            }
            this.prod_team_piva_lines = arr;
            break;
          case 'fornit_piva':
            var arr = [];
            for(var i=0;i<objs.length;i++){
              var obj = objs[i];
              var t_obj = {
                id: obj.id,
                in_23_prod_fornit_piva_act_desc: obj.in_23_prod_fornit_piva_act_desc,
                in_23_prod_fornit_piva_who_str: obj.in_23_prod_fornit_piva_who_str,
                in_23_prod_fornit_piva_kind: !this.is_test ? this.$getEnumValue(this.item_general_kinds, obj.in_23_prod_fornit_piva_kind) : obj.in_23_prod_fornit_piva_kind,
                in_23_prod_fornit_piva_eur: this.$fromCentToEur(obj.in_23_prod_fornit_piva_eur),
              };
              arr.push(t_obj);
            }
            this.prod_fornit_piva_lines = arr;
            break;
          case 'media':
            var arr = [];
            for(var i=0;i<objs.length;i++){
              var obj = objs[i];
              var t_obj = {
                id: obj.id,
                in_24_prod_media_desc: obj.in_24_prod_media_desc,
                in_24_prod_media_desc1: obj.in_24_prod_media_desc1,
                in_24_prod_media_kind: !this.is_test ? this.$getEnumValue(this.item_general_kinds, obj.in_24_prod_media_kind) : obj.in_24_prod_media_kind,
                in_24_prod_media_eur: this.$fromCentToEur(obj.in_24_prod_media_eur),
              };
              arr.push(t_obj);
            }
            this.prod_media_lines = arr;
            break;
          case 'others':
            var arr = [];
            for(var i=0;i<objs.length;i++){
              var obj = objs[i];
              var t_obj = {
                id: obj.id,
                in_25_prod_others_type: !this.is_test ? this.$getEnumValue(this.prod_others_types, obj.in_25_prod_others_type) : obj.in_25_prod_others_type,
                in_25_prod_others_desc: obj.in_25_prod_others_desc,
                in_25_prod_others_kind: !this.is_test ? this.$getEnumValue(this.item_general_kinds, obj.in_25_prod_others_kind) : obj.in_25_prod_others_kind,
                in_25_prod_others_eur: this.$fromCentToEur(obj.in_25_prod_others_eur),
              };
              arr.push(t_obj);
            }
            this.prod_others_lines = arr;
            break;
          default:break;
        }
      },

      addProdLine(type){
        switch(type){
          case 'team_piva':
            this.prod_team_piva_lines.push(this.objProdTeamPivaLine());
            break;
          case 'fornit_piva':
            this.prod_fornit_piva_lines.push(this.objProdFornitPivaLine());
            break;
          case 'media':
            this.prod_media_lines.push(this.objProdMediaLine());
            break;
          case 'others':
            this.prod_others_lines.push(this.objProdOthersLine());
            break;
          default:break;
        }
      },

      removeProdLine(type, index){
        switch(type){
          case 'team_piva':
            this.prod_team_piva_lines.splice(index, 1);
            break;
          case 'fornit_piva':
            this.prod_fornit_piva_lines.splice(index, 1);
            break;
          case 'media':
            this.prod_media_lines.splice(index, 1);
            break;
          case 'others':
            this.prod_others_lines.splice(index, 1);
            break;
          default:break;
        }
      },

      saveCep(){
        this.btn_class = 'loading';
        var params = {
          cep: {
            // Comp section
              comp_section: 'ce',
            // In
              in_01_new_sell_ext_who_partner_id: this.in_01_new_sell_ext_who_partner_id,
              in_01_new_sell_ext_kind: this.in_01_new_sell_ext_kind,
              in_01_new_sell_ext_perc: this.in_01_new_sell_ext_perc,
              in_01_new_sell_oth_who_id: this.in_01_new_sell_oth_who_id,
              in_01_new_sell_oth_kind: this.in_01_new_sell_oth_kind,
              in_01_new_sell_oth_perc: this.in_01_new_sell_oth_perc,
              in_01_new_sell_int_who_pl_id: this.in_01_new_sell_int_who_pl_id,
              in_01_new_sell_int_kind: this.in_01_new_sell_int_kind,
              in_01_new_sell_int_perc: this.in_01_new_sell_int_perc,
              in_01_upsell_int_who_pl_id: this.in_01_upsell_int_who_pl_id,
              in_01_upsell_int_kind: this.in_01_upsell_int_kind,
              in_01_upsell_int_perc: this.in_01_upsell_int_perc,
              in_02_extra_margine_partner_who_partner_id: this.in_02_extra_margine_partner_who_partner_id,
              in_02_extra_margine_partner_kind: this.in_02_extra_margine_partner_kind,
              in_02_extra_margine_partner_eur: this.$fromEurToCent(this.in_02_extra_margine_partner_eur),
              in_03_extra_margine_bonus_who_user_id: this.in_03_extra_margine_bonus_who_user_id,
              in_03_extra_margine_bonus_kind: this.in_03_extra_margine_bonus_kind,
              in_11_direzione_team_fisso_kind: this.in_11_direzione_team_fisso_kind,
              in_11_direzione_team_fisso_input_perc: this.in_11_direzione_team_fisso_input_perc,
              in_11_direzione_team_piva_kind: this.in_11_direzione_team_piva_kind,
              in_11_direzione_team_piva_input_perc: this.in_11_direzione_team_piva_input_perc,
              in_11_direzione_fornit_kind: this.in_11_direzione_fornit_kind,
              in_11_direzione_fornit_input_perc: this.in_11_direzione_fornit_input_perc,
              in_11_direzione_consul_kind: this.in_11_direzione_consul_kind,
              in_11_direzione_consul_input_eur: this.$fromEurToCent(this.in_11_direzione_consul_input_eur),
              in_11_direzione_consul_input_h_qty: this.in_11_direzione_consul_input_h_qty,
              in_12_gest_alloc_fisso_piva_kind: this.in_12_gest_alloc_fisso_piva_kind,
              in_12_gest_alloc_fisso_piva_input_perc: this.in_12_gest_alloc_fisso_piva_input_perc,
              in_12_gest_superv_fornit_kind: this.in_12_gest_superv_fornit_kind,
              in_12_gest_superv_fornit_input_perc: this.in_12_gest_superv_fornit_input_perc,
              in_12_gest_superv_event_kind: this.in_12_gest_superv_event_kind,
              in_12_gest_superv_event_eur: this.$fromEurToCent(this.in_12_gest_superv_event_eur),
              in_12_gest_comp_plus_kind: this.in_12_gest_comp_plus_kind,
              in_3_struct_dir_az_kind: this.in_3_struct_dir_az_kind,
              in_3_struct_dir_az_input_perc: this.in_3_struct_dir_az_input_perc,
              in_3_struct_sede_kind: this.in_3_struct_sede_kind,
              in_3_struct_sede_input_perc: this.in_3_struct_sede_input_perc,
              in_3_struct_rappr_kind: this.in_3_struct_rappr_kind,
              in_3_struct_rappr_input_perc: this.in_3_struct_rappr_input_perc,
              in_3_struct_ant_fin_kind: this.in_3_struct_ant_fin_kind,
              in_3_struct_ant_fin_input_perc: this.in_3_struct_ant_fin_input_perc,
              in_3_struct_comp_plus_kind: this.in_3_struct_comp_plus_kind,
              in_4_brand_coo_sales_kind: this.in_4_brand_coo_sales_kind,
              in_4_brand_coo_sales_input_perc: this.in_4_brand_coo_sales_input_perc,
              in_4_brand_coo_com_kind: this.in_4_brand_coo_com_kind,
              in_4_brand_coo_com_input_perc: this.in_4_brand_coo_com_input_perc,
              in_4_brand_comp_plus_kind: this.in_4_brand_comp_plus_kind,
              in_mar_extra_margine_eur: this.$fromEurToCent(this.in_mar_extra_margine_eur),
              in_mar_bonus_plus_kind: this.in_mar_bonus_plus_kind,
              in_mar_bonus_plus_perc: this.in_mar_bonus_plus_perc,
              in_mar_acc_gest_kind: this.in_mar_acc_gest_kind,
              in_mar_acc_gest_perc: this.in_mar_acc_gest_perc,
              in_mar_acc_struct_kind: this.in_mar_acc_struct_kind,
              in_mar_acc_struct_perc: this.in_mar_acc_struct_perc,
              in_mar_acc_brand_kind: this.in_mar_acc_brand_kind,
              in_mar_acc_brand_perc: this.in_mar_acc_brand_perc,
              in_51_mar_team_fisso_kind: this.in_51_mar_team_fisso_kind,
              in_51_mar_fisso_input_perc: this.in_51_mar_fisso_input_perc,
              in_51_mar_team_piva_kind: this.in_51_mar_team_piva_kind,
              in_51_mar_team_piva_input_perc: this.in_51_mar_team_piva_input_perc,
              in_51_mar_fornit_kind: this.in_51_mar_fornit_kind,
              in_51_mar_fornit_input_perc: this.in_51_mar_fornit_input_perc,
              in_53_mar_fee_comm_kind: this.in_53_mar_fee_comm_kind,
              is_without_fee_comm: this.is_without_fee_comm,
            // Calcd
              calcd_01_tot_eur: this.calcd_01_tot_eur,
              calcd_01_tot_perc: this.calcd_01_tot_perc,
              calcd_01_prezzo_senza: this.calcd_01_prezzo_senza,
              calcd_01_fee_comm: this.calcd_01_fee_comm,
              calcd_01_new_sell_tot_perc: this.calcd_01_new_sell_tot_perc,
              calcd_01_new_sell_ext_eur: this.calcd_01_new_sell_ext_eur,
              calcd_01_new_sell_oth_eur: this.calcd_01_new_sell_oth_eur,
              calcd_01_new_sell_int_eur: this.calcd_01_new_sell_int_eur,
              calcd_01_upsell_int_eur: this.calcd_01_upsell_int_eur,
              calcd_02_extra_margine_tot_eur: this.calcd_02_extra_margine_tot_eur,
              calcd_02_extra_margine_tot_perc: this.calcd_02_extra_margine_tot_perc,
              calcd_03_extra_margine_bonus_tot_eur: this.calcd_03_extra_margine_bonus_tot_eur,
              calcd_03_extra_margine_bonus_tot_perc: this.calcd_03_extra_margine_bonus_tot_perc,
              calcd_03_extra_margine_bonus_eur: this.calcd_03_extra_margine_bonus_eur,
              calcd_11_direzione_who_user_id: this.calcd_11_direzione_who_user_id,
              calcd_11_direzione_tot_h: this.calcd_11_direzione_tot_h,
              calcd_11_direzione_tot_eur: this.calcd_11_direzione_tot_eur,
              calcd_11_direzione_tot_perc: this.calcd_11_direzione_tot_perc,
              calcd_11_direzione_team_fisso_eur: this.calcd_11_direzione_team_fisso_eur,
              calcd_11_direzione_team_fisso_perc: this.calcd_11_direzione_team_fisso_perc,
              calcd_11_direzione_team_piva_eur: this.calcd_11_direzione_team_piva_eur,
              calcd_11_direzione_team_piva_perc: this.calcd_11_direzione_team_piva_perc,
              calcd_11_direzione_fornit_eur: this.calcd_11_direzione_fornit_eur,
              calcd_11_direzione_fornit_perc: this.calcd_11_direzione_fornit_perc,
              calcd_11_direzione_consul_eur: this.calcd_11_direzione_consul_eur,
              calcd_11_direzione_consul_perc: this.calcd_11_direzione_consul_perc,
              calcd_21_prod_team_stip_tot_h: this.calcd_21_prod_team_stip_tot_h,
              calcd_21_prod_team_stip_tot_eur: this.calcd_21_prod_team_stip_tot_eur,
              calcd_21_prod_team_stip_tot_perc: this.calcd_21_prod_team_stip_tot_perc,
              calcd_21_prod_team_stip_eur: this.calcd_21_prod_team_stip_eur,
              calcd_21_prod_team_stip_perc: this.calcd_21_prod_team_stip_perc,
              calcd_22_prod_team_piva_tot_h: this.calcd_22_prod_team_piva_tot_h,
              calcd_22_prod_team_piva_tot_eur: this.calcd_22_prod_team_piva_tot_eur,
              calcd_22_prod_team_piva_tot_perc: this.calcd_22_prod_team_piva_tot_perc,
              calcd_22_prod_team_piva_eur: this.calcd_22_prod_team_piva_eur,
              calcd_22_prod_team_piva_perc: this.calcd_22_prod_team_piva_perc,
              calcd_23_prod_fornit_piva_tot_eur: this.calcd_23_prod_fornit_piva_tot_eur,
              calcd_23_prod_fornit_piva_tot_perc: this.calcd_23_prod_fornit_piva_tot_perc,
              calcd_23_prod_fornit_piva_perc: this.calcd_23_prod_fornit_piva_perc,
              calcd_24_prod_media_tot_eur: this.calcd_24_prod_media_tot_eur,
              calcd_24_prod_media_tot_perc: this.calcd_24_prod_media_tot_perc,
              calcd_24_prod_media_perc: this.calcd_24_prod_media_perc,
              calcd_25_prod_others_tot_eur: this.calcd_25_prod_others_tot_eur,
              calcd_25_prod_others_tot_perc: this.calcd_25_prod_others_tot_perc,
              calcd_25_prod_others_perc: this.calcd_25_prod_others_perc,
              calcd_12_gest_tot_eur: this.calcd_12_gest_tot_eur,
              calcd_12_gest_tot_perc: this.calcd_12_gest_tot_perc,
              calcd_12_gest_alloc_fisso_piva_eur: this.calcd_12_gest_alloc_fisso_piva_eur,
              calcd_12_gest_alloc_fisso_piva_perc: this.calcd_12_gest_alloc_fisso_piva_perc,
              calcd_12_gest_superv_fornit_eur: this.calcd_12_gest_superv_fornit_eur,
              calcd_12_gest_superv_fornit_perc: this.calcd_12_gest_superv_fornit_perc,
              calcd_12_gest_superv_event_perc: this.calcd_12_gest_superv_event_perc,
              calcd_12_gest_comp_plus_eur: this.calcd_12_gest_comp_plus_eur,
              calcd_12_gest_comp_plus_perc: this.calcd_12_gest_comp_plus_perc,
              calcd_3_struct_tot_eur: this.calcd_3_struct_tot_eur,
              calcd_3_struct_tot_perc: this.calcd_3_struct_tot_perc,
              calcd_3_struct_dir_az_eur: this.calcd_3_struct_dir_az_eur,
              calcd_3_struct_dir_az_perc: this.calcd_3_struct_dir_az_perc,
              calcd_3_struct_sede_eur: this.calcd_3_struct_sede_eur,
              calcd_3_struct_sede_perc: this.calcd_3_struct_sede_perc,
              calcd_3_struct_rappr_eur: this.calcd_3_struct_rappr_eur,
              calcd_3_struct_rappr_perc: this.calcd_3_struct_rappr_perc,
              calcd_3_struct_ant_fin_eur: this.calcd_3_struct_ant_fin_eur,
              calcd_3_struct_ant_fin_perc: this.calcd_3_struct_ant_fin_perc,
              calcd_3_struct_comp_plus_eur: this.calcd_3_struct_comp_plus_eur,
              calcd_3_struct_comp_plus_perc: this.calcd_3_struct_comp_plus_perc,
              calcd_4_brand_tot_eur: this.calcd_4_brand_tot_eur,
              calcd_4_brand_tot_perc: this.calcd_4_brand_tot_perc,
              calcd_4_brand_coo_sales_eur: this.calcd_4_brand_coo_sales_eur,
              calcd_4_brand_coo_sales_perc: this.calcd_4_brand_coo_sales_perc,
              calcd_4_brand_coo_com_eur: this.calcd_4_brand_coo_com_eur,
              calcd_4_brand_coo_com_perc: this.calcd_4_brand_coo_com_perc,
              calcd_4_brand_comp_plus_eur: this.calcd_4_brand_comp_plus_eur,
              calcd_4_brand_comp_plus_perc: this.calcd_4_brand_comp_plus_perc,
              calcd_mar_bonus_plus_eur: this.calcd_mar_bonus_plus_eur,
              calcd_mar_acc_gest_eur: this.calcd_mar_acc_gest_eur,
              calcd_mar_acc_struct_eur: this.calcd_mar_acc_struct_eur,
              calcd_mar_acc_brand_eur: this.calcd_mar_acc_brand_eur,
              calcd_51_mar_tot_eur: this.calcd_51_mar_tot_eur,
              calcd_51_mar_tot_perc: this.calcd_51_mar_tot_perc,
              calcd_51_mar_team_fisso_eur: this.calcd_51_mar_team_fisso_eur,
              calcd_51_mar_team_fisso_perc: this.calcd_51_mar_team_fisso_perc,
              calcd_51_mar_team_piva_eur: this.calcd_51_mar_team_piva_eur,
              calcd_51_mar_team_piva_perc: this.calcd_51_mar_team_piva_perc,
              calcd_51_mar_fornit_eur: this.calcd_51_mar_fornit_eur,
              calcd_51_mar_fornit_perc: this.calcd_51_mar_fornit_perc,
              calcd_53_mar_fee_tot_eur: this.calcd_53_mar_fee_tot_eur,
              calcd_53_mar_fee_tot_perc: this.calcd_53_mar_fee_tot_perc,
              calcd_53_mar_fee_comm_eur: this.calcd_53_mar_fee_comm_eur,
              calcd_53_mar_fee_comm_perc: this.calcd_53_mar_fee_comm_perc,
              calcd_prezzo_vendita_tot_eur: this.calcd_prezzo_vendita_tot_eur,
              calcd_csp_fissi_tot_perc: this.calcd_csp_fissi_tot_perc,
              calcd_csp_fissi_prod_int_tot_h: this.calcd_csp_fissi_prod_int_tot_h,
              calcd_csp_fissi_prod_int_tot_eur: this.calcd_csp_fissi_prod_int_tot_eur,
              calcd_csp_fissi_prod_int_tot_perc: this.calcd_csp_fissi_prod_int_tot_perc,
              calcd_csp_fissi_mol_tot_eur: this.calcd_csp_fissi_mol_tot_eur,
              calcd_csp_fissi_mol_tot_perc: this.calcd_csp_fissi_mol_tot_perc,
              calcd_csp_var_tot_perc: this.calcd_csp_var_tot_perc,
              calcd_csp_var_segnal_tot_eur: this.calcd_csp_var_segnal_tot_eur,
              calcd_csp_var_segnal_tot_perc: this.calcd_csp_var_segnal_tot_perc,
              calcd_csp_var_ea_tot_eur: this.calcd_csp_var_ea_tot_eur,
              calcd_csp_var_ea_tot_perc: this.calcd_csp_var_ea_tot_perc,
              calcd_csp_var_prod_piva_tot_h: this.calcd_csp_var_prod_piva_tot_h,
              calcd_csp_var_prod_int_piva_tot_eur: this.calcd_csp_var_prod_int_piva_tot_eur,
              calcd_csp_var_prod_int_piva_tot_perc: this.calcd_csp_var_prod_int_piva_tot_perc,
              calcd_csp_var_prod_est_piva_tot_eur: this.calcd_csp_var_prod_est_piva_tot_eur,
              calcd_csp_var_prod_est_piva_tot_perc: this.calcd_csp_var_prod_est_piva_tot_perc,
              calcd_csp_var_prod_other_est_eur: this.calcd_csp_var_prod_other_est_eur,
              calcd_csp_var_prod_other_est_perc: this.calcd_csp_var_prod_other_est_perc,
              calcd_cvb_comm_tot_eur: this.calcd_cvb_comm_tot_eur,
              calcd_cvb_comm_tot_perc: this.calcd_cvb_comm_tot_perc,
              calcd_cvb_dir_tot_eur: this.calcd_cvb_dir_tot_eur,
              calcd_cvb_dir_tot_perc: this.calcd_cvb_dir_tot_perc,
              calcd_cvb_prod_tot_eur: this.calcd_cvb_prod_tot_eur,
              calcd_cvb_prod_tot_perc: this.calcd_cvb_prod_tot_perc,
              calcd_cvb_mol_tot_eur: this.calcd_cvb_mol_tot_eur,
              calcd_cvb_mol_tot_perc: this.calcd_cvb_mol_tot_perc,
            // Lines
              prod_team_stip_lines: this.prod_team_stip_lines.map((obj, index) => {
                return {
                  id: obj.id,
                  in_21_prod_team_stip_act_type: obj.in_21_prod_team_stip_act_type,
                  in_21_prod_team_stip_who_str: obj.in_21_prod_team_stip_who_str,
                  in_21_prod_team_stip_kind: obj.in_21_prod_team_stip_kind,
                  in_21_prod_team_stip_h_cost_eur: this.$fromEurToCent(obj.in_21_prod_team_stip_h_cost_eur),
                  in_21_prod_team_stip_h_qty: obj.in_21_prod_team_stip_h_qty,
                  calcd_21_prod_team_stip_eur: this.calcd_21_prod_team_stip_eur[index],
                  calcd_21_prod_team_stip_perc: this.calcd_21_prod_team_stip_perc[index],
                };
              }).filter((obj) => { return obj.in_21_prod_team_stip_act_type!==null && obj.in_21_prod_team_stip_h_cost_eur!==null && obj.in_21_prod_team_stip_h_qty!==null; }),
              prod_team_piva_lines: this.prod_team_piva_lines.map((obj, index) => {
                return {
                  id: obj.id,
                  in_22_prod_team_piva_act_type: obj.in_22_prod_team_piva_act_type,
                  in_22_prod_team_piva_who_user_id: obj.in_22_prod_team_piva_who_user_id,
                  in_22_prod_team_piva_kind: obj.in_22_prod_team_piva_kind,
                  in_22_prod_team_piva_h_cost_eur: this.$fromEurToCent(obj.in_22_prod_team_piva_h_cost_eur),
                  in_22_prod_team_piva_h_qty: obj.in_22_prod_team_piva_h_qty,
                  calcd_22_prod_team_piva_eur: this.calcd_22_prod_team_piva_eur[index],
                  calcd_22_prod_team_piva_perc: this.calcd_22_prod_team_piva_perc[index],
                };
              }).filter((obj) => { return obj.in_22_prod_team_piva_act_type!==null && obj.in_22_prod_team_piva_h_cost_eur!==null && obj.in_22_prod_team_piva_h_qty!==null; }),
              prod_fornit_piva_lines: this.prod_fornit_piva_lines.map((obj, index) => {
                return {
                  id: obj.id,
                  in_23_prod_fornit_piva_act_desc: obj.in_23_prod_fornit_piva_act_desc,
                  in_23_prod_fornit_piva_who_str: obj.in_23_prod_fornit_piva_who_str,
                  in_23_prod_fornit_piva_kind: obj.in_23_prod_fornit_piva_kind,
                  in_23_prod_fornit_piva_eur: this.$fromEurToCent(obj.in_23_prod_fornit_piva_eur),
                  calcd_23_prod_fornit_piva_perc: this.calcd_23_prod_fornit_piva_perc[index],
                };
              }).filter((obj) => { return obj.in_23_prod_fornit_piva_act_desc!==null && obj.in_23_prod_fornit_piva_eur!==null; }),
              prod_media_lines: this.prod_media_lines.map((obj, index) => {
                return {
                  id: obj.id,
                  in_24_prod_media_desc: obj.in_24_prod_media_desc,
                  in_24_prod_media_desc1: obj.in_24_prod_media_desc1,
                  in_24_prod_media_kind: obj.in_24_prod_media_kind,
                  in_24_prod_media_eur: this.$fromEurToCent(obj.in_24_prod_media_eur),
                  calcd_24_prod_media_perc: this.calcd_24_prod_media_perc[index],
                };
              }).filter((obj) => { return obj.in_24_prod_media_desc!==null && obj.in_24_prod_media_eur!==null; }),
              prod_others_lines: this.prod_others_lines.map((obj, index) => {
                return {
                  id: obj.id,
                  in_25_prod_others_type: obj.in_25_prod_others_type,
                  in_25_prod_others_desc: obj.in_25_prod_others_desc,
                  in_25_prod_others_kind: obj.in_25_prod_others_kind,
                  in_25_prod_others_eur: this.$fromEurToCent(obj.in_25_prod_others_eur),
                  calcd_25_prod_others_perc: this.calcd_25_prod_others_perc[index],
                };
              }).filter((obj) => { return obj.in_25_prod_others_type!==null && obj.in_25_prod_others_eur!==null; }),
          }
        };
        var inst = this;
        return new Promise(resolve => {
          inst.axios.put('/api/ceps/'+inst.cep.id, params).then(response => {
            var obj = response.data;
            if(obj.data.success){
              inst.btn_class = '';
              inst.success_banner_class = 'shown';
              setTimeout(() => inst.success_banner_class = '', 3000);
              resolve({data: {status: 'Ok', message: 'L\'operazione è stata eseguita con successo'}});
            }
            else{
              inst.btn_class = '';
              inst.error_banner_class = 'shown';
              setTimeout(() => inst.error_banner_class = '', 3000);
              resolve({data: {status: 'Error', message: 'Si è verificato un errore'}});
            }
          });
        });
      }
    }
  }
</script>
