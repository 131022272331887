<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6">
        <p class="section-ot">Risorse fisse <span class="edit-btn" @click="activateEditMode()"></span></p>
        <div class="bi-box-wrapper">
          <template v-for="(row, ri) in rows">
            <div class="heading" :class="colors[ri]">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <p>{{$niceCeaBiKind(row.kind)}} - {{row.name}}</p>
                </div>
                <div class="col-12 col-md-4">
                  <span class="value">{{$niceMoney(calcd_tot_cat_cost[ri])}}</span>
                </div>
              </div>
            </div>
            <template v-for="(res_row, i) in row.resources">
              <div class="value-row" :class="!edit_mode ? 'wbb' : ''">
                <div class="row align-items-center">
                  <div class="col-12 col-md-8">
                    <template v-if="edit_mode">
                      <CInput :id="'res_row_'+ri+'_'+i+'_name'" :name="'res_row_'+ri+'_'+i+'_name'" placeholder="..." :readonly="false" v-model="res_row.name"></CInput>
                    </template>
                    <template v-else>
                      <p>{{res_row.name}}</p>
                    </template>
                  </div>
                  <div class="col-12 col-md-4">
                    <template v-if="edit_mode">
                      <CInputMoney :id="'res_row_'+ri+'_'+i+'_cost'" :name="'res_row_'+ri+'_'+i+'_cost'" placeholder="0,00" :readonly="false" v-model.number="res_row.cost"></CInputMoney>
                    </template>
                    <template v-else>
                      <span class="value">{{$niceMoney($fromEurToCent(res_row.cost))}}</span>
                    </template>
                  </div>
                </div>
                <template v-if="edit_mode">
                  <span class="remove-row" @click="removeResource(ri, i)"></span>
                </template>
              </div>
            </template>
            <template v-if="edit_mode">
              <span class="add-row" @click="addResource(ri)"></span>
            </template>
            <div class="spacer"></div>
          </template>
          <template v-if="edit_mode">
            <span class="save-btn" :class="btn_class" @click="saveResources()">Salva le modifiche</span>
          </template>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <p class="section-ot">Altre entrate <span class="edit-btn" @click="activateAeEditMode()"></span></p>
        <div class="bi-box-wrapper">
          <div class="heading grey">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p>{{ae_rows[0].name}}</p>
              </div>
              <div class="col-12 col-md-4">
                <span class="value">{{$niceMoney(calcd_tot_ae_cost[0])}}</span>
              </div>
            </div>
          </div>
          <template v-for="(res_row, i) in ae_rows[0].resources">
            <div class="value-row" :class="!ae_edit_mode ? 'wbb' : ''">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <template v-if="ae_edit_mode">
                    <CInput :id="'ae_res_row_'+0+'_'+i+'_name'" :name="'ae_res_row_'+0+'_'+i+'_name'" placeholder="..." :readonly="false" v-model="res_row.name"></CInput>
                  </template>
                  <template v-else>
                    <p>{{res_row.name}}</p>
                  </template>
                </div>
                <div class="col-12 col-md-4">
                  <template v-if="ae_edit_mode">
                    <CInputMoney :id="'ae_res_row_'+0+'_'+i+'_cost'" :name="'ae_res_row_'+0+'_'+i+'_cost'" placeholder="0,00" :readonly="false" v-model.number="res_row.cost"></CInputMoney>
                  </template>
                  <template v-else>
                    <span class="value">{{$niceMoney($fromEurToCent(res_row.cost))}}</span>
                  </template>
                </div>
              </div>
              <template v-if="ae_edit_mode">
                <span class="remove-row" @click="removeAeResource(0, i)"></span>
              </template>
            </div>
          </template>
          <template v-if="ae_edit_mode">
            <span class="add-row" @click="addAeResource(0)"></span>
          </template>
          <template v-if="ae_edit_mode">
            <div class="spacer"></div>
            <span class="save-btn" :class="btn_class" @click="saveAeResources()">Salva le modifiche</span>
          </template>
        </div>
        <p class="section-ot">Sintesi bilancino</p>
        <div class="sbi-box-wrapper">
          <div class="rows-wrapper">
            <div class="row-wrapper green">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <p><span>a.</span> Costi di produzione interna</p>
                </div>
                <div class="col-12 col-md-4">
                  <span class="value">{{$niceMoney(calcd_tot_cat_cost[0])}}</span>
                </div>
              </div>
            </div>
            <div class="row-wrapper yellow">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <p><span>b.</span> Costi di struttura</p>
                </div>
                <div class="col-12 col-md-4">
                  <span class="value">{{$niceMoney(calcd_tot_cat_cost[1])}}</span>
                </div>
              </div>
            </div>
            <div class="row-wrapper blue">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <p><span>c.</span> Costi di amministrazione</p>
                </div>
                <div class="col-12 col-md-4">
                  <span class="value">{{$niceMoney(calcd_tot_cat_cost[2])}}</span>
                </div>
              </div>
            </div>
            <div class="row-wrapper purple">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <p><span>d.</span> Costi di Marcom</p>
                </div>
                <div class="col-12 col-md-4">
                  <span class="value">{{$niceMoney(calcd_tot_cat_cost[3])}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="spacer"></div>
          <div class="heading">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p>Uscite per costi fissi</p>
              </div>
              <div class="col-12 col-md-4">
                <span class="value">{{$niceMoney(calcd_fixed_costs)}}</span>
              </div>
            </div>
          </div>
          <div class="rows-wrapper">
            <div class="row-wrapper purple">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <p><span>e.</span> Costi di Segnalatori</p>
                </div>
                <div class="col-12 col-md-4">
                  <span class="value">{{$niceMoney(comp_co_values.calcd_det_cons_segn_eur)}}</span>
                </div>
              </div>
            </div>
            <div class="row-wrapper light-blue">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <p><span>f.</span> Executive Advisor</p>
                </div>
                <div class="col-12 col-md-4">
                  <span class="value">{{$niceMoney(comp_co_values.calcd_det_cons_ea_eur)}}</span>
                </div>
              </div>
            </div>
            <div class="row-wrapper green">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <p><span>g.</span> Produzione interna</p>
                </div>
                <div class="col-12 col-md-4">
                  <span class="value">{{$niceMoney(comp_co_values.calcd_det_cons_prod_int_piva_eur)}}</span>
                </div>
              </div>
            </div>
            <div class="row-wrapper green">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <p><span>h.</span> Produzione esterna</p>
                </div>
                <div class="col-12 col-md-4">
                  <span class="value">{{$niceMoney(comp_co_values.calcd_det_cons_prod_ext_piva_eur)}}</span>
                </div>
              </div>
            </div>
            <div class="row-wrapper green">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <p><span>i.</span> Produzione esterna (risorse)</p>
                </div>
                <div class="col-12 col-md-4">
                  <span class="value">{{$niceMoney(comp_co_values.calcd_det_cons_oth_prod_ext_eur)}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="spacer"></div>
          <div class="heading">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p>Uscite/entrate variabili</p>
              </div>
              <div class="col-12 col-md-4">
                <span class="value">{{$niceMoney(calcd_var_ext_ent)}}</span>
              </div>
            </div>
          </div>
          <div class="rows-wrapper">
            <div class="row-wrapper green">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <p><span>l.</span> Produzione interna</p>
                </div>
                <div class="col-12 col-md-4">
                  <span class="value">{{$niceMoney(comp_co_values.calcd_det_cons_prod_int_eur)}}</span>
                </div>
              </div>
            </div>
            <div class="row-wrapper orange">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <p><span>m.</span> MOL</p>
                </div>
                <div class="col-12 col-md-4">
                  <span class="value">{{$niceMoney(comp_co_values.calcd_det_cons_mol_eur)}}</span>
                </div>
              </div>
            </div>
            <div class="row-wrapper grey">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <p><span>n.</span> Altre entrate</p>
                </div>
                <div class="col-12 col-md-4">
                  <span class="value">{{$niceMoney(calcd_tot_ae_cost[0])}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="spacer"></div>
          <div class="heading">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p>Entrate per costi fissi</p>
              </div>
              <div class="col-12 col-md-4">
                <span class="value">{{$niceMoney(calcd_fixed_ent)}}</span>
              </div>
            </div>
          </div>
          <div class="rows-wrapper">
            <div class="row-wrapper red">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <p>Consuntivo</p>
                </div>
                <div class="col-12 col-md-4">
                  <span class="value">{{$niceMoney(calcd_cons_tot_eur)}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6">
        <div class="bi-c-wrapper">
          <p>Concentrazione costi fissi</p>
          <div class="i-bi-c-wrapper" ref="fix_chart"></div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="bi-c-wrapper">
          <p>Concentrazione costi variabili</p>
          <div class="i-bi-c-wrapper" ref="var_chart"></div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="bi-c-wrapper">
          <p>Concentrazione ricavi</p>
          <div class="i-bi-c-wrapper" ref="rev_chart"></div>
        </div>
      </div>
    </div>

    <div class="ctm-banner success" :class="success_banner_class">
      <p>Le modifiche sono state salvate con successo</p>
    </div>
    <div class="ctm-banner error" :class="error_banner_class">
      <p>Si è verificato un problema durante il salvataggio delle modifiche</p>
    </div>
  </div>
</template>

<script>
  import CInput from '../form/CInput.vue'
  import CInputMoney from '../form/CInputMoney.vue'

  import * as helperFunctions from "../../helpers/helpers"

  import * as am5 from '@amcharts/amcharts5'
  import * as am5percent from '@amcharts/amcharts5/percent'
  import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'

  export default {
    components:{
      CInput,
      CInputMoney,
    },

    props: {
      cea: Object,
      bi_values: Array,
      comp_co_values: Object,
    },

    data(){
      return {
        edit_mode: false,
        ae_edit_mode: false,

        colors: [
          'green',
          'yellow',
          'blue',
          'purple',
        ],

        rows: [],
        ae_rows: [],

        btn_class: '',
        success_banner_class: '',
        error_banner_class: '',

        fix_chart: null,
        var_chart: null,
        rev_chart: null,
      }
    },

    mounted(){
      this.$nextTick(() => {
        this.renderChart('fix_chart');
        this.renderChart('var_chart');
        this.renderChart('rev_chart');
      });
    },

    created(){
      this.rows = this.bi_values.filter((obj) => {
        return obj.name!='Altre entrate';
      }).map((obj) => {
        return {
          id: obj.id,
          name: obj.name,
          kind: obj.kind,
          resources: obj.resources.map((r_obj) => {
            return {
              id: r_obj.id,
              name: r_obj.name,
              cost: this.$fromCentToEur(r_obj.cost),
            }
          }),
        };
      });
      this.ae_rows = this.bi_values.filter((obj) => {
        return obj.name=='Altre entrate';
      }).map((obj) => {
        return {
          id: obj.id,
          name: obj.name,
          kind: obj.kind,
          resources: obj.resources.map((r_obj) => {
            return {
              id: r_obj.id,
              name: r_obj.name,
              cost: this.$fromCentToEur(r_obj.cost),
            }
          }),
        };
      });
    },

    computed: {
      calcd_tot_cat_cost: function(){
        return this.rows.map((obj) => {
          return this.$fromEurToCent(obj.resources.reduce((n, {cost}) => n+cost, 0));
        });
      },

      calcd_tot_ae_cost: function(){
        return this.ae_rows.map((obj) => {
          return this.$fromEurToCent(obj.resources.reduce((n, {cost}) => n+cost, 0));
        });
      },

      calcd_fixed_costs: function(){
        // Formula F10
        return this.calcd_tot_cat_cost.reduce((cost, n) => cost+n, 0)*(-1);
      },

      calcd_var_ext_ent: function(){
        // Formula F22
        return ((-1)*(this.comp_co_values.calcd_det_cons_segn_eur+this.comp_co_values.calcd_det_cons_ea_eur+this.comp_co_values.calcd_det_cons_prod_int_piva_eur+this.comp_co_values.calcd_det_cons_prod_ext_piva_eur+this.comp_co_values.calcd_det_cons_oth_prod_ext_eur));
      },

      calcd_fixed_ent: function(){
        // Formula F30
        return this.comp_co_values.calcd_det_cons_prod_int_eur+this.comp_co_values.calcd_det_cons_mol_eur+this.calcd_tot_ae_cost[0];
      },

      calcd_cons_tot_eur: function(){
        // Formula F32 -> =F10+F22+F30
        return this.calcd_fixed_costs+this.calcd_var_ext_ent+this.calcd_fixed_ent;
      },

      calcd_var_chart_data: function(){
        return [
          {
            category: 'Costi di Segnalatori',
            value: this.comp_co_values.calcd_det_cons_segn_eur,
          },
          {
            category: 'Executive Advisor',
            value: this.comp_co_values.calcd_det_cons_ea_eur,
          },
          {
            category: 'Produzione interna',
            value: this.comp_co_values.calcd_det_cons_prod_int_piva_eur,
          },
          {
            category: 'Produzione esterna',
            value: this.comp_co_values.calcd_det_cons_prod_ext_piva_eur,
          },
          {
            category: 'Produzione esterna (risorse)',
            value: this.comp_co_values.calcd_det_cons_oth_prod_ext_eur,
          },
        ];
      },

      calcd_rev_chart_data: function(){
        return [
          {
            category: 'Produzione interna',
            value: this.comp_co_values.calcd_det_cons_prod_int_eur,
          },
          {
            category: 'MOL',
            value: this.comp_co_values.calcd_det_cons_mol_eur,
          },
        ];
      },
    },

    methods: {
      ...helperFunctions,

      activateEditMode(){
        this.edit_mode = true;
      },

      deactivateEditMode(){
        this.edit_mode = false;
      },

      addResource(index){
        this.rows[index].resources.push({name: null, cost: 0});
      },

      removeResource(index, r_index){
        this.rows[index].resources.splice(r_index, 1);
      },

      saveResources(){
        this.btn_class = 'loading';
        var params = {
          cea: {
            // Comp section
              comp_section: 'bi',
            // Resources
              resources: this.rows.map((obj, index) => {
                return obj.resources.map((r_obj, r_index) => {
                  return {
                    id: r_obj.id ? r_obj.id : null,
                    cat_id: obj.id,
                    cea_id: this.cea.id,
                    name: r_obj.name,
                    cost: this.$fromEurToCent(r_obj.cost),
                  }
                });
              }).flat(1),
          }
        };
        var inst = this;
        return new Promise(resolve => {
          inst.axios.put('/api/ceas/'+inst.cea.id+'/update_bi_data', params).then(response => {
            var obj = response.data;
            if(obj.data.success){
              inst.btn_class = '';
              this.deactivateEditMode();
              this.$emit('updateValues');
              inst.success_banner_class = 'shown';
              setTimeout(() => inst.success_banner_class = '', 3000);
              resolve({data: {status: 'Ok', message: 'L\'operazione è stata eseguita con successo'}});
            }
            else{
              inst.btn_class = '';
              inst.error_banner_class = 'shown';
              setTimeout(() => inst.error_banner_class = '', 3000);
              resolve({data: {status: 'Error', message: 'Si è verificato un errore'}});
            }
          });
        });
      },

      activateAeEditMode(){
        this.ae_edit_mode = true;
      },

      deactivateAeEditMode(){
        this.ae_edit_mode = false;
      },

      addAeResource(index){
        this.ae_rows[index].resources.push({name: null, cost: 0});
      },

      removeAeResource(index, r_index){
        this.ae_rows[index].resources.splice(r_index, 1);
      },

      saveAeResources(){
        this.btn_class = 'loading';
        var params = {
          cea: {
            // Comp section
              comp_section: 'ae',
            // Resources
              resources: this.ae_rows.map((obj, index) => {
                return obj.resources.map((r_obj, r_index) => {
                  return {
                    id: r_obj.id ? r_obj.id : null,
                    cat_id: obj.id,
                    cea_id: this.cea.id,
                    name: r_obj.name,
                    cost: this.$fromEurToCent(r_obj.cost),
                  }
                });
              }).flat(1),
          }
        };
        var inst = this;
        return new Promise(resolve => {
          inst.axios.put('/api/ceas/'+inst.cea.id+'/update_bi_data', params).then(response => {
            var obj = response.data;
            if(obj.data.success){
              inst.btn_class = '';
              this.deactivateAeEditMode();
              inst.success_banner_class = 'shown';
              setTimeout(() => inst.success_banner_class = '', 3000);
              resolve({data: {status: 'Ok', message: 'L\'operazione è stata eseguita con successo'}});
            }
            else{
              inst.btn_class = '';
              inst.error_banner_class = 'shown';
              setTimeout(() => inst.error_banner_class = '', 3000);
              resolve({data: {status: 'Error', message: 'Si è verificato un errore'}});
            }
          });
        });
      },

      renderChart(type){
        switch(type){
          case 'fix_chart':
            if(this.fix_chart){
              this.fix_chart.dispose();
            }
            var fix_chart = am5.Root.new(this.$refs.fix_chart);
            fix_chart.setThemes([am5themes_Animated.new(fix_chart)]);

            var chart = fix_chart.container.children.push(
              am5percent.PieChart.new(fix_chart, {
                radius: am5.percent(50),
                innerRadius: am5.percent(60)
              })
            );

            // Define data
            var data = [
              {
                category: 'Costi di produzione interna',
                value: this.calcd_tot_cat_cost[0],
              },
              {
                category: 'Costi di struttura',
                value: this.calcd_tot_cat_cost[1],
              },
              {
                category: 'Costi di amministrazione',
                value: this.calcd_tot_cat_cost[2],
              },
              {
                category: 'Costi di Marcom',
                value: this.calcd_tot_cat_cost[3],
              },
            ];

            var series = chart.series.push(
              am5percent.PieSeries.new(fix_chart, {
                name: 'Series',
                categoryField: 'category',
                valueField: 'value',
              })
            );

            series.get('colors').set('colors', [
              am5.color(0x42C38C),
              am5.color(0xE8B62E),
              am5.color(0x2A47C1),
              am5.color(0x9B49ED)
            ]);

            series.data.setAll(data);

            series.labels.template.setAll({
              maxWidth: 170,
              textAlign: 'left',
              oversizedBehavior: 'wrap',
            });

            this.fix_chart = fix_chart;
            break;
          case 'var_chart':
            if(this.var_chart){
              this.var_chart.dispose();
            }
            var var_chart = am5.Root.new(this.$refs.var_chart);
            var_chart.setThemes([am5themes_Animated.new(var_chart)]);

            var chart = var_chart.container.children.push(
              am5percent.PieChart.new(var_chart, {
                radius: am5.percent(50),
                innerRadius: am5.percent(60)
              })
            );

            // Define data
            var data = this.calcd_var_chart_data;

            var series = chart.series.push(
              am5percent.PieSeries.new(var_chart, {
                name: 'Series',
                categoryField: 'category',
                valueField: 'value',
              })
            );

            series.get('colors').set('colors', [
              am5.color(0x9B49ED),
              am5.color(0x88A9F2),
              am5.color(0x42C38C),
              am5.color(0x64CEA0),
              am5.color(0x83D8B3)
            ]);

            series.data.setAll(data);

            series.labels.template.setAll({
              maxWidth: 170,
              textAlign: 'left',
              oversizedBehavior: 'wrap',
            });

            this.var_chart = var_chart;
            break;
          case 'rev_chart':
            if(this.rev_chart){
              this.rev_chart.dispose();
            }
            var rev_chart = am5.Root.new(this.$refs.rev_chart);
            rev_chart.setThemes([am5themes_Animated.new(rev_chart)]);

            var chart = rev_chart.container.children.push(
              am5percent.PieChart.new(rev_chart, {
                radius: am5.percent(50),
                innerRadius: am5.percent(60)
              })
            );

            // Define data
            var data = this.calcd_rev_chart_data;

            var series = chart.series.push(
              am5percent.PieSeries.new(rev_chart, {
                name: 'Series',
                categoryField: 'category',
                valueField: 'value',
              })
            );

            series.get('colors').set('colors', [
              am5.color(0x42C38C),
              am5.color(0xFF8B03)
            ]);

            series.data.setAll(data);

            series.labels.template.setAll({
              maxWidth: 170,
              textAlign: 'left',
              oversizedBehavior: 'wrap',
            });

            this.rev_chart = rev_chart;
            break;
          default:break;
        }
      },
    }
  }
</script>