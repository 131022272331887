<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-8">
        <p class="section-ot">Oggetto del preventivo</p>
        <div class="form-box-wrapper">
          <div class="row">
            <div class="col-12">
              <div class="value-row">
                <div class="row">
                  <div class="col-12 col-md-4">
                    <label for="title">Titolo del preventivo</label>
                    <span class="visual-value">{{$niceField(cep_data.title)}}</span>
                  </div>
                  <div class="col-12 col-md-8">
                    <label for="description">Descrizione del preventivo</label>
                    <span class="visual-value">{{$niceField(cep_data.description)}}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-3">
                    <label for="quoted_at">Data preventivo</label>
                    <span class="visual-value">{{$niceDate(cep_data.quoted_at)}}</span>
                  </div>
                  <div class="col-12 col-md-3">
                    <label for="quote_number">Numero preventivo</label>
                    <span class="visual-value">{{$niceField(cep_data.quote_number)}}</span>
                  </div>
                  <div class="col-12 col-md-3">
                    <label for="quote_expires_at">Preventivo valido fino al</label>
                    <span class="visual-value">{{$niceDate(cep_data.quote_expires_at)}}</span>
                  </div>
                  <div class="col-12 col-md-3">
                    <label for="quote_accepted_at">Preventivo accettato il</label>
                    <span class="visual-value">{{$niceDate(cep_data.quote_accepted_at)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <p class="section-ot">Informazioni di progetto</p>
        <div class="form-box-wrapper">
          <div class="row">
            <div class="col-12">
              <div class="value-row">
                <div class="row">
                  <div class="col-12">
                    <label for="main_kind">Offerta prevalente</label>
                    <span class="visual-value">{{$niceObjField($findObjInArray(main_kinds, 'value', cep_data.main_kind), 'localized_label')}}</span>
                  </div>
                  <div class="col-12">
                    <label for="market_id">Mercato</label>
                    <span class="visual-value">{{$niceObjField($findObjInArray(markets, 'id', cep_data.market_id), 'name')}}</span>
                  </div>
                  <div class="col-12">
                    <label for="grade">Grado</label>
                    <span class="visual-value">{{$niceObjField($findObjInArray(grades, 'value', cep_data.grade), 'localized_label')}}</span>
                  </div>
                  <div class="col-12">
                    <label for="grade">Segnalatore</label>
                    <span class="visual-value">{{$niceObjField($findObjInArray(signalmen, 'id', cep_data.signalman_id), 'name')}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-8">
        <p class="section-ot">Dettaglio dei pagamenti</p>
        <div class="form-box-wrapper purple">
          <div class="row">
            <div class="col-12">
              <template v-for="(pagam_line, index) in pagam_lines">
                <div class="heading" v-if="index==0">
                  <div class="row align-items-center">
                    <div class="col-12 col-md-8">
                      <div class="repeatable-wrapper">
                        <div class="row">
                          <div class="col-12 col-md-4">
                            <label for="in_prev_detpag_impon_eur">Imponibile</label>
                          </div>
                          <div class="col-12 col-md-4">
                            <label for="in_prev_detpag_emiss_date">Emissione</label>
                          </div>
                          <div class="col-12 col-md-4">
                            <label for="in_prev_detpag_kind">Termini</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <div class="row">
                        <div class="col-12">
                          <span class="value wmt">Incasso</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="value-row">
                  <div class="row align-items-center">
                    <div class="col-12 col-md-8">
                      <div class="repeatable-wrapper">
                        <div class="row">
                          <div class="col-12 col-md-4">
                            <span class="visual-value">{{$niceMoney(pagam_line.in_prev_detpag_impon_eur)}}</span>
                          </div>
                          <div class="col-12 col-md-4">
                            <span class="visual-value">{{$niceDate(pagam_line.in_prev_detpag_emiss_date)}}</span>
                          </div>
                          <div class="col-12 col-md-4">
                            <span class="visual-value">{{pagam_line.in_prev_detpag_kind}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <div class="row">
                        <div class="col-12">
                          <span class="value wmt">{{$niceDate(calcd_prev_detpag_incasso_prev[index])}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <p class="section-ot">Scadenze di progetto</p>
        <div class="form-box-wrapper">
          <div class="row">
            <div class="col-12">
              <div class="value-row">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <label for="starts_at">Data inizio attività</label>
                    <span class="visual-value">{{$niceDate(cep_data.starts_at)}}</span>
                  </div>
                  <div class="col-12 col-md-6">
                    <label for="finishes_at">Data fine attività</label>
                    <span class="visual-value">{{$niceDate(cep_data.finishes_at)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p class="section-ot">Budget media</p>
        <div class="form-box-wrapper">
          <div class="row">
            <div class="col-12">
              <div class="value-row">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <label for="budget_media_kind">Tipologia</label>
                    <span class="visual-value">{{$niceObjField($findObjInArray(budget_media_kinds, 'value', cep_data.budget_media_kind), 'localized_label')}}</span>
                  </div>
                  <div class="col-12 col-md-6">
                    <label for="budget_media_tot_eur">Importo</label>
                    <span class="visual-value">{{$niceMoney(cep_data.budget_media_tot_eur)}}</span>
                  </div>
                  <div class="col-12">
                    <template v-if="cep_data.budget_media_kind==0">
                      <p class="hint"><strong>NON</strong> è previsto budget media</p>
                    </template>
                    <template v-if="cep_data.budget_media_kind==1">
                      <p class="hint">Budget media speso direttamente da account/carte del cliente</p>
                    </template>
                    <template v-if="cep_data.budget_media_kind==2">
                      <p class="hint">Budget media versato dal cliente <strong>PRIMA</strong> di essere gestito</p>
                    </template>
                    <template v-if="cep_data.budget_media_kind==3">
                      <p class="hint">Budget media <strong>ANTICIPATO</strong> e gestito da Twig</p>
                    </template>
                  </div>
                  <div class="col-12">
                    <p class="hint">Budget media nel conto economico: <strong>{{$niceMoney(cep.calcd_24_prod_media_tot_eur)}}</strong></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <p class="section-ot">Output del preventivo</p>
        <div class="form-box-wrapper purple">
          <div class="row">
            <div class="col-12">
              <template v-for="(output_line, index) in output_lines">
                <div class="heading" v-if="index==0">
                  <div class="row align-items-center">
                    <div class="col-12 col-md-8">
                      <div class="repeatable-wrapper">
                        <div class="row">
                          <div class="col-12 col-md-4">
                            <label for="in_prev_output_name">Nome dell'output</label>
                          </div>
                          <div class="col-12 col-md-2">
                            <label for="in_prev_output_kind">Tipo offerta</label>
                          </div>
                          <div class="col-12 col-md-2">
                            <label for="in_prev_output_impon_eur">Imponibile</label>
                          </div>
                          <div class="col-12 col-md-2">
                            <label for="in_prev_output_h_qty">Ore</label>
                          </div>
                          <div class="col-12 col-md-2">
                            <label for="in_prev_output_deliver_date">Consegna</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <div class="row">
                        <div class="col-12 col-md-6">
                          <span class="value wmt">% Offerta</span>
                        </div>
                        <div class="col-12 col-md-6">
                          <span class="value wmt">Tariffa giornaliera</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="value-row">
                  <div class="row">
                    <div class="col-12 col-md-8">
                      <div class="repeatable-wrapper">
                        <div class="row">
                          <div class="col-12 col-md-4">
                            <span class="visual-value">{{$niceField(output_line.in_prev_output_name)}}</span>
                          </div>
                          <div class="col-12 col-md-2">
                            <span class="visual-value">{{$niceObjField($findObjInArray(main_kinds, 'value', output_line.in_prev_output_kind), 'localized_label')}}</span>
                          </div>
                          <div class="col-12 col-md-2">
                            <span class="visual-value">{{$niceMoney(output_line.in_prev_output_impon_eur)}}</span>
                          </div>
                          <div class="col-12 col-md-2">
                            <span class="visual-value">{{$niceNumber(output_line.in_prev_output_h_qty)}} h</span>
                          </div>
                          <div class="col-12 col-md-2">
                            <span class="visual-value">{{$niceDate(output_line.in_prev_output_deliver_date)}}</span>
                          </div>
                          <div class="col-12">
                            <span class="visual-value">{{$niceField(output_line.in_prev_output_desc)}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <div class="row">
                        <div class="col-12 col-md-6">
                          <span class="value wmt">{{$niceIntPerc(calcd_prev_output_offerta_perc[index])}}</span>
                        </div>
                        <div class="col-12 col-md-6">
                          <span class="value wmt">{{$niceMoney(calcd_prev_output_tariffa_eur[index])}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as helperFunctions from "../../helpers/helpers"

  export default {
    props: {
      cep: Object,
      cep_lines: Object,
      markets: Array,
      signalmen: Array,
      grades: Array,
      main_kinds: Array,
      budget_media_kinds: Array,
    },

    data(){
      return {
        cep_data: {
          title: this.cep.title,
          description: this.cep.description,
          main_kind: this.$getEnumValue(this.main_kinds, this.cep.main_kind),
          market_id: this.cep.market_id,
          grade: this.$getEnumValue(this.grades, this.cep.grade),
          signalman_id: this.cep.signalman_id,
          starts_at: this.cep.starts_at,
          finishes_at: this.cep.finishes_at,
          quoted_at: this.cep.quoted_at,
          quote_number: this.cep.quote_number,
          quote_expires_at: this.cep.quote_expires_at,
          quote_accepted_at: this.cep.quote_accepted_at,
          budget_media_kind: this.$getEnumValue(this.budget_media_kinds, this.cep.budget_media_kind),
          budget_media_tot_eur: this.cep.budget_media_tot_eur,
        },

        pagam_lines: [],
        output_lines: [],
      }
    },

    created(){
      if((!this.pagam_lines || this.pagam_lines.length==0) && this.cep_lines && this.cep_lines.pagam_lines && this.cep_lines.pagam_lines.length>0){
        this.initLines(this.cep_lines.pagam_lines, 'pagam');
      }
      else{
        this.pagam_lines = [this.objPagamLine()];
      }
      if((!this.output_lines || this.output_lines.length==0) && this.cep_lines && this.cep_lines.output_lines && this.cep_lines.output_lines.length>0){
        this.initLines(this.cep_lines.output_lines, 'output');
      }
      else{
        this.output_lines = [this.objOutputLine()];
      }
    },

    computed: {
      calcd_prev_detpag_incasso_prev: function(){
        return this.pagam_lines.map((obj) => {
          var v = null;
          if(obj.in_prev_detpag_emiss_date!==null && obj.in_prev_detpag_kind!==null){
            var date = new Date(obj.in_prev_detpag_emiss_date);
            date.setDate(date.getDate() + obj.in_prev_detpag_kind);
            v = this.$niceFromattedDate(date);
          }
          return v;
        });
      },

      calcd_prev_output_impon_tot_eur: function(){
        var v = this.output_lines.filter((obj) => { return obj.in_prev_output_name!==null && obj.in_prev_output_impon_eur!==null; }).reduce((n, {in_prev_output_impon_eur}) => n+in_prev_output_impon_eur, 0);
          return v;
      },

      calcd_prev_output_tot_h: function(){
        var v = this.output_lines.filter((obj) => { return obj.in_prev_output_name!==null && obj.in_prev_output_impon_eur!==null; }).reduce((n, {in_prev_output_h_qty}) => n+in_prev_output_h_qty, 0);
          return v;
      },

      calcd_prev_output_offerta_perc: function(){
        return this.output_lines.map((obj) => {
          var v = 0;
          if(obj.in_prev_output_name!==null && obj.in_prev_output_impon_eur!==null){
            if(this.calcd_prev_output_impon_tot_eur){
              v = this.$calculatePerc(obj.in_prev_output_impon_eur/this.calcd_prev_output_impon_tot_eur);
            }
          }
          return v;
        });
      },

      calcd_prev_output_tariffa_eur: function(){
        return this.output_lines.map((obj) => {
          var v = 0;
          if(obj.in_prev_output_name!==null && obj.in_prev_output_impon_eur!==null){
            if(obj.in_prev_output_h_qty){
              v = this.$roundIntNumber(obj.in_prev_output_impon_eur/obj.in_prev_output_h_qty*8);
            }
          }
          return v;
        });
      },
    },

    methods: {
      ...helperFunctions,

      objPagamLine(){
        return {
          in_prev_detpag_impon_eur: null,
          in_prev_detpag_emiss_date: null,
          in_prev_detpag_kind: 0,
        };
      },

      objOutputLine(){
        return {
          in_prev_output_name: null,
          in_prev_output_desc: null,
          in_prev_output_kind: null,
          in_prev_output_impon_eur: null,
          in_prev_output_h_qty: null,
          in_prev_output_deliver_date: null,
        };
      },

      initLines(objs, type){
        switch(type){
          case 'pagam':
            var arr = [];
            for(var i=0;i<objs.length;i++){
              var obj = objs[i];
              var t_obj = {
                in_prev_detpag_impon_eur: obj.in_prev_detpag_impon_eur,
                in_prev_detpag_emiss_date: obj.in_prev_detpag_emiss_date,
                in_prev_detpag_kind: this.$stringToFloat(obj.in_prev_detpag_kind),
              };
              arr.push(t_obj);
            }
            this.pagam_lines = arr;
            break;
          case 'output':
            var arr = [];
            for(var i=0;i<objs.length;i++){
              var obj = objs[i];
              var t_obj = {
                in_prev_output_name: obj.in_prev_output_name,
                in_prev_output_desc: obj.in_prev_output_desc,
                in_prev_output_kind: this.$getEnumValue(this.main_kinds, obj.in_prev_output_kind),
                in_prev_output_impon_eur: obj.in_prev_output_impon_eur,
                in_prev_output_h_qty: this.$stringToFloat(obj.in_prev_output_h_qty),
                in_prev_output_deliver_date: obj.in_prev_output_deliver_date,
              };
              arr.push(t_obj);
            }
            this.output_lines = arr;
            break;
          default:break;
        }
      },
    }
  }
</script>
