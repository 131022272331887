<template>
  <div>
    <input :id="id" :name="name" type="file" :placeholder="placeholder" :accept="accept" @change="emitValue">
  </div>
</template>

<script>
  export default {
    props: {
      id: String,
      name: String,
      placeholder: String,
      accept: String,
      modelValue: File,
    },

    data(){
      return {

      }
    },

    methods: {
      emitValue($event){
        var emitted_value = $event.target.files && $event.target.files.length>0 ? $event.target.files[0] : null;
        this.$emit('update:modelValue', emitted_value);
      },
    }
  }
</script>