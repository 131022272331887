<template>
  <div>
    <cep-form
      rails_env="rails_env"
      :current_user="current_user"
      :cep="cep"
      :quote_file="quote_file"
      :users="users"
      :sceneries="sceneries"
      :clients="clients"
      :cep_statuses="cep_statuses"
      :cep_interlocs="cep_interlocs"
      :cep_contact="cep_contact"
      :cep_bill_info="cep_bill_info"
      @ctmJson="ctmJson"
    ></cep-form>
    <cep-alerts
      :is_show="false"
      :title="$refs.cep_form_p ? $refs.cep_form_p.cep_data.title : null"
      :description="$refs.cep_form_p ? $refs.cep_form_p.cep_data.description : null"
      :main_kind="$refs.cep_form_p ? $refs.cep_form_p.cep_data.main_kind : null"
      :market_id="$refs.cep_form_p ? $refs.cep_form_p.cep_data.market_id : null"
      :grade="$refs.cep_form_p ? $refs.cep_form_p.cep_data.grade : null"
      :starts_at="$refs.cep_form_p ? $refs.cep_form_p.cep_data.starts_at : null"
      :finishes_at="$refs.cep_form_p ? $refs.cep_form_p.cep_data.finishes_at : null"
      :quoted_at="$refs.cep_form_p ? $refs.cep_form_p.cep_data.quoted_at : null"
      :quote_number="$refs.cep_form_p ? $refs.cep_form_p.cep_data.quote_number : null"
      :quote_expires_at="$refs.cep_form_p ? $refs.cep_form_p.cep_data.quote_expires_at : null"
      :budget_media_kind="$refs.cep_form_p ? $refs.cep_form_p.cep_data.budget_media_kind : null"
      :budget_media_tot_eur="$refs.cep_form_p ? $refs.cep_form_p.cep_data.budget_media_tot_eur : null"
      :calcd_24_prod_media_tot_eur="$refs.cep_form_ce ? $refs.cep_form_ce.calcd_24_prod_media_tot_eur : null"
      :pl_id="cep.pl_id"
      :pc_id="cep.pc_id"
      :prod_team_stip_lines="$refs.cep_form_ce ? $refs.cep_form_ce.prod_team_stip_lines : []"
      :prod_team_piva_lines="$refs.cep_form_ce ? $refs.cep_form_ce.prod_team_piva_lines : []"
      :output_lines="$refs.cep_form_p ? $refs.cep_form_p.output_lines : []"
      :pagam_lines="$refs.cep_form_p ? $refs.cep_form_p.pagam_lines : []"
      :total_price="total_price"
      ref="cep_form_a"
    ></cep-alerts>
    <div class="tabs-h-wrapper">
      <span :class="active_tab=='ce' ? 'active' : ''" @click="selectTab('ce')">Conto economico</span>
      <span :class="active_tab=='p' ? 'active' : ''" @click="selectTab('p')">Preventivo</span>
      <span :class="active_tab=='l' ? 'active' : ''" @click="selectTab('l')">Link di progetto</span>
    </div>
    <div class="tabs-c-wrapper">
      <div :class="active_tab=='ce' ? 'active-tab' : ''">
        <cep-form-c-e
          :cep="cep"
          :current_user="current_user"
          :item_general_kinds="item_general_kinds"
          :stip_act_types="stip_act_types"
          :prod_others_types="prod_others_types"
          :default_values="default_values"
          :cep_lines="cep_lines"
          :users="users"
          :partners="partners"
          :avg_values="avg_values"
          ref="cep_form_ce"
          @updateTotalPrice="updateTotalPrice"
        ></cep-form-c-e>
      </div>
      <div :class="active_tab=='p' ? 'active-tab' : ''">
        <cep-form-p
          :cep="cep"
          :cep_lines="cep_lines"
          :grades="grades"
          :main_kinds="main_kinds"
          :markets="markets"
          :signalmen="signalmen"
          :budget_media_kinds="budget_media_kinds"
          :calcd_24_prod_media_tot_eur="getMediaTot()"
          ref="cep_form_p"
        ></cep-form-p>
      </div>
      <div :class="active_tab=='l' ? 'active-tab' : ''">
        <cep-form-l
          :cep="cep"
          ref="cep_form_l"
        ></cep-form-l>
      </div>
    </div>
    <cep-form-h
      :cep="cep"
      :total_price="total_price"
      :alert_number="$refs.cep_form_a ? $refs.cep_form_a.alert_number : 0"
      :status="cep.status"
      ref="cep_form_h"
      @saveCep="saveCep"
    ></cep-form-h>
  </div>
</template>

<script>
  export default {
    props: {
      rails_env: String,
      current_user: Object,
      cep: Object,
      quote_file: Object,
      users: Array,
      sceneries: Array,
      clients: Array,
      signalmen: Array,
      cep_statuses: Array,
      cep_interlocs: Array,
      cep_lines: Object,
      item_general_kinds: Array,
      stip_act_types: Array,
      prod_others_types: Array,
      partners: Array,
      grades: Array,
      main_kinds: Array,
      budget_media_kinds: Array,
      markets: Array,
      default_values: Object,
      avg_values: Object,
      cep_contact: Object,
      cep_bill_info: Object,
    },

    data(){
      return {
        active_tab: 'ce',

        total_price: 0,
      }
    },

    mounted(){
      this.$forceUpdate();
    },

    methods: {
      updateTotalPrice(v){
        this.total_price = v;
      },

      saveCep(){
        switch(this.active_tab){
          case 'ce':
            this.$refs.cep_form_ce.saveCep().then(response => {
              this.$refs.cep_form_h.btn_class = '';
            });
            break;
          case 'p':
            this.$refs.cep_form_p.saveCep().then(response => {
              this.$refs.cep_form_h.btn_class = '';
            });
            break;
          case 'l':
            this.$refs.cep_form_l.saveCep().then(response => {
              this.$refs.cep_form_h.btn_class = '';
            });
            break;
          default:break;
        }
      },

      ctmJson(ctm_json){
        this.$refs.cep_form_ce.ctm_json = ctm_json;
        this.$refs.cep_form_p.ctm_json = ctm_json;
        this.$refs.cep_form_l.ctm_json = ctm_json;
      },

      selectTab(tab){
        if(this.active_tab!=tab){
          this.active_tab = tab;
        }
      },

      getMediaTot(){
        return this.$refs.cep_form_ce ? this.$refs.cep_form_ce.calcd_24_prod_media_tot_eur : null;
      },
    }
  }
</script>