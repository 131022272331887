<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-8">
        <p class="section-ot">Oggetto del preventivo</p>
        <div class="form-box-wrapper">
          <div class="row">
            <div class="col-12">
              <div class="value-row">
                <div class="row">
                  <div class="col-12 col-md-4">
                    <label for="title">Titolo del preventivo</label>
                    <CTextarea id="title" name="title" placeholder="Inserisci titolo" :readonly="false" v-model="cep_data.title"></CTextarea>
                  </div>
                  <div class="col-12 col-md-8">
                    <label for="description">Descrizione del preventivo</label>
                    <CTextarea id="description" name="description" placeholder="Inserisci descrizione" :readonly="false" v-model.number="cep_data.description"></CTextarea>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-3">
                    <label for="quoted_at">Data preventivo</label>
                    <CInputDate id="quoted_at" name="quoted_at" :readonly="false" v-model="cep_data.quoted_at"></CInputDate>
                  </div>
                  <div class="col-12 col-md-3">
                    <label for="quote_number">Numero preventivo</label>
                    <CInput id="quote_number" name="quote_number" placeholder="Inserisci numero preventivo" :readonly="false" v-model="cep_data.quote_number"></CInput>
                  </div>
                  <div class="col-12 col-md-3">
                    <label for="quote_expires_at">Preventivo valido fino al</label>
                    <CInputDate id="quote_expires_at" name="quote_expires_at" :readonly="false" v-model="cep_data.quote_expires_at"></CInputDate>
                  </div>
                  <div class="col-12 col-md-3">
                    <label for="quote_accepted_at">Preventivo accettato il</label>
                    <CInputDate id="quote_accepted_at" name="quote_accepted_at" :readonly="false" v-model="cep_data.quote_accepted_at"></CInputDate>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <p class="section-ot">Informazioni di progetto</p>
        <div class="form-box-wrapper">
          <div class="row">
            <div class="col-12">
              <div class="value-row">
                <div class="row">
                  <div class="col-12">
                    <label for="main_kind">Offerta prevalente</label>
                    <CSelect id="main_kind" name="main_kind" placeholder="..." :options="main_kinds" :disabled="false" v-model="cep_data.main_kind"></CSelect>
                  </div>
                  <div class="col-12">
                    <label for="market_id">Mercato</label>
                    <CSelect id="market_id" name="market_id" placeholder="..." :options="markets" :disabled="false" v-model="cep_data.market_id"></CSelect>
                  </div>
                  <div class="col-12">
                    <label for="grade">Grado</label>
                    <CSelect id="grade" name="grade" placeholder="..." :options="grades" :disabled="false" v-model="cep_data.grade"></CSelect>
                  </div>
                  <div class="col-12">
                    <label for="signalman_id">Segnalatore</label>
                    <CSelect id="signalman_id" name="signalman_id" placeholder="..." :options="signalmen" :disabled="false" v-model="cep_data.signalman_id"></CSelect>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-8">
        <p class="section-ot">Dettaglio dei pagamenti</p>
        <div class="form-box-wrapper purple">
          <div class="row">
            <div class="col-12">
              <template v-for="(pagam_line, index) in pagam_lines">
                <div class="heading" v-if="index==0">
                  <div class="row align-items-center">
                    <div class="col-12 col-md-8">
                      <div class="repeatable-wrapper">
                        <div class="row">
                          <div class="col-12 col-md-4">
                            <label for="in_prev_detpag_impon_eur">Imponibile*</label>
                          </div>
                          <div class="col-12 col-md-4">
                            <label for="in_prev_detpag_emiss_date">Emissione*</label>
                          </div>
                          <div class="col-12 col-md-4">
                            <label for="in_prev_detpag_kind">Termini</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <div class="row">
                        <div class="col-12">
                          <span class="value wmt">Incasso</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="value-row">
                  <div class="row">
                    <div class="col-12 col-md-8">
                      <div class="repeatable-wrapper">
                        <div class="row">
                          <div class="col-12 col-md-4">
                            <CInputMoney :class="$getRequiredFieldClass(pagam_line.in_prev_detpag_impon_eur)" id="in_prev_detpag_impon_eur" name="in_prev_detpag_impon_eur" placeholder="0,00" :readonly="false" v-model.number="pagam_line.in_prev_detpag_impon_eur"></CInputMoney>
                          </div>
                          <div class="col-12 col-md-4">
                            <CInputDate :class="$getRequiredFieldClass(pagam_line.in_prev_detpag_emiss_date)" id="in_prev_detpag_emiss_date" name="in_prev_detpag_emiss_date" placeholder="Data" :readonly="false" v-model="pagam_line.in_prev_detpag_emiss_date"></CInputDate>
                          </div>
                          <div class="col-12 col-md-4">
                            <CInputNumber id="in_prev_detpag_kind" name="in_prev_detpag_kind" placeholder="0" :readonly="false" v-model.number="pagam_line.in_prev_detpag_kind"></CInputNumber>
                          </div>
                        </div>
                        <span class="remove-row" @click="removePrevLine('pagam', index)"></span>
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <div class="row">
                        <div class="col-12">
                          <span class="value wmt">{{$niceDate(calcd_prev_detpag_incasso_prev[index])}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <span class="add-row" @click="addPrevLine('pagam')"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <p class="section-ot">Scadenze di progetto</p>
        <div class="form-box-wrapper">
          <div class="row">
            <div class="col-12">
              <div class="value-row">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <label for="starts_at">Data inizio attività</label>
                    <CInputDate id="starts_at" name="starts_at" placeholder="Dal" :readonly="false" v-model="cep_data.starts_at"></CInputDate>
                  </div>
                  <div class="col-12 col-md-6">
                    <label for="finishes_at">Data fine attività</label>
                    <CInputDate id="finishes_at" name="finishes_at" placeholder="Al" :readonly="false" v-model="cep_data.finishes_at"></CInputDate>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p class="section-ot">Budget media</p>
        <div class="form-box-wrapper">
          <div class="row">
            <div class="col-12">
              <div class="value-row">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <label for="budget_media_kind">Tipologia</label>
                    <CSelect id="budget_media_kind" name="budget_media_kind" placeholder="..." :options="budget_media_kinds" :disabled="false" v-model="cep_data.budget_media_kind"></CSelect>
                  </div>
                  <div class="col-12 col-md-6">
                    <label for="budget_media_tot_eur">Importo</label>
                    <CInputMoney id="budget_media_tot_eur" name="budget_media_tot_eur" :readonly="false" v-model.number="cep_data.budget_media_tot_eur"></CInputMoney>
                  </div>
                  <div class="col-12">
                    <template v-if="cep_data.budget_media_kind==0">
                      <p class="hint"><strong>NON</strong> è previsto budget media</p>
                    </template>
                    <template v-if="cep_data.budget_media_kind==1">
                      <p class="hint">Budget media speso direttamente da account/carte del cliente</p>
                    </template>
                    <template v-if="cep_data.budget_media_kind==2">
                      <p class="hint">Budget media versato dal cliente <strong>PRIMA</strong> di essere gestito</p>
                    </template>
                    <template v-if="cep_data.budget_media_kind==3">
                      <p class="hint">Budget media <strong>ANTICIPATO</strong> e gestito da Twig</p>
                    </template>
                  </div>
                  <div class="col-12">
                    <p class="hint">Budget media nel conto economico: <strong>{{$niceMoney(calcd_24_prod_media_tot_eur)}}</strong></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <p class="section-ot">Output del preventivo</p>
        <div class="form-box-wrapper purple">
          <div class="row">
            <div class="col-12">
              <template v-for="(output_line, index) in output_lines">
                <div class="heading" v-if="index==0">
                  <div class="row align-items-center">
                    <div class="col-12 col-md-8">
                      <div class="repeatable-wrapper">
                        <div class="row">
                          <div class="col-12 col-md-4">
                            <label for="in_prev_output_name">Nome dell'output*</label>
                          </div>
                          <div class="col-12 col-md-2">
                            <label for="in_prev_output_kind">Tipo offerta*</label>
                          </div>
                          <div class="col-12 col-md-2">
                            <label for="in_prev_output_impon_eur">Imponibile*</label>
                          </div>
                          <div class="col-12 col-md-2">
                            <label for="in_prev_output_h_qty">Ore</label>
                          </div>
                          <div class="col-12 col-md-2">
                            <label for="in_prev_output_deliver_date">Consegna</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <div class="row">
                        <div class="col-12 col-md-6">
                          <span class="value wmt">% Offerta</span>
                        </div>
                        <div class="col-12 col-md-6">
                          <span class="value wmt">Tariffa giornaliera</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="value-row">
                  <div class="row">
                    <div class="col-12 col-md-8">
                      <div class="repeatable-wrapper">
                        <div class="row">
                          <div class="col-12 col-md-4">
                            <CInput :class="$getRequiredFieldClass(output_line.in_prev_output_name)" id="in_prev_output_name" name="in_prev_output_name" placeholder="Inserisci il nome" :readonly="false" v-model="output_line.in_prev_output_name"></CInput>
                          </div>
                          <div class="col-12 col-md-2">
                            <CSelect :class="$getRequiredFieldClass(output_line.in_prev_output_kind)" id="in_prev_output_kind" name="in_prev_output_kind" placeholder="..." :options="main_kinds" :disabled="false" v-model="output_line.in_prev_output_kind"></CSelect>
                          </div>
                          <div class="col-12 col-md-2">
                            <CInputMoney :class="$getRequiredFieldClass(output_line.in_prev_output_impon_eur)" id="in_prev_output_impon_eur" name="in_prev_output_impon_eur" placeholder="0,00" :readonly="false" v-model.number="output_line.in_prev_output_impon_eur"></CInputMoney>
                          </div>
                          <div class="col-12 col-md-2">
                            <CInputHour id="in_prev_output_h_qty" name="in_prev_output_h_qty" placeholder="0,00" :readonly="false" v-model.number="output_line.in_prev_output_h_qty"></CInputHour>
                          </div>
                          <div class="col-12 col-md-2">
                            <CInputDate id="in_prev_output_deliver_date" name="in_prev_output_deliver_date" placeholder="Data" :readonly="false" v-model="output_line.in_prev_output_deliver_date"></CInputDate>
                          </div>
                          <div class="col-12 col-md-6">
                            <CTextarea id="in_prev_output_desc" name="in_prev_output_desc" placeholder="Inserisci la descrizione*" :readonly="false" v-model="output_line.in_prev_output_desc"></CTextarea>
                          </div>
                        </div>
                        <span class="remove-row" @click="removePrevLine('output', index)"></span>
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <div class="row">
                        <div class="col-12 col-md-6">
                          <span class="value wmt">{{$niceIntPerc(calcd_prev_output_offerta_perc[index])}}</span>
                        </div>
                        <div class="col-12 col-md-6">
                          <span class="value wmt">{{$niceMoney(calcd_prev_output_tariffa_eur[index])}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <span class="add-row" @click="addPrevLine('output')"></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ctm-banner success" :class="success_banner_class">
      <p>Le modifiche sono state salvate con successo</p>
    </div>
    <div class="ctm-banner error" :class="error_banner_class">
      <p>Si è verificato un problema durante il salvataggio delle modifiche</p>
    </div>
  </div>
</template>

<script>
  import CSelect from '../form/CSelect.vue'
  import CInput from '../form/CInput.vue'
  import CTextarea from '../form/CTextarea.vue'
  import CInputDate from '../form/CInputDate.vue'
  import CInputPerc from '../form/CInputPerc.vue'
  import CInputMoney from '../form/CInputMoney.vue'
  import CInputHour from '../form/CInputHour.vue'
  import CInputNumber from '../form/CInputNumber.vue'

  import * as helperFunctions from "../../helpers/helpers"

  export default {
    components:{
      CSelect,
      CInput,
      CTextarea,
      CInputDate,
      CInputPerc,
      CInputMoney,
      CInputHour,
      CInputNumber,
    },

    props: {
      cep: Object,
      cep_lines: Object,
      grades: Array,
      main_kinds: Array,
      markets: Array,
      signalmen: Array,
      budget_media_kinds: Array,
      calcd_24_prod_media_tot_eur: Number,
    },

    data(){
      return {
        ctm_json: null,

        cep_data: {
          title: this.cep.title,
          description: this.cep.description,
          main_kind: this.$getEnumValue(this.main_kinds, this.cep.main_kind),
          market_id: this.cep.market_id,
          grade: this.$getEnumValue(this.grades, this.cep.grade),
          signalman_id: this.cep.signalman_id,
          starts_at: this.cep.starts_at,
          finishes_at: this.cep.finishes_at,
          quoted_at: this.cep.quoted_at,
          quote_number: this.cep.quote_number,
          quote_expires_at: this.cep.quote_expires_at,
          quote_accepted_at: this.cep.quote_accepted_at,
          budget_media_kind: this.$getEnumValue(this.budget_media_kinds, this.cep.budget_media_kind),
          budget_media_tot_eur: this.$fromCentToEur(this.cep.budget_media_tot_eur),
        },

        pagam_lines: [],
        output_lines: [],

        btn_class: '',
        success_banner_class: '',
        error_banner_class: '',
      }
    },

    watch: {
      ctm_json: function(){
        if(ctm_json){
          var parsed_json = JSON.parse(this.ctm_json);
          var keys = [
            'title',
            'description',
            'starts_at',
            'main_kind',
            'market_id',
            'signalman_id',
            'grade',
            'finishes_at',
            'quoted_at',
            'quote_number',
            'quote_expires_at',
            'quote_accepted_at',
            'budget_media_kind',
            'budget_media_tot_eur',
            'pagam_lines',
            'output_lines',
          ];

          for(var i=0;i<keys.length;i++){
            var key = keys[i];
            if(key.includes('_eur')){
              this.cep_data[key] = this.$fromCentToEur(parsed_json.in[key]);
            }
            else if(key.includes('_lines')){
              switch(key){
                case 'pagam_lines':
                  var arr = [];
                  if(parsed_json.in[key]){
                    for(var x=0;x<parsed_json.in[key].length;x++){
                      var obj = {
                        id: null,
                        in_prev_detpag_impon_eur: this.$fromCentToEur(parsed_json.in[key][x].in_prev_detpag_impon_eur),
                        in_prev_detpag_emiss_date: parsed_json.in[key][x].in_prev_detpag_emiss_date,
                        in_prev_detpag_kind: parsed_json.in[key][x].in_prev_detpag_kind,
                      };
                      arr.push(obj);
                    }
                  }
                  if(arr && arr.length>0){
                    this.pagam_lines = arr;
                  }
                  else{
                    this.pagam_lines = [this.objPagamLine()];
                  }
                  break;
                case 'output_lines':
                  var arr = [];
                  if(parsed_json.in[key]){
                    for(var x=0;x<parsed_json.in[key].length;x++){
                      var obj = {
                        id: null,
                        in_prev_output_name: parsed_json.in[key][x].in_prev_output_name,
                        in_prev_output_desc: String(parsed_json.in[key][x].in_prev_output_desc),
                        in_prev_output_kind: parsed_json.in[key][x].in_prev_output_kind,
                        in_prev_output_impon_eur: this.$fromCentToEur(parsed_json.in[key][x].in_prev_output_impon_eur),
                        in_prev_output_h_qty: parsed_json.in[key][x].in_prev_output_h_qty,
                        in_prev_output_deliver_date: parsed_json.in[key][x].in_prev_output_deliver_date,
                      };
                      arr.push(obj);
                    }
                  }
                  if(arr && arr.length>0){
                    this.output_lines = arr;
                  }
                  else{
                    this.output_lines = [this.objOutputLine()];
                  }
                  break;
                default:break;
              }
            }
            else{
              this.cep_data[key] = parsed_json.in[key];
            }
          }
        }
      },
    },

    created(){
      if((!this.pagam_lines || this.pagam_lines.length==0) && this.cep_lines && this.cep_lines.pagam_lines && this.cep_lines.pagam_lines.length>0){
        this.initLines(this.cep_lines.pagam_lines, 'pagam');
      }
      else{
        this.pagam_lines = [this.objPagamLine()];
      }
      if((!this.output_lines || this.output_lines.length==0) && this.cep_lines && this.cep_lines.output_lines && this.cep_lines.output_lines.length>0){
        this.initLines(this.cep_lines.output_lines, 'output');
      }
      else{
        this.output_lines = [this.objOutputLine()];
      }
    },

    computed: {
      calcd_prev_detpag_incasso_prev: function(){
        return this.pagam_lines.map((obj) => {
          var v = null;
          if(obj.in_prev_detpag_emiss_date!==null && obj.in_prev_detpag_kind!==null){
            var date = new Date(obj.in_prev_detpag_emiss_date);
            date.setDate(date.getDate() + obj.in_prev_detpag_kind);
            v = this.$niceFromattedDate(date);
          }
          return v;
        });
      },

      calcd_prev_output_impon_tot_eur: function(){
        var v = this.output_lines.filter((obj) => { return obj.in_prev_output_name!==null && obj.in_prev_output_impon_eur!==null; }).reduce((n, {in_prev_output_impon_eur}) => n+in_prev_output_impon_eur, 0);
        return v;
      },

      calcd_prev_output_tot_h: function(){
        var v = this.output_lines.filter((obj) => { return obj.in_prev_output_name!==null && obj.in_prev_output_impon_eur!==null; }).reduce((n, {in_prev_output_h_qty}) => n+in_prev_output_h_qty, 0);
        return v;
      },

      calcd_prev_output_offerta_perc: function(){
        return this.output_lines.map((obj) => {
          var v = 0;
          if(obj.in_prev_output_name!==null && obj.in_prev_output_impon_eur!==null){
            if(this.calcd_prev_output_impon_tot_eur){
              v = this.$calculatePerc(obj.in_prev_output_impon_eur/this.calcd_prev_output_impon_tot_eur);
            }
          }
          return v;
        });
      },

      calcd_prev_output_tariffa_eur: function(){
        return this.output_lines.map((obj) => {
          var v = 0;
          if(obj.in_prev_output_name!==null && obj.in_prev_output_impon_eur!==null){
            if(obj.in_prev_output_h_qty){
              v = this.$roundIntNumber(obj.in_prev_output_impon_eur/obj.in_prev_output_h_qty*8);
            }
          }
          return v*100;
        });
      },
    },

    methods: {
      ...helperFunctions,

      objPagamLine(){
        return {
          id: null,
          in_prev_detpag_impon_eur: null,
          in_prev_detpag_emiss_date: null,
          in_prev_detpag_kind: 0,
        };
      },

      objOutputLine(){
        return {
          id: null,
          in_prev_output_name: null,
          in_prev_output_desc: null,
          in_prev_output_kind: null,
          in_prev_output_impon_eur: null,
          in_prev_output_h_qty: null,
          in_prev_output_deliver_date: null,
        };
      },

      initLines(objs, type){
        switch(type){
          case 'pagam':
            var arr = [];
            for(var i=0;i<objs.length;i++){
              var obj = objs[i];
              var t_obj = {
                id: obj.id,
                in_prev_detpag_impon_eur: this.$fromCentToEur(obj.in_prev_detpag_impon_eur),
                in_prev_detpag_emiss_date: obj.in_prev_detpag_emiss_date,
                in_prev_detpag_kind: this.$stringToFloat(obj.in_prev_detpag_kind),
              };
              arr.push(t_obj);
            }
            this.pagam_lines = arr;
            break;
          case 'output':
            var arr = [];
            for(var i=0;i<objs.length;i++){
              var obj = objs[i];
              var t_obj = {
                id: obj.id,
                in_prev_output_name: obj.in_prev_output_name,
                in_prev_output_desc: obj.in_prev_output_desc,
                in_prev_output_kind: this.$getEnumValue(this.main_kinds, obj.in_prev_output_kind),
                in_prev_output_impon_eur: this.$fromCentToEur(obj.in_prev_output_impon_eur),
                in_prev_output_h_qty: this.$stringToFloat(obj.in_prev_output_h_qty),
                in_prev_output_deliver_date: obj.in_prev_output_deliver_date,
              };
              arr.push(t_obj);
            }
            this.output_lines = arr;
            break;
          default:break;
        }
      },

      addPrevLine(type){
        switch(type){
          case 'pagam':
            this.pagam_lines.push(this.objPagamLine());
            break;
          case 'output':
            this.output_lines.push(this.objOutputLine());
            break;
          default:break;
        }
      },

      removePrevLine(type, index){
        switch(type){
          case 'pagam':
            this.pagam_lines.splice(index, 1);
            break;
          case 'output':
            this.output_lines.splice(index, 1);
            break;
          default:break;
        }
      },

      saveCep(){
        this.btn_class = 'loading';
        var params = {
          cep: {
            // Comp section
              comp_section: 'p',
            // In
              title: this.cep_data.title,
              description: this.cep_data.description,
              main_kind: this.cep_data.main_kind,
              market_id: this.cep_data.market_id,
              signalman_id: this.cep_data.signalman_id,
              grade: this.cep_data.grade,
              starts_at: this.cep_data.starts_at,
              finishes_at: this.cep_data.finishes_at,
              quoted_at: this.cep_data.quoted_at,
              quote_number: this.cep_data.quote_number,
              quote_expires_at: this.cep_data.quote_expires_at,
              quote_accepted_at: this.cep_data.quote_accepted_at,
              budget_media_kind: this.cep_data.budget_media_kind,
              budget_media_tot_eur: this.$fromEurToCent(this.cep_data.budget_media_tot_eur),
            // Lines
              pagam_lines: this.pagam_lines.map((obj, index) => {
                return {
                  id: obj.id,
                  in_prev_detpag_impon_eur: this.$fromEurToCent(obj.in_prev_detpag_impon_eur),
                  in_prev_detpag_emiss_date: obj.in_prev_detpag_emiss_date,
                  in_prev_detpag_kind: String(obj.in_prev_detpag_kind),
                  calcd_prev_detpag_incasso_prev: this.calcd_prev_detpag_incasso_prev[index],
                };
              }).filter((obj) => { return obj.in_prev_detpag_impon_eur!==null && obj.in_prev_detpag_emiss_date!==null; }),
              output_lines: this.output_lines.map((obj, index) => {
                return {
                  id: obj.id,
                  in_prev_output_name: obj.in_prev_output_name,
                  in_prev_output_desc: obj.in_prev_output_desc,
                  in_prev_output_kind: obj.in_prev_output_kind,
                  in_prev_output_impon_eur: this.$fromEurToCent(obj.in_prev_output_impon_eur),
                  in_prev_output_h_qty: obj.in_prev_output_h_qty,
                  in_prev_output_deliver_date: obj.in_prev_output_deliver_date,
                  calcd_prev_output_offerta_perc: this.calcd_prev_output_offerta_perc[index],
                  calcd_prev_output_tariffa_eur: this.calcd_prev_output_tariffa_eur[index],
                };
              }).filter((obj) => { return obj.in_prev_output_name!==null && obj.in_prev_output_impon_eur!==null; }),
          }
        };
        var inst = this;
        return new Promise(resolve => {
          inst.axios.put('/api/ceps/'+inst.cep.id, params).then(response => {
            var obj = response.data;
            if(obj.data.success){
              inst.btn_class = '';
              inst.success_banner_class = 'shown';
              setTimeout(() => inst.success_banner_class = '', 3000);
              resolve({data: {status: 'Ok', message: 'L\'operazione è stata eseguita con successo'}});
            }
            else{
              inst.btn_class = '';
              inst.error_banner_class = 'shown';
              setTimeout(() => inst.error_banner_class = '', 3000);
              resolve({data: {status: 'Error', message: 'Si è verificato un errore'}});
            }
          });
        });
      }
    }
  }
</script>
