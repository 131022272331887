<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="alerts-box-wrapper" v-if="alert_number>0">
          <div class="row">
            <div class="col-12">
              <template v-if="ce_show">
                <h6>Tab "Conto economico"</h6>
                <template v-if="checkAlert('prod_team_stip_lines_pl')">
                  <p>- nello scenario corrente (CODICE SCENARIO) non è previsto aver attività PL nel blocco <strong>2.1 Produzione Team a stipendio</strong></p>
                </template>
                <template v-if="checkAlert('prod_team_stip_lines_pc')">
                  <p>- nello scenario corrente (CODICE SCENARIO) non è previsto aver attività PC nel blocco <strong>2.1 Produzione Team a stipendio</strong></p>
                </template>
                <template v-if="checkAlert('prod_team_piva_lines_pl')">
                  <p>- nello scenario corrente (CODICE SCENARIO) non è previsto aver attività PL nel blocco <strong>2.2 Produzione Team a p.iva</strong></p>
                </template>
                <template v-if="checkAlert('prod_team_piva_lines_pc')">
                  <p>- nello scenario corrente (CODICE SCENARIO) non è previsto aver attività PC nel blocco <strong>2.2 Produzione Team a p.iva</strong></p>
                </template>
              </template>
              <template v-if="p_show">
                <h6>Tab "Preventivo"</h6>
                <template v-if="checkAlert('title')">
                  <p>- il campo <strong>Titolo del preventivo</strong> non può essere vuoto</p>
                </template>
                <template v-if="checkAlert('description')">
                  <p>- il campo <strong>Descrizione del preventivo</strong> non può essere vuoto</p>
                </template>
                <template v-if="checkAlert('main_kind')">
                  <p>- il campo <strong>Offerta prevalente</strong> non può essere vuoto</p>
                </template>
                <template v-if="checkAlert('market_id')">
                  <p>- il campo <strong>Mercato</strong> non può essere vuoto</p>
                </template>
                <template v-if="checkAlert('grade')">
                  <p>- il campo <strong>Grado</strong> non può essere vuoto</p>
                </template>
                <template v-if="checkAlert('starts_at')">
                  <p>- il campo <strong>Data inizio attività</strong> non può essere vuoto</p>
                </template>
                <template v-if="checkAlert('finishes_at')">
                  <p>- il campo <strong>Data fine attività</strong> non può essere vuoto</p>
                </template>
                <template v-if="checkAlert('quoted_at')">
                  <p>- il campo <strong>Data preventivo</strong> non può essere vuoto</p>
                </template>
                <template v-if="checkAlert('quote_number')">
                  <p>- il campo <strong>Numero preventivo</strong> non può essere vuoto</p>
                </template>
                <template v-if="checkAlert('quote_expires_at')">
                  <p>- il campo <strong>Preventivo valido fino al</strong> non può essere vuoto</p>
                </template>
                <template v-if="checkAlert('budget_media_kind')">
                  <p>- l'importo del campo <strong>Budget media</strong> è diverso rispetto al valore degli spazi media nel conto economico (Spazi media conto economico: {{$niceMoney(calcd_24_prod_media_tot_eur)}} - Budget media: <template v-if="is_show">{{$niceMoney(budget_media_tot_eur)}}</template><template v-else>{{$niceMoney(budget_media_tot_eur*100)}}</template>)</p>
                </template>
                <template v-if="checkAlert('output_lines_at_least_one')">
                  <p>- deve essere presente almeno una riga nel blocco <strong>Output del preventivo</strong> con i campi "Nome dell'output", "Tipo offerta", "Imponibile", "Descrizione output" compilati</p>
                </template>
                <template v-if="checkAlert('output_lines_tot')">
                  <p>- la somma degli imponibili nel blocco <strong>Output del preventivo</strong> è diverso rispetto al prezzo di vendita calcolato (Prezzo di vendita calcolato: {{$niceMoney(total_price)}} - Somma output del preventivo: {{$niceMoney(outputLinesSum)}})</p>
                </template>
                <template v-if="checkAlert('pagam_lines_tot')">
                  <p>- la somma dei pagamenti nel blocco <strong>Dettaglio dei pagamenti</strong> è diverso rispetto al prezzo di vendita calcolato (Prezzo di vendita calcolato: {{$niceMoney(total_price)}} - Somma dei pagamenti: {{$niceMoney(pagamLinesSum)}})</p>
                </template>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as helperFunctions from "../../helpers/helpers"

  export default {
    props: {
      is_show: Boolean,
      title: String,
      description: String,
      main_kind: [Number, String],
      market_id: Number,
      grade: [Number, String],
      starts_at: String,
      finishes_at: String,
      quoted_at: String,
      quote_number: String,
      quote_expires_at: String,
      budget_media_kind: [Number, String],
      budget_media_tot_eur: Number,
      calcd_24_prod_media_tot_eur: Number,
      pl_id: Number,
      pc_id: Number,
      prod_team_stip_lines: Array,
      prod_team_piva_lines: Array,
      output_lines: Array,
      pagam_lines: Array,
      total_price: Number,
    },

    data(){
      return {

      }
    },

    computed: {
      alert_number(){
        var count = 0;
        var arr = ['title', 'description', 'main_kind', 'market_id', 'grade', 'starts_at', 'finishes_at', 'quoted_at', 'quote_number', 'quote_expires_at', 'budget_media_kind', 'prod_team_stip_lines_pl', 'prod_team_stip_lines_pc', 'prod_team_piva_lines_pl', 'prod_team_piva_lines_pc', 'output_lines_at_least_one', 'output_lines_tot', 'pagam_lines_tot'];
        for(var i=0;i<arr.length;i++){
          if(this.checkAlert(arr[i])){
            count++;
          }
        }
        return count;
      },

      ce_show(){
        return this.checkAlert('prod_team_stip_lines_pl') || this.checkAlert('prod_team_stip_lines_pc') || this.checkAlert('prod_team_piva_lines_pl') || this.checkAlert('prod_team_piva_lines_pc');
      },

      p_show(){
        return this.checkAlert('title') || this.checkAlert('description') || this.checkAlert('main_kind') || this.checkAlert('market_id') || this.checkAlert('grade') || this.checkAlert('starts_at') || this.checkAlert('finishes_at') || this.checkAlert('quoted_at') || this.checkAlert('quote_number') || this.checkAlert('quote_expires_at') || this.checkAlert('budget_media_kind') || this.checkAlert('output_lines_at_least_one') || this.checkAlert('output_lines_tot') || this.checkAlert('pagam_lines_tot');
      },

      outputLinesSum(){
        var sum = this.output_lines.filter((item) => { return item.in_prev_output_name!==null && item.in_prev_output_impon_eur!==null; }).reduce((n, {in_prev_output_impon_eur}) => n+in_prev_output_impon_eur, 0);
        if(!this.is_show){
          sum = sum*100;
        }
        return sum;
      },

      pagamLinesSum(){
        var sum = this.pagam_lines.filter((item) => { return item.in_prev_detpag_impon_eur!==null; }).reduce((n, {in_prev_detpag_impon_eur}) => n+in_prev_detpag_impon_eur, 0);
        if(!this.is_show){
          sum = sum*100;
        }
        return sum;
      },
    },

    methods: {
      ...helperFunctions,

      checkAlert(type){
        var flag = false;
        switch(type){
          case 'title':
            if(!this.title || this.title==''){
              flag = true;
            }
            break;
          case 'description':
            if(!this.description || this.description==''){
              flag = true;
            }
            break;
          case 'main_kind':
            if(this.main_kind===null){
              flag = true;
            }
            break;
          case 'market_id':
            if(this.market_id===null){
              flag = true;
            }
            break;
          case 'grade':
            if(this.grade===null){
              flag = true;
            }
            break;
          case 'starts_at':
            if(!this.starts_at || this.starts_at==''){
              flag = true;
            }
            break;
          case 'finishes_at':
            if(!this.finishes_at || this.finishes_at==''){
              flag = true;
            }
            break;
          case 'quoted_at':
            if(!this.quoted_at || this.quoted_at==''){
              flag = true;
            }
            break;
          case 'quote_number':
            if(!this.quote_number || this.quote_number==''){
              flag = true;
            }
            break;
          case 'quote_expires_at':
            if(!this.quote_expires_at || this.quote_expires_at==''){
              flag = true;
            }
            break;
          case 'budget_media_kind':
            var arr = [2, 3, 'anticipated_by_client', 'anticipated_by_twig'];
            if(arr.includes(this.budget_media_kind)){
              if(this.is_show){
                if(this.budget_media_tot_eur!=this.calcd_24_prod_media_tot_eur){
                  flag = true;
                }
              }
              else{
                if(this.budget_media_tot_eur!=(this.calcd_24_prod_media_tot_eur/100)){
                  flag = true;
                }
              }
            }
            break;
          case 'prod_team_stip_lines_pl':
            if(!this.pl_id){
              var f_arr = this.prod_team_stip_lines.filter((item) => {
                return (item.in_21_prod_team_stip_act_type===0 || item.in_21_prod_team_stip_act_type=='PL - Direzione') && item.in_21_prod_team_stip_h_cost_eur>0 && item.in_21_prod_team_stip_h_qty>0;
              });
              if(f_arr && f_arr.length>0){
                flag = true;
              }
            }
            break;
          case 'prod_team_stip_lines_pc':
            if(!this.pc_id){
              var f_arr = this.prod_team_stip_lines.filter((item) => {
                return (item.in_21_prod_team_stip_act_type===1 || item.in_21_prod_team_stip_act_type=='PC - Coordinamento') && item.in_21_prod_team_stip_h_cost_eur>0 && item.in_21_prod_team_stip_h_qty>0;
              });
              if(f_arr && f_arr.length>0){
                flag = true;
              }
            }
            break;
          case 'prod_team_piva_lines_pl':
            if(!this.pl_id){
              var f_arr = this.prod_team_piva_lines.filter((item) => {
                return (item.in_22_prod_team_piva_act_type===0 || item.in_22_prod_team_piva_act_type=='PL - Direzione') && item.in_22_prod_team_piva_h_cost_eur>0 && item.in_22_prod_team_piva_h_qty>0;
              });
              if(f_arr && f_arr.length>0){
                flag = true;
              }
            }
            break;
          case 'prod_team_piva_lines_pc':
            if(!this.pc_id){
              var f_arr = this.prod_team_piva_lines.filter((item) => {
                return (item.in_22_prod_team_piva_act_type===1 || item.in_22_prod_team_piva_act_type=='PC - Coordinamento') && item.in_22_prod_team_piva_h_cost_eur>0 && item.in_22_prod_team_piva_h_qty>0;
              });
              if(f_arr && f_arr.length>0){
                flag = true;
              }
            }
            break;
          case 'output_lines_at_least_one':
            var f_arr = this.output_lines.filter((item) => {
              return item.in_prev_output_name!='' && (item.in_prev_output_kind || item.in_prev_output_kind>=0) && item.in_prev_output_impon_eur>0 && item.in_prev_output_desc!='';
            });
            if(!f_arr || f_arr.length==0){
              flag = true;
            }
            break;
          case 'output_lines_tot':
            var v = this.outputLinesSum/100;
            if((this.total_price/100)!=v){
              flag = true;
            }
            break;
          case 'pagam_lines_tot':
            var v = this.pagamLinesSum/100;
            if((this.total_price/100)!=v){
              flag = true;
            }
            break;
          default:break;
        }
        return flag;
      },
    }
  }
</script>
