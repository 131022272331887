<template>
  <div>
    <div class="row">
      <div class="col-12">
        <span class="create-btn" @click="showManageHValues()">Crea / Modifica storico</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <p class="section-ot red">Consuntivo di bilancio</p>
        <div class="an-c-wrapper red">
          <div class="c-heading">
            <div class="row">
              <div class="col-12 col-md-6">
                <span class="label">Consuntivo di bilancio</span>
                <span class="value">{{$niceMoney(comp_co_values.calcd_cons_tot_eur)}}</span>
              </div>
              <div class="col-12 col-md-6">
                <span class="label">Qual è l'obiettivo?</span>
                <span class="value">
                  <template v-if="edit_mode=='cdb_obj'">
                    <CInputMoney id="cdb_obj" name="cdb_obj" placeholder="0,00" :readonly="false" v-model.number="cea_data.cdb_obj"></CInputMoney>
                    <span class="save-btn" @click="saveCeaObjs('cdb_obj')"></span>
                  </template>
                  <template v-else>
                    {{$niceMoney($fromEurToCent(cea_data.cdb_obj))}} <span class="edit-btn" @click="activateEditMode('cdb_obj')"></span>
                  </template>
                </span>
              </div>
            </div>
          </div>
          <div class="i-an-c-wrapper" id="cob_chart"></div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <p class="section-ot yellow">CCF (copertura costi fissi)</p>
        <div class="an-c-wrapper yellow">
          <div class="c-heading">
            <div class="row">
              <div class="col-12 col-md-6">
                <span class="label">CCF</span>
                <span class="value">{{$nicePerc(comp_co_values.calcd_det_cons_t_tot_perc)}}</span>
              </div>
              <div class="col-12 col-md-6">
                <span class="label">Qual è il limite?</span>
                <span class="value">
                  <template v-if="edit_mode=='ccf_lim'">
                    <CInputPerc id="ccf_lim" name="ccf_lim" placeholder="0,00" :readonly="false" v-model.number="cea_data.ccf_lim"></CInputPerc>
                    <span class="save-btn" @click="saveCeaObjs('ccf_lim')"></span>
                  </template>
                  <template v-else>
                    {{$nicePerc(cea_data.ccf_lim)}} <span class="edit-btn" @click="activateEditMode('ccf_lim')"></span>
                  </template>
                </span>
              </div>
            </div>
          </div>
          <div class="i-an-c-wrapper" id="ccf_chart"></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <p class="section-ot purple">Sforzo commerciale complessivo</p>
        <div class="an-c-wrapper purple">
          <div class="c-heading">
            <div class="row">
              <div class="col-12">
                <span class="label">Sforzo commerciale</span>
                <span class="value">{{$niceMoney(comp_co_values.calcd_comm_effort_tot_eur)}}</span>
              </div>
            </div>
          </div>
          <div class="i-an-c-wrapper" id="scc_chart"></div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <p class="section-ot green">Consolidato nell’anno</p>
        <div class="an-c-wrapper green">
          <div class="c-heading">
            <div class="row">
              <div class="col-12 col-md-6">
                <span class="label">Consolidato nell’anno</span>
                <span class="value">{{$niceMoney(comp_co_values.calcd_cons_impon_eur)}}</span>
              </div>
              <div class="col-12 col-md-6">
                <span class="label">Qual è l'obiettivo?</span>
                <span class="value">
                  <template v-if="edit_mode=='cna_obj'">
                    <CInputMoney id="cna_obj" name="cna_obj" placeholder="0,00" :readonly="false" v-model.number="cea_data.cna_obj"></CInputMoney>
                    <span class="save-btn" @click="saveCeaObjs('cna_obj')"></span>
                  </template>
                  <template v-else>
                    {{$niceMoney($fromEurToCent(cea_data.cna_obj))}} <span class="edit-btn" @click="activateEditMode('cna_obj')"></span>
                  </template>
                </span>
              </div>
            </div>
          </div>
          <div class="i-an-c-wrapper" id="yco_chart"></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <p class="section-ot orange">MOL</p>
        <div class="an-c-wrapper orange">
          <div class="c-heading">
            <div class="row">
              <div class="col-12 col-md-6">
                <span class="label">MOL</span>
                <span class="value">{{$nicePerc(comp_co_values.calcd_det_cons_mol_perc)}}</span>
              </div>
              <div class="col-12 col-md-6">
                <span class="label">Qual è il limite?</span>
                <span class="value">
                  <template v-if="edit_mode=='mol_lim'">
                    <CInputPerc id="mol_lim" name="mol_lim" placeholder="0,00" :readonly="false" v-model.number="cea_data.mol_lim"></CInputPerc>
                    <span class="save-btn" @click="saveCeaObjs('mol_lim')"></span>
                  </template>
                  <template v-else>
                    {{$nicePerc(cea_data.mol_lim)}} <span class="edit-btn" @click="activateEditMode('mol_lim')"></span>
                  </template>
                </span>
              </div>
            </div>
          </div>
          <div class="i-an-c-wrapper" id="mol_chart"></div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <p class="section-ot orange">Quanto abbiamo in trattativa</p>
        <div class="an-c-wrapper orange">
          <div class="c-heading">
            <div class="row">
              <div class="col-12">
                <span class="label">Trattativa</span>
                <span class="value">{{$niceMoney(comp_co_values.calcd_trat_impon_eur)}}</span>
              </div>
            </div>
          </div>
          <div class="i-an-c-wrapper" id="trt_chart"></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <p class="section-ot purple">Tariffa media</p>
        <div class="an-c-wrapper purple">
          <div class="c-heading">
            <div class="row">
              <div class="col-12 col-md-6">
                <span class="label">Tariffa media</span>
                <span class="value">{{$niceMoney(comp_co_values.calcd_det_cons_avg_daily_rate_eur)}}</span>
              </div>
              <div class="col-12 col-md-6">
                <span class="label">Qual è l'obiettivo?</span>
                <span class="value">
                  <template v-if="edit_mode=='tam_obj'">
                    <CInputMoney id="tam_obj" name="tam_obj" placeholder="0,00" :readonly="false" v-model.number="cea_data.tam_obj"></CInputMoney>
                    <span class="save-btn" @click="saveCeaObjs('tam_obj')"></span>
                  </template>
                  <template v-else>
                    {{$niceMoney($fromEurToCent(cea_data.tam_obj))}} <span class="edit-btn" @click="activateEditMode('tam_obj')"></span>
                  </template>
                </span>
              </div>
            </div>
          </div>
          <div class="i-an-c-wrapper" id="avgt_chart"></div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <p class="section-ot purple">Tasso di conversione</p>
        <div class="an-c-wrapper purple">
          <div class="c-heading">
            <div class="row">
              <div class="col-12 col-md-6">
                <span class="label">Tasso di conversione</span>
                <span class="value">{{$nicePerc(comp_co_values.calcd_conv_rate)}}</span>
              </div>
              <div class="col-12 col-md-6">
                <span class="label">Qual è l'obiettivo?</span>
                <span class="value">
                  <template v-if="edit_mode=='tac_obj'">
                    <CInputPerc id="tac_obj" name="tac_obj" placeholder="0,00" :readonly="false" v-model.number="cea_data.tac_obj"></CInputPerc>
                    <span class="save-btn" @click="saveCeaObjs('tac_obj')"></span>
                  </template>
                  <template v-else>
                    {{$nicePerc(cea_data.tac_obj)}} <span class="edit-btn" @click="activateEditMode('tac_obj')"></span>
                  </template>
                </span>
              </div>
            </div>
          </div>
          <div class="i-an-c-wrapper" id="conv_chart"></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <p class="section-ot light-blue">Quanto abbiamo interessato</p>
        <div class="an-c-wrapper light-blue">
          <div class="c-heading">
            <div class="row">
              <div class="col-12">
                <span class="label">Interessato</span>
                <span class="value">{{$niceMoney(comp_co_values.calcd_inte_impon_eur)}}</span>
              </div>
            </div>
          </div>
          <div class="i-an-c-wrapper" id="intr_chart"></div>
        </div>
      </div>
    </div>

    <div class="h-values-popup-wrapper" v-if="manage_h_values">
      <div class="box-wrapper">
        <span class="close" @click="hideManageHValues()"></span>
        <h4>Crea / Modifica storico della <strong>CEA {{cea.year}}</strong></h4>
        <div class="form-wrapper">
          <label for="date">Seleziona una delle date per inserire o modificare i dati già presenti:</label>
          <CSelect id="date" name="date" placeholder="Seleziona una CEA" :options="cea_dates" :disabled="false" v-model="h_value.date" @change="updateDefaultHValues()"></CSelect>
          <template v-if="h_value.date">
            <label class="red" for="balance">Consuntivo di bilancio</label>
            <CInputMoney id="balance" name="balance" placeholder="0,00" :readonly="false" v-model.number="h_value.balance"></CInputMoney>
            <label class="yellow" for="ccf">CCF (copertura costi fissi)</label>
            <CInputPerc id="ccf" name="ccf" placeholder="0,00" :readonly="false" v-model.number="h_value.ccf"></CInputPerc>
            <label class="purple" for="commercial">Sforzo commerciale complessivo</label>
            <CInputMoney id="commercial" name="commercial" placeholder="0,00" :readonly="false" v-model.number="h_value.commercial"></CInputMoney>
            <label class="green" for="value">Consolidato nell’anno</label>
            <CInputMoney id="value" name="value" placeholder="0,00" :readonly="false" v-model.number="h_value.value"></CInputMoney>
            <label class="orange" for="mol">MOL</label>
            <CInputPerc id="mol" name="mol" placeholder="0,00" :readonly="false" v-model.number="h_value.mol"></CInputPerc>
            <label class="orange" for="negotiation">Quanto abbiamo in trattativa</label>
            <CInputMoney id="negotiation" name="negotiation" placeholder="0,00" :readonly="false" v-model.number="h_value.negotiation"></CInputMoney>
            <label class="purple" for="avg">Tariffa media</label>
            <CInputMoney id="avg" name="avg" placeholder="0,00" :readonly="false" v-model.number="h_value.avg"></CInputMoney>
            <label class="purple" for="conversion">Tasso di conversione</label>
            <CInputPerc id="conversion" name="conversion" placeholder="0,00" :readonly="false" v-model.number="h_value.conversion"></CInputPerc>
            <label class="light-blue" for="interested">Quanto abbiamo interessato</label>
            <CInputMoney id="interested" name="interested" placeholder="0,00" :readonly="false" v-model.number="h_value.interested"></CInputMoney>
          </template>
        </div>
        <div>
          <span class="save-btn" :class="btn_class" @click="saveHValues()">Salva</span>
        </div>
      </div>
    </div>

    <div class="ctm-banner success" :class="success_banner_class">
      <p>Le modifiche sono state salvate con successo</p>
    </div>
    <div class="ctm-banner error" :class="error_banner_class">
      <p>Si è verificato un problema durante il salvataggio delle modifiche</p>
    </div>
  </div>
</template>

<script>
  import CSelect from '../form/CSelect.vue'
  import CInputPerc from '../form/CInputPerc.vue'
  import CInputMoney from '../form/CInputMoney.vue'

  import * as helperFunctions from "../../helpers/helpers"

  import * as am5 from '@amcharts/amcharts5'
  import * as am5xy from '@amcharts/amcharts5/xy'
  import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
  import am5_it from '@amcharts/amcharts5/locales/it_IT'

  export default {
    components:{
      CSelect,
      CInputPerc,
      CInputMoney,
    },

    props: {
      cea: Object,
      h_values: Array,
      comp_co_values: Object,
    },

    data(){
      return {
        m_h_values: this.h_values,

        manage_h_values: false,

        h_value: {
          date: null,
          balance: null,
          ccf: null,
          commercial: null,
          value: null,
          mol: null,
          negotiation: null,
          avg: null,
          conversion: null,
          interested: null,
        },

        btn_class: '',

        cob_chart: null,
        ccf_chart: null,
        scc_chart: null,
        yco_chart: null,
        mol_chart: null,
        trt_chart: null,
        avgt_chart: null,
        conv_chart: null,
        intr_chart: null,

        charts_created: false,

        cea_data: {
          cdb_obj: (this.cea && this.cea.cdb_obj) ? this.$fromCentToEur(this.cea.cdb_obj) : 0,
          ccf_lim: (this.cea && this.cea.ccf_lim) ? this.cea.ccf_lim : 0,
          cna_obj: (this.cea && this.cea.cna_obj) ? this.$fromCentToEur(this.cea.cna_obj) : 0,
          mol_lim: (this.cea && this.cea.mol_lim) ? this.cea.mol_lim : 0,
          tam_obj: (this.cea && this.cea.tam_obj) ? this.$fromCentToEur(this.cea.tam_obj) : 0,
          tac_obj: (this.cea && this.cea.tac_obj) ? this.cea.tac_obj : 0,
        },

        edit_mode: null,

        success_banner_class: '',
        error_banner_class: '',

        colors: {
          2019: '#981869',
          2020: '#E8B62E',
          2021: '#9B49ED',
          2022: '#42C38C',
          2023: '#FF8B03',
          2024: '#88A9F2',
          2025: '#4F3824',
          2026: '#738290',
          2027: '#738290',
          2028: '#001524',
          2029: '#FAA4BD',
          2030: '#035E7B',
        },
      }
    },

    created(){

    },

    mounted(){
      this.$nextTick(() => {
        this.renderChart('cob_chart');
        this.renderChart('ccf_chart');
        this.renderChart('scc_chart');
        this.renderChart('yco_chart');
        this.renderChart('mol_chart');
        this.renderChart('trt_chart');
        this.renderChart('avgt_chart');
        this.renderChart('conv_chart');
        this.renderChart('intr_chart');
        this.charts_created = true;
      });
    },

    computed: {
      cea_dates: function(){
        return [
          {label: this.$niceCeaDate('01-14-'+this.cea.year), value: 1, r_value: this.cea.year+'-01-14', localized_label: this.$niceCeaDate('01-14-'+this.cea.year)},
          {label: this.$niceCeaDate('01-29-'+this.cea.year), value: 2, r_value: this.cea.year+'-01-29', localized_label: this.$niceCeaDate('01-29-'+this.cea.year)},
          {label: this.$niceCeaDate('02-08-'+this.cea.year), value: 3, r_value: this.cea.year+'-02-08', localized_label: this.$niceCeaDate('02-08-'+this.cea.year)},
          {label: this.$niceCeaDate('02-22-'+this.cea.year), value: 4, r_value: this.cea.year+'-02-22', localized_label: this.$niceCeaDate('02-22-'+this.cea.year)},
          {label: this.$niceCeaDate('03-08-'+this.cea.year), value: 5, r_value: this.cea.year+'-03-08', localized_label: this.$niceCeaDate('03-08-'+this.cea.year)},
          {label: this.$niceCeaDate('03-21-'+this.cea.year), value: 6, r_value: this.cea.year+'-03-21', localized_label: this.$niceCeaDate('03-21-'+this.cea.year)},
          {label: this.$niceCeaDate('04-04-'+this.cea.year), value: 7, r_value: this.cea.year+'-04-04', localized_label: this.$niceCeaDate('04-04-'+this.cea.year)},
          {label: this.$niceCeaDate('04-15-'+this.cea.year), value: 8, r_value: this.cea.year+'-04-15', localized_label: this.$niceCeaDate('04-15-'+this.cea.year)},
          {label: this.$niceCeaDate('05-06-'+this.cea.year), value: 9, r_value: this.cea.year+'-05-06', localized_label: this.$niceCeaDate('05-06-'+this.cea.year)},
          {label: this.$niceCeaDate('05-20-'+this.cea.year), value: 10, r_value: this.cea.year+'-05-20', localized_label: this.$niceCeaDate('05-20-'+this.cea.year)},
          {label: this.$niceCeaDate('06-02-'+this.cea.year), value: 11, r_value: this.cea.year+'-06-02', localized_label: this.$niceCeaDate('06-02-'+this.cea.year)},
          {label: this.$niceCeaDate('06-13-'+this.cea.year), value: 12, r_value: this.cea.year+'-06-13', localized_label: this.$niceCeaDate('06-13-'+this.cea.year)},
          {label: this.$niceCeaDate('06-27-'+this.cea.year), value: 13, r_value: this.cea.year+'-06-27', localized_label: this.$niceCeaDate('06-27-'+this.cea.year)},
          {label: this.$niceCeaDate('07-14-'+this.cea.year), value: 14, r_value: this.cea.year+'-07-14', localized_label: this.$niceCeaDate('07-14-'+this.cea.year)},
          {label: this.$niceCeaDate('07-29-'+this.cea.year), value: 15, r_value: this.cea.year+'-07-29', localized_label: this.$niceCeaDate('07-29-'+this.cea.year)},
          {label: this.$niceCeaDate('08-22-'+this.cea.year), value: 16, r_value: this.cea.year+'-08-22', localized_label: this.$niceCeaDate('08-22-'+this.cea.year)},
          {label: this.$niceCeaDate('09-05-'+this.cea.year), value: 17, r_value: this.cea.year+'-09-05', localized_label: this.$niceCeaDate('09-05-'+this.cea.year)},
          {label: this.$niceCeaDate('09-19-'+this.cea.year), value: 18, r_value: this.cea.year+'-09-19', localized_label: this.$niceCeaDate('09-19-'+this.cea.year)},
          {label: this.$niceCeaDate('10-03-'+this.cea.year), value: 19, r_value: this.cea.year+'-10-03', localized_label: this.$niceCeaDate('10-03-'+this.cea.year)},
          {label: this.$niceCeaDate('10-19-'+this.cea.year), value: 20, r_value: this.cea.year+'-10-19', localized_label: this.$niceCeaDate('10-19-'+this.cea.year)},
          {label: this.$niceCeaDate('11-03-'+this.cea.year), value: 21, r_value: this.cea.year+'-11-03', localized_label: this.$niceCeaDate('11-03-'+this.cea.year)},
          {label: this.$niceCeaDate('11-14-'+this.cea.year), value: 22, r_value: this.cea.year+'-11-14', localized_label: this.$niceCeaDate('11-14-'+this.cea.year)},
          {label: this.$niceCeaDate('11-28-'+this.cea.year), value: 23, r_value: this.cea.year+'-11-28', localized_label: this.$niceCeaDate('11-28-'+this.cea.year)},
          {label: this.$niceCeaDate('12-11-'+this.cea.year), value: 24, r_value: this.cea.year+'-12-11', localized_label: this.$niceCeaDate('12-11-'+this.cea.year)},
          {label: this.$niceCeaDate('12-31-'+this.cea.year), value: 25, r_value: this.cea.year+'-12-31', localized_label: this.$niceCeaDate('12-31-'+this.cea.year)},
        ];
      },
    },

    methods: {
      ...helperFunctions,

      showManageHValues(){
        this.manage_h_values = true;
      },

      hideManageHValues(){
        this.manage_h_values = false;
        this.h_value.date = null;
      },

      updateDefaultHValues(){
        var date = null;
        var f_date_obj = this.cea_dates.filter((obj) => { return obj.value==this.h_value.date; });
        if(f_date_obj && f_date_obj.length>0){
          date = f_date_obj[0].r_value;
        }
        var h_value = this.m_h_values.filter((obj) => { return obj.date==date; });
        this.h_value.balance = h_value && h_value.length>0 ? this.$fromCentToEur(h_value[0].balance) : null;
        this.h_value.ccf = h_value && h_value.length>0 ? this.$stringToFloat(h_value[0].ccf) : null;
        this.h_value.commercial = h_value && h_value.length>0 ? this.$fromCentToEur(h_value[0].commercial) : null;
        this.h_value.value = h_value && h_value.length>0 ? this.$fromCentToEur(h_value[0].value) : null;
        this.h_value.mol = h_value && h_value.length>0 ? this.$stringToFloat(h_value[0].mol) : null;
        this.h_value.negotiation = h_value && h_value.length>0 ? this.$fromCentToEur(h_value[0].negotiation) : null;
        this.h_value.avg = h_value && h_value.length>0 ? this.$fromCentToEur(h_value[0].avg) : null;
        this.h_value.conversion = h_value && h_value.length>0 ? this.$stringToFloat(h_value[0].conversion) : null;
        this.h_value.interested = h_value && h_value.length>0 ? this.$fromCentToEur(h_value[0].interested) : null;
      },

      saveHValues(){
        var date = null;
        var f_date_obj = this.cea_dates.filter((obj) => { return obj.value==this.h_value.date; });
        if(f_date_obj && f_date_obj.length>0){
          date = f_date_obj[0].r_value;
        }
        var params = {
          cea: {
            // Value
              h_value: {
                date: date,
                balance: this.$fromEurToCent(this.h_value.balance),
                ccf: this.h_value.ccf,
                commercial: this.$fromEurToCent(this.h_value.commercial),
                value: this.$fromEurToCent(this.h_value.value),
                mol: this.h_value.mol,
                negotiation: this.$fromEurToCent(this.h_value.negotiation),
                avg: this.$fromEurToCent(this.h_value.avg),
                conversion: this.h_value.conversion,
                interested: this.$fromEurToCent(this.h_value.interested),
              },
          }
        };
        var inst = this;
        return new Promise(resolve => {
          inst.axios.put('/api/ceas/'+inst.cea.id+'/update_h_values', params).then(response => {
            var obj = response.data;
            if(obj.data.success){
              var f_index = null;
              var f_index = this.m_h_values.findIndex(hv_obj => hv_obj.date===date);
              if(f_index>=0){
                this.m_h_values[f_index] = params.cea.h_value
              }
              else{
                this.m_h_values.push(params.cea.h_value);
              }
              this.hideManageHValues();
              inst.success_banner_class = 'shown';
              setTimeout(() => inst.success_banner_class = '', 3000);
              resolve({data: {status: 'Ok', message: 'L\'operazione è stata eseguita con successo'}});
              if(this.charts_created){
                var c_arr = [
                  'cob_chart',
                  'ccf_chart',
                  'scc_chart',
                  'yco_chart',
                  'mol_chart',
                  'trt_chart',
                  'avgt_chart',
                  'conv_chart',
                  'intr_chart',
                ];
                for(var x=0;x<c_arr.length;x++){
                  var id = c_arr[x];
                  am5.array.each(am5.registry.rootElements, function(root){
                    if(root){
                      if(root.dom.id==id){
                        root.dispose();
                      }
                    }
                  });
                }
              }
              this.$nextTick(() => {
                this.renderChart('cob_chart');
                this.renderChart('ccf_chart');
                this.renderChart('scc_chart');
                this.renderChart('yco_chart');
                this.renderChart('mol_chart');
                this.renderChart('trt_chart');
                this.renderChart('avgt_chart');
                this.renderChart('conv_chart');
                this.renderChart('intr_chart');
              });
            }
            else{
              inst.error_banner_class = 'shown';
              setTimeout(() => inst.error_banner_class = '', 3000);
              resolve({data: {status: 'Error', message: 'Si è verificato un errore'}});
            }
          });
        });
      },

      activateEditMode(edit_mode){
        this.edit_mode = edit_mode;
      },

      saveCeaObjs(key){
        var params = {
          cea: {
            // Value
              cdb_obj: this.$fromEurToCent(this.cea_data.cdb_obj),
              ccf_lim: this.cea_data.ccf_lim,
              cna_obj: this.$fromEurToCent(this.cea_data.cna_obj),
              mol_lim: this.cea_data.mol_lim,
              tam_obj: this.$fromEurToCent(this.cea_data.tam_obj),
              tac_obj: this.cea_data.tac_obj,
          }
        };
        var inst = this;
        return new Promise(resolve => {
          inst.axios.put('/api/ceas/'+inst.cea.id+'/update_an_data', params).then(response => {
            var obj = response.data;
            if(obj.data.success){
              this.activateEditMode(null);
              inst.success_banner_class = 'shown';
              setTimeout(() => inst.success_banner_class = '', 3000);
              resolve({data: {status: 'Ok', message: 'L\'operazione è stata eseguita con successo'}});
            }
            else{
              inst.error_banner_class = 'shown';
              setTimeout(() => inst.error_banner_class = '', 3000);
              resolve({data: {status: 'Error', message: 'Si è verificato un errore'}});
            }
          });
        });
      },

      renderChart(type){
        var dates = [
          '01-14',
          '01-29',
          '02-08',
          '02-22',
          '03-08',
          '03-21',
          '04-04',
          '04-15',
          '05-06',
          '05-20',
          '06-02',
          '06-13',
          '06-27',
          '07-14',
          '07-29',
          '08-22',
          '09-05',
          '09-19',
          '10-03',
          '10-19',
          '11-03',
          '11-14',
          '11-28',
          '12-11',
          '12-31',
        ];
        switch(type){
          case 'cob_chart':
            // Setting chart variables
              var cob_chart = am5.Root.new('cob_chart');
              cob_chart.locale = am5_it;
              cob_chart.setThemes([am5themes_Animated.new(cob_chart)]);

              var chart = cob_chart.container.children.push(
                am5xy.XYChart.new(cob_chart, {
                  panX: false,
                  panY: false,
                  paddingTop: 0,
                  paddingRight: 12,
                  paddingBottom: 32,
                  paddingLeft: 0,
                  maxTooltipDistance: 0,
                  layout: cob_chart.verticalLayout
                })
              );

              var xRenderer = am5xy.AxisRendererX.new(cob_chart, {})
              xRenderer.labels.template.set('visible', false);

              var xAxis = chart.xAxes.push(am5xy.DateAxis.new(cob_chart, {
                baseInterval: {
                  timeUnit: 'day',
                  count: 1
                },
                renderer: xRenderer,
              }));

              var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(cob_chart, {
                maxPrecision: 0,
                numberFormat: "'€' #,###",
                renderer: am5xy.AxisRendererY.new(cob_chart, {})
              }));

              var years = [];
              for(var i=2022;i<=this.cea.year;i++){
                years.push(i);
              }

              var data = [];
              for(var i=0;i<dates.length;i++){
                var date = dates[i];
                var obj = {
                  date: new Date('2000-'+date).getTime(),
                };
                for(var x=0;x<years.length;x++){
                  var f_row = this.m_h_values.filter((obj) => { return obj.date==years[x]+'-'+date; });
                  if(f_row && f_row.length>0){
                    obj['value_'+years[x]] = this.$fromCentToEur(f_row[0].balance);
                  }
                  else{
                    obj['value_'+years[x]] = null;
                  }
                }
                data.push(obj);
              }

              function createCobChartSeries(name, color, index){
                var series = chart.series.push(am5xy.LineSeries.new(cob_chart, {
                  name: name,
                  xAxis: xAxis,
                  yAxis: yAxis,
                  valueYField: 'value_'+name,
                  valueXField: 'date',
                  tooltip: am5.Tooltip.new(cob_chart, {
                    labelText: "{name.formatNumber('####')}: {valueY.formatNumber('€ #,###')}"
                  }),
                }));

                series.set('fill', color);
                series.set('stroke', color);

                series.strokes.template.setAll({
                  strokeWidth: 2,
                });

                if(index==years.length-1){
                  series.bullets.push(function() {
                    return am5.Bullet.new(cob_chart, {
                      sprite: am5.Circle.new(cob_chart, {
                        radius: 3,
                        fill: series.get('fill'),
                      })
                    });
                  });
                }

                series.data.setAll(data);
                series.appear(1000);

                return series;
              }

              var series_arr = [];

              for(var i=0;i<years.length;i++){
                var year = years[i];
                var color = am5.color(this.colors[year]);
                series_arr.push(createCobChartSeries(year.toString(), color, i));
              }

              var cursor = chart.set('cursor', am5xy.XYCursor.new(cob_chart, {
                snapToSeries: series_arr,
              }));

              var legend = chart.children.unshift(am5.Legend.new(cob_chart, {
                x: am5.percent(0),
                centerX: am5.percent(0),
                y: am5.percent(0),
                centerY: am5.percent(0),
                marginBottom: 20,
              }));
              legend.data.setAll(chart.series.values);

              this.cob_chart = cob_chart;
            break;
          case 'ccf_chart':
            // Setting chart variables
              var ccf_chart = am5.Root.new('ccf_chart');
              ccf_chart.locale = am5_it;
              ccf_chart.setThemes([am5themes_Animated.new(ccf_chart)]);

              var chart = ccf_chart.container.children.push(
                am5xy.XYChart.new(ccf_chart, {
                  panX: false,
                  panY: false,
                  paddingTop: 0,
                  paddingRight: 12,
                  paddingBottom: 32,
                  paddingLeft: 0,
                  maxTooltipDistance: 0,
                  layout: ccf_chart.verticalLayout
                })
              );

              var xRenderer = am5xy.AxisRendererX.new(ccf_chart, {})
              xRenderer.labels.template.set('visible', false);

              var xAxis = chart.xAxes.push(am5xy.DateAxis.new(ccf_chart, {
                baseInterval: {
                  timeUnit: 'day',
                  count: 1
                },
                renderer: xRenderer,
              }));

              var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(ccf_chart, {
                min: 0,
                maxPrecision: 2,
                numberFormat: "#.#'%'",
                renderer: am5xy.AxisRendererY.new(ccf_chart, {})
              }));

              var years = [];
              for(var i=2022;i<=this.cea.year;i++){
                years.push(i);
              }

              var data = [];
              for(var i=0;i<dates.length;i++){
                var date = dates[i];
                var obj = {
                  date: new Date('2000-'+date).getTime(),
                };
                for(var x=0;x<years.length;x++){
                  var f_row = this.m_h_values.filter((obj) => { return obj.date==years[x]+'-'+date; });
                  if(f_row && f_row.length>0){
                    obj['value_'+years[x]] = parseFloat(f_row[0].ccf);
                  }
                  else{
                    obj['value_'+years[x]] = null;
                  }
                }
                data.push(obj);
              }

              function createCcfChartSeries(name, color, index){
                var series = chart.series.push(am5xy.LineSeries.new(ccf_chart, {
                  name: name,
                  xAxis: xAxis,
                  yAxis: yAxis,
                  valueYField: 'value_'+name,
                  valueXField: 'date',
                  tooltip: am5.Tooltip.new(ccf_chart, {
                    labelText: "{name.formatNumber('####')}: {valueY.formatNumber('#.#')}%"
                  }),
                }));

                series.set('fill', color);
                series.set('stroke', color);

                series.strokes.template.setAll({
                  strokeWidth: 2,
                });

                if(index==years.length-1){
                  series.bullets.push(function() {
                    return am5.Bullet.new(ccf_chart, {
                      sprite: am5.Circle.new(ccf_chart, {
                        radius: 3,
                        fill: series.get('fill'),
                      })
                    });
                  });
                }

                series.data.setAll(data);
                series.appear(1000);

                return series;
              }

              var series_arr = [];

              for(var i=0;i<years.length;i++){
                var year = years[i];
                var color = am5.color(this.colors[year]);
                series_arr.push(createCcfChartSeries(year.toString(), color, i));
              }

              var cursor = chart.set('cursor', am5xy.XYCursor.new(ccf_chart, {
                snapToSeries: series_arr,
              }));

              var legend = chart.children.unshift(am5.Legend.new(ccf_chart, {
                x: am5.percent(0),
                centerX: am5.percent(0),
                y: am5.percent(0),
                centerY: am5.percent(0),
                marginBottom: 20,
              }));
              legend.data.setAll(chart.series.values);

              this.ccf_chart = ccf_chart;
            break;
          case 'scc_chart':
            // Setting chart variables
              var scc_chart = am5.Root.new('scc_chart');
              scc_chart.locale = am5_it;
              scc_chart.setThemes([am5themes_Animated.new(scc_chart)]);

              var chart = scc_chart.container.children.push(
                am5xy.XYChart.new(scc_chart, {
                  panX: false,
                  panY: false,
                  paddingTop: 0,
                  paddingRight: 12,
                  paddingBottom: 32,
                  paddingLeft: 0,
                  maxTooltipDistance: 0,
                  layout: scc_chart.verticalLayout
                })
              );

              var xRenderer = am5xy.AxisRendererX.new(scc_chart, {})
              xRenderer.labels.template.set('visible', false);

              var xAxis = chart.xAxes.push(am5xy.DateAxis.new(scc_chart, {
                baseInterval: {
                  timeUnit: 'day',
                  count: 1
                },
                renderer: xRenderer,
              }));

              var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(scc_chart, {
                maxPrecision: 0,
                numberFormat: "'€' #,###",
                renderer: am5xy.AxisRendererY.new(scc_chart, {})
              }));

              var years = [];
              for(var i=2023;i<=this.cea.year;i++){
                years.push(i);
              }

              var data = [];
              for(var i=0;i<dates.length;i++){
                var date = dates[i];
                var obj = {
                  date: new Date('2000-'+date).getTime(),
                };
                for(var x=0;x<years.length;x++){
                  var f_row = this.m_h_values.filter((obj) => { return obj.date==years[x]+'-'+date; });
                  if(f_row && f_row.length>0){
                    obj['value_'+years[x]] = this.$fromCentToEur(f_row[0].commercial);
                  }
                  else{
                    obj['value_'+years[x]] = null;
                  }
                }
                data.push(obj);
              }

              function createSccChartSeries(name, color, index){
                var series = chart.series.push(am5xy.LineSeries.new(scc_chart, {
                  name: name,
                  xAxis: xAxis,
                  yAxis: yAxis,
                  valueYField: 'value_'+name,
                  valueXField: 'date',
                  tooltip: am5.Tooltip.new(scc_chart, {
                    labelText: "{name.formatNumber('####')}: {valueY.formatNumber('€ #,###')}"
                  }),
                }));

                series.set('fill', color);
                series.set('stroke', color);

                series.strokes.template.setAll({
                  strokeWidth: 2,
                });

                if(index==years.length-1){
                  series.bullets.push(function() {
                    return am5.Bullet.new(scc_chart, {
                      sprite: am5.Circle.new(scc_chart, {
                        radius: 3,
                        fill: series.get('fill'),
                      })
                    });
                  });
                }

                series.data.setAll(data);
                series.appear(1000);

                return series;
              }

              var series_arr = [];

              for(var i=0;i<years.length;i++){
                var year = years[i];
                var color = am5.color(this.colors[year]);
                series_arr.push(createSccChartSeries(year.toString(), color, i));
              }

              var cursor = chart.set('cursor', am5xy.XYCursor.new(scc_chart, {
                snapToSeries: series_arr,
              }));

              var legend = chart.children.unshift(am5.Legend.new(scc_chart, {
                x: am5.percent(0),
                centerX: am5.percent(0),
                y: am5.percent(0),
                centerY: am5.percent(0),
                marginBottom: 20,
              }));
              legend.data.setAll(chart.series.values);

              this.scc_chart = scc_chart;
            break;
          case 'yco_chart':
            // Setting chart variables
              var yco_chart = am5.Root.new('yco_chart');
              yco_chart.locale = am5_it;
              yco_chart.setThemes([am5themes_Animated.new(yco_chart)]);

              var chart = yco_chart.container.children.push(
                am5xy.XYChart.new(yco_chart, {
                  panX: false,
                  panY: false,
                  paddingTop: 0,
                  paddingRight: 12,
                  paddingBottom: 32,
                  paddingLeft: 0,
                  maxTooltipDistance: 0,
                  layout: yco_chart.verticalLayout
                })
              );

              var xRenderer = am5xy.AxisRendererX.new(yco_chart, {})
              xRenderer.labels.template.set('visible', false);

              var xAxis = chart.xAxes.push(am5xy.DateAxis.new(yco_chart, {
                baseInterval: {
                  timeUnit: 'day',
                  count: 1
                },
                renderer: xRenderer,
              }));

              var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(yco_chart, {
                maxPrecision: 0,
                numberFormat: "'€' #,###",
                renderer: am5xy.AxisRendererY.new(yco_chart, {})
              }));

              var years = [];
              for(var i=2019;i<=this.cea.year;i++){
                years.push(i);
              }

              var data = [];
              for(var i=0;i<dates.length;i++){
                var date = dates[i];
                var obj = {
                  date: new Date('2000-'+date).getTime(),
                };
                for(var x=0;x<years.length;x++){
                  var f_row = this.m_h_values.filter((obj) => { return obj.date==years[x]+'-'+date; });
                  if(f_row && f_row.length>0){
                    obj['value_'+years[x]] = this.$fromCentToEur(f_row[0].value);
                  }
                  else{
                    obj['value_'+years[x]] = null;
                  }
                }
                data.push(obj);
              }

              function createYcoChartSeries(name, color, index){
                var series = chart.series.push(am5xy.LineSeries.new(yco_chart, {
                  name: name,
                  xAxis: xAxis,
                  yAxis: yAxis,
                  valueYField: 'value_'+name,
                  valueXField: 'date',
                  tooltip: am5.Tooltip.new(yco_chart, {
                    labelText: "{name.formatNumber('####')}: {valueY.formatNumber('€ #,###')}"
                  }),
                }));

                series.set('fill', color);
                series.set('stroke', color);

                series.strokes.template.setAll({
                  strokeWidth: 2,
                });

                if(index==years.length-1){
                  series.bullets.push(function() {
                    return am5.Bullet.new(yco_chart, {
                      sprite: am5.Circle.new(yco_chart, {
                        radius: 3,
                        fill: series.get('fill'),
                      })
                    });
                  });
                }

                series.data.setAll(data);
                series.appear(1000);

                return series;
              }

              var series_arr = [];

              for(var i=0;i<years.length;i++){
                var year = years[i];
                var color = am5.color(this.colors[year]);
                series_arr.push(createYcoChartSeries(year.toString(), color, i));
              }

              var cursor = chart.set('cursor', am5xy.XYCursor.new(yco_chart, {
                snapToSeries: series_arr,
              }));

              var legend = chart.children.unshift(am5.Legend.new(yco_chart, {
                x: am5.percent(0),
                centerX: am5.percent(0),
                y: am5.percent(0),
                centerY: am5.percent(0),
                marginBottom: 20,
              }));
              legend.data.setAll(chart.series.values);

              this.yco_chart = yco_chart;
            break;
          case 'mol_chart':
            // Setting chart variables
              var mol_chart = am5.Root.new('mol_chart');
              mol_chart.locale = am5_it;
              mol_chart.setThemes([am5themes_Animated.new(mol_chart)]);

              var chart = mol_chart.container.children.push(
                am5xy.XYChart.new(mol_chart, {
                  panX: false,
                  panY: false,
                  paddingTop: 0,
                  paddingRight: 12,
                  paddingBottom: 32,
                  paddingLeft: 0,
                  maxTooltipDistance: 0,
                  layout: mol_chart.verticalLayout
                })
              );

              var xRenderer = am5xy.AxisRendererX.new(mol_chart, {})
              xRenderer.labels.template.set('visible', false);

              var xAxis = chart.xAxes.push(am5xy.DateAxis.new(mol_chart, {
                baseInterval: {
                  timeUnit: 'day',
                  count: 1
                },
                renderer: xRenderer,
              }));

              var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(mol_chart, {
                min: 0,
                maxPrecision: 2,
                numberFormat: "#.#'%'",
                renderer: am5xy.AxisRendererY.new(mol_chart, {})
              }));

              var years = [];
              for(var i=2022;i<=this.cea.year;i++){
                years.push(i);
              }

              var data = [];
              for(var i=0;i<dates.length;i++){
                var date = dates[i];
                var obj = {
                  date: new Date('2000-'+date).getTime(),
                };
                for(var x=0;x<years.length;x++){
                  var f_row = this.m_h_values.filter((obj) => { return obj.date==years[x]+'-'+date; });
                  if(f_row && f_row.length>0){
                    obj['value_'+years[x]] = parseFloat(f_row[0].mol);
                  }
                  else{
                    obj['value_'+years[x]] = null;
                  }
                }
                data.push(obj);
              }

              function createMolChartSeries(name, color, index){
                var series = chart.series.push(am5xy.LineSeries.new(mol_chart, {
                  name: name,
                  xAxis: xAxis,
                  yAxis: yAxis,
                  valueYField: 'value_'+name,
                  valueXField: 'date',
                  tooltip: am5.Tooltip.new(mol_chart, {
                    labelText: "{name.formatNumber('####')}: {valueY.formatNumber('#.#')}%"
                  }),
                }));

                series.set('fill', color);
                series.set('stroke', color);

                series.strokes.template.setAll({
                  strokeWidth: 2,
                });

                if(index==years.length-1){
                  series.bullets.push(function() {
                    return am5.Bullet.new(mol_chart, {
                      sprite: am5.Circle.new(mol_chart, {
                        radius: 3,
                        fill: series.get('fill'),
                      })
                    });
                  });
                }

                series.data.setAll(data);
                series.appear(1000);

                return series;
              }

              var series_arr = [];

              for(var i=0;i<years.length;i++){
                var year = years[i];
                var color = am5.color(this.colors[year]);
                series_arr.push(createMolChartSeries(year.toString(), color, i));
              }

              var cursor = chart.set('cursor', am5xy.XYCursor.new(mol_chart, {
                snapToSeries: series_arr,
              }));

              var legend = chart.children.unshift(am5.Legend.new(mol_chart, {
                x: am5.percent(0),
                centerX: am5.percent(0),
                y: am5.percent(0),
                centerY: am5.percent(0),
                marginBottom: 20,
              }));
              legend.data.setAll(chart.series.values);

              this.mol_chart = mol_chart;
            break;
          case 'trt_chart':
            // Setting chart variables
              var trt_chart = am5.Root.new('trt_chart');
              trt_chart.locale = am5_it;
              trt_chart.setThemes([am5themes_Animated.new(trt_chart)]);

              var chart = trt_chart.container.children.push(
                am5xy.XYChart.new(trt_chart, {
                  panX: false,
                  panY: false,
                  paddingTop: 0,
                  paddingRight: 12,
                  paddingBottom: 32,
                  paddingLeft: 0,
                  maxTooltipDistance: 0,
                  layout: trt_chart.verticalLayout
                })
              );

              var xRenderer = am5xy.AxisRendererX.new(trt_chart, {})
              xRenderer.labels.template.set('visible', false);

              var xAxis = chart.xAxes.push(am5xy.DateAxis.new(trt_chart, {
                baseInterval: {
                  timeUnit: 'day',
                  count: 1
                },
                renderer: xRenderer,
              }));

              var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(trt_chart, {
                maxPrecision: 0,
                numberFormat: "'€' #,###",
                renderer: am5xy.AxisRendererY.new(trt_chart, {})
              }));

              var years = [];
              for(var i=2021;i<=this.cea.year;i++){
                years.push(i);
              }

              var data = [];
              for(var i=0;i<dates.length;i++){
                var date = dates[i];
                var obj = {
                  date: new Date('2000-'+date).getTime(),
                };
                for(var x=0;x<years.length;x++){
                  var f_row = this.m_h_values.filter((obj) => { return obj.date==years[x]+'-'+date; });
                  if(f_row && f_row.length>0){
                    obj['value_'+years[x]] = this.$fromCentToEur(f_row[0].negotiation);
                  }
                  else{
                    obj['value_'+years[x]] = null;
                  }
                }
                data.push(obj);
              }

              function createTrtChartSeries(name, color, index){
                var series = chart.series.push(am5xy.LineSeries.new(trt_chart, {
                  name: name,
                  xAxis: xAxis,
                  yAxis: yAxis,
                  valueYField: 'value_'+name,
                  valueXField: 'date',
                  tooltip: am5.Tooltip.new(trt_chart, {
                    labelText: "{name.formatNumber('####')}: {valueY.formatNumber('€ #,###')}"
                  }),
                }));

                series.set('fill', color);
                series.set('stroke', color);

                series.strokes.template.setAll({
                  strokeWidth: 2,
                });

                if(index==years.length-1){
                  series.bullets.push(function() {
                    return am5.Bullet.new(trt_chart, {
                      sprite: am5.Circle.new(trt_chart, {
                        radius: 3,
                        fill: series.get('fill'),
                      })
                    });
                  });
                }

                series.data.setAll(data);
                series.appear(1000);

                return series;
              }

              var series_arr = [];

              for(var i=0;i<years.length;i++){
                var year = years[i];
                var color = am5.color(this.colors[year]);
                series_arr.push(createTrtChartSeries(year.toString(), color, i));
              }

              var cursor = chart.set('cursor', am5xy.XYCursor.new(trt_chart, {
                snapToSeries: series_arr,
              }));

              var legend = chart.children.unshift(am5.Legend.new(trt_chart, {
                x: am5.percent(0),
                centerX: am5.percent(0),
                y: am5.percent(0),
                centerY: am5.percent(0),
                marginBottom: 20,
              }));
              legend.data.setAll(chart.series.values);

              this.trt_chart = trt_chart;
            break;
          case 'avgt_chart':
            // Setting chart variables
              var avgt_chart = am5.Root.new('avgt_chart');
              avgt_chart.locale = am5_it;
              avgt_chart.setThemes([am5themes_Animated.new(avgt_chart)]);

              var chart = avgt_chart.container.children.push(
                am5xy.XYChart.new(avgt_chart, {
                  panX: false,
                  panY: false,
                  paddingTop: 0,
                  paddingRight: 12,
                  paddingBottom: 32,
                  paddingLeft: 0,
                  maxTooltipDistance: 0,
                  layout: avgt_chart.verticalLayout
                })
              );

              var xRenderer = am5xy.AxisRendererX.new(avgt_chart, {})
              xRenderer.labels.template.set('visible', false);

              var xAxis = chart.xAxes.push(am5xy.DateAxis.new(avgt_chart, {
                baseInterval: {
                  timeUnit: 'day',
                  count: 1
                },
                renderer: xRenderer,
              }));

              var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(avgt_chart, {
                maxPrecision: 0,
                numberFormat: "'€' #,###",
                renderer: am5xy.AxisRendererY.new(avgt_chart, {})
              }));

              var years = [];
              for(var i=2023;i<=this.cea.year;i++){
                years.push(i);
              }

              var data = [];
              for(var i=0;i<dates.length;i++){
                var date = dates[i];
                var obj = {
                  date: new Date('2000-'+date).getTime(),
                };
                for(var x=0;x<years.length;x++){
                  var f_row = this.m_h_values.filter((obj) => { return obj.date==years[x]+'-'+date; });
                  if(f_row && f_row.length>0){
                    obj['value_'+years[x]] = this.$fromCentToEur(f_row[0].avg);
                  }
                  else{
                    obj['value_'+years[x]] = null;
                  }
                }
                data.push(obj);
              }

              function createAvgtChartSeries(name, color, index){
                var series = chart.series.push(am5xy.LineSeries.new(avgt_chart, {
                  name: name,
                  xAxis: xAxis,
                  yAxis: yAxis,
                  valueYField: 'value_'+name,
                  valueXField: 'date',
                  tooltip: am5.Tooltip.new(avgt_chart, {
                    labelText: "{name.formatNumber('####')}: {valueY.formatNumber('€ #,###')}"
                  }),
                }));

                series.set('fill', color);
                series.set('stroke', color);

                series.strokes.template.setAll({
                  strokeWidth: 2,
                });

                if(index==years.length-1){
                  series.bullets.push(function() {
                    return am5.Bullet.new(avgt_chart, {
                      sprite: am5.Circle.new(avgt_chart, {
                        radius: 3,
                        fill: series.get('fill'),
                      })
                    });
                  });
                }

                series.data.setAll(data);
                series.appear(1000);

                return series;
              }

              var series_arr = [];

              for(var i=0;i<years.length;i++){
                var year = years[i];
                var color = am5.color(this.colors[year]);
                series_arr.push(createAvgtChartSeries(year.toString(), color, i));
              }

              var cursor = chart.set('cursor', am5xy.XYCursor.new(avgt_chart, {
                snapToSeries: series_arr,
              }));

              var legend = chart.children.unshift(am5.Legend.new(avgt_chart, {
                x: am5.percent(0),
                centerX: am5.percent(0),
                y: am5.percent(0),
                centerY: am5.percent(0),
                marginBottom: 20,
              }));
              legend.data.setAll(chart.series.values);

              this.avgt_chart = avgt_chart;
            break;
          case 'conv_chart':
            // Setting chart variables
              var conv_chart = am5.Root.new('conv_chart');
              conv_chart.locale = am5_it;
              conv_chart.setThemes([am5themes_Animated.new(conv_chart)]);

              var chart = conv_chart.container.children.push(
                am5xy.XYChart.new(conv_chart, {
                  panX: false,
                  panY: false,
                  paddingTop: 0,
                  paddingRight: 12,
                  paddingBottom: 32,
                  paddingLeft: 0,
                  maxTooltipDistance: 0,
                  layout: conv_chart.verticalLayout
                })
              );

              var xRenderer = am5xy.AxisRendererX.new(conv_chart, {})
              xRenderer.labels.template.set('visible', false);

              var xAxis = chart.xAxes.push(am5xy.DateAxis.new(conv_chart, {
                baseInterval: {
                  timeUnit: 'day',
                  count: 1
                },
                renderer: xRenderer,
              }));

              var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(conv_chart, {
                min: 0,
                maxPrecision: 2,
                numberFormat: "#.#'%'",
                renderer: am5xy.AxisRendererY.new(conv_chart, {})
              }));

              var years = [];
              for(var i=2023;i<=this.cea.year;i++){
                years.push(i);
              }

              var data = [];
              for(var i=0;i<dates.length;i++){
                var date = dates[i];
                var obj = {
                  date: new Date('2000-'+date).getTime(),
                };
                for(var x=0;x<years.length;x++){
                  var f_row = this.m_h_values.filter((obj) => { return obj.date==years[x]+'-'+date; });
                  if(f_row && f_row.length>0){
                    obj['value_'+years[x]] = parseFloat(f_row[0].conversion);
                  }
                  else{
                    obj['value_'+years[x]] = null;
                  }
                }
                data.push(obj);
              }

              function createConvChartSeries(name, color, index){
                var series = chart.series.push(am5xy.LineSeries.new(conv_chart, {
                  name: name,
                  xAxis: xAxis,
                  yAxis: yAxis,
                  valueYField: 'value_'+name,
                  valueXField: 'date',
                  tooltip: am5.Tooltip.new(conv_chart, {
                    labelText: "{name.formatNumber('####')}: {valueY.formatNumber('#.#')}%"
                  }),
                }));

                series.set('fill', color);
                series.set('stroke', color);

                series.strokes.template.setAll({
                  strokeWidth: 2,
                });

                if(index==years.length-1){
                  series.bullets.push(function() {
                    return am5.Bullet.new(conv_chart, {
                      sprite: am5.Circle.new(conv_chart, {
                        radius: 3,
                        fill: series.get('fill'),
                      })
                    });
                  });
                }

                series.data.setAll(data);
                series.appear(1000);

                return series;
              }

              var series_arr = [];

              for(var i=0;i<years.length;i++){
                var year = years[i];
                var color = am5.color(this.colors[year]);
                series_arr.push(createConvChartSeries(year.toString(), color, i));
              }

              var cursor = chart.set('cursor', am5xy.XYCursor.new(conv_chart, {
                snapToSeries: series_arr,
              }));

              var legend = chart.children.unshift(am5.Legend.new(conv_chart, {
                x: am5.percent(0),
                centerX: am5.percent(0),
                y: am5.percent(0),
                centerY: am5.percent(0),
                marginBottom: 20,
              }));
              legend.data.setAll(chart.series.values);

              this.conv_chart = conv_chart;
            break;
          case 'intr_chart':
            // Setting chart variables
              var intr_chart = am5.Root.new('intr_chart');
              intr_chart.locale = am5_it;
              intr_chart.setThemes([am5themes_Animated.new(intr_chart)]);

              var chart = intr_chart.container.children.push(
                am5xy.XYChart.new(intr_chart, {
                  panX: false,
                  panY: false,
                  paddingTop: 0,
                  paddingRight: 12,
                  paddingBottom: 32,
                  paddingLeft: 0,
                  maxTooltipDistance: 0,
                  layout: intr_chart.verticalLayout
                })
              );

              var xRenderer = am5xy.AxisRendererX.new(intr_chart, {})
              xRenderer.labels.template.set('visible', false);

              var xAxis = chart.xAxes.push(am5xy.DateAxis.new(intr_chart, {
                baseInterval: {
                  timeUnit: 'day',
                  count: 1
                },
                renderer: xRenderer,
              }));

              var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(intr_chart, {
                maxPrecision: 0,
                numberFormat: "'€' #,###",
                renderer: am5xy.AxisRendererY.new(intr_chart, {})
              }));

              var years = [];
              for(var i=2021;i<=this.cea.year;i++){
                years.push(i);
              }

              var data = [];
              for(var i=0;i<dates.length;i++){
                var date = dates[i];
                var obj = {
                  date: new Date('2000-'+date).getTime(),
                };
                for(var x=0;x<years.length;x++){
                  var f_row = this.m_h_values.filter((obj) => { return obj.date==years[x]+'-'+date; });
                  if(f_row && f_row.length>0){
                    obj['value_'+years[x]] = this.$fromCentToEur(f_row[0].interested);
                  }
                  else{
                    obj['value_'+years[x]] = null;
                  }
                }
                data.push(obj);
              }

              function createIntrChartSeries(name, color, index){
                var series = chart.series.push(am5xy.LineSeries.new(intr_chart, {
                  name: name,
                  xAxis: xAxis,
                  yAxis: yAxis,
                  valueYField: 'value_'+name,
                  valueXField: 'date',
                  tooltip: am5.Tooltip.new(intr_chart, {
                    labelText: "{name.formatNumber('####')}: {valueY.formatNumber('€ #,###')}"
                  }),
                }));

                series.set('fill', color);
                series.set('stroke', color);

                series.strokes.template.setAll({
                  strokeWidth: 2,
                });

                if(index==years.length-1){
                  series.bullets.push(function() {
                    return am5.Bullet.new(intr_chart, {
                      sprite: am5.Circle.new(intr_chart, {
                        radius: 3,
                        fill: series.get('fill'),
                      })
                    });
                  });
                }

                series.data.setAll(data);
                series.appear(1000);

                return series;
              }

              var series_arr = [];

              for(var i=0;i<years.length;i++){
                var year = years[i];
                var color = am5.color(this.colors[year]);
                series_arr.push(createIntrChartSeries(year.toString(), color, i));
              }

              var cursor = chart.set('cursor', am5xy.XYCursor.new(intr_chart, {
                snapToSeries: series_arr,
              }));

              var legend = chart.children.unshift(am5.Legend.new(intr_chart, {
                x: am5.percent(0),
                centerX: am5.percent(0),
                y: am5.percent(0),
                centerY: am5.percent(0),
                marginBottom: 20,
              }));
              legend.data.setAll(chart.series.values);

              this.intr_chart = intr_chart;
            break;
          default:break;
        }
      },
    }
  }
</script>