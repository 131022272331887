<template>
  <div class="edit-project-code-wrapper">
    <span class="icon" @click="toggleBoxShown()"></span>
    <div class="overlay" v-if="box_shown">
      <div class="box-wrapper">
        <span class="close" @click="toggleBoxShown()"></span>
        <h4>Inserisci il nuovo codice di progetto</h4>
        <div class="form-wrapper">
          <CInput id="project_code" name="project_code" placeholder="COD." :readonly="false" v-model="cep_data.project_code"></CInput>
        </div>
        <template v-if="required_fields_error && required_fields_error.length>0">
          <p class="required-fields">Completa tutti i campi obbligatori per procedere</p>
        </template>
        <template v-if="save_error">
          <p class="required-fields">Si è verificato un errore</p>
        </template>
        <template v-if="wrong_project_code_format">
          <p class="required-fields">Il codice progetto deve iniziare con "<strong>{{client.code}}.</strong>"</p>
        </template>
        <template v-if="existing_project_code_format">
          <p class="required-fields">Il codice progetto inserito è già stato utilizzato</p>
        </template>
        <template v-if="regex_project_code_format">
          <p class="required-fields">Il codice progetto inserito non è formattato correttamente</p>
        </template>
        <span class="save-btn" :class="btn_class" @click="editCepProjectCode()">Salva</span>
      </div>
    </div>

    <div class="ctm-banner success" :class="success_banner_class">
      <p>Le modifiche sono state salvate con successo</p>
    </div>
    <div class="ctm-banner error" :class="error_banner_class">
      <p>Si è verificato un problema durante il salvataggio delle modifiche</p>
    </div>
  </div>
</template>

<script>
  import CInput from "../form/CInput.vue"

  import * as helperFunctions from "../../helpers/helpers"

  export default {
    components:{
      CInput,
    },

    props: {
      current_user: Object,
      cep: Object,
      client: Object,
      project_codes: Array,
    },

    data(){
      return {
        box_shown: false,

        cep_data: {
          project_code: this.cep ? this.cep.project_code : null,
        },

        required_fields: ['project_code'],
        required_fields_error: [],

        wrong_project_code_format: false,
        existing_project_code_format: false,
        regex_project_code_format: false,

        save_error: false,

        btn_class: '',
        success_banner_class: '',
        error_banner_class: '',
      }
    },

    methods: {
      ...helperFunctions,

      toggleBoxShown(){
        this.box_shown = !this.box_shown;
      },

      allRequiredFieldsAreComplete(){
        var flag = true;
        var required_fields_error = [];
        this.required_fields.forEach((obj_key) => {
          switch(obj_key){
            case 'project_code':
              if(!this.cep_data[obj_key] || this.cep_data[obj_key]==''){
                flag = false;
                required_fields_error.push(obj_key);
              }
              else{
                var client_code = this.client.code + '.';
                if(!this.cep_data[obj_key].startsWith(client_code)){
                  flag = false;
                  this.wrong_project_code_format = true;
                }
                else{
                  this.wrong_project_code_format = false;
                }
                if(this.project_codes.includes(this.cep_data[obj_key]) && this.cep_data.project_code!=this.cep.project_code){
                  flag = false;
                  this.existing_project_code_format = true;
                }
                else{
                  this.existing_project_code_format = false;
                }
                var regex = /^(.*){3,}\.(.*){3,}\.\d{3,}$/;
                if(!regex.test(this.cep_data[obj_key])){
                  flag = false;
                  this.regex_project_code_format = true;
                }
                else{
                  this.regex_project_code_format = false;
                }
              }
              break;
            default:break;
          }
        });
        this.required_fields_error = required_fields_error;
        return flag;
      },

      editCepProjectCode(){
        if(this.allRequiredFieldsAreComplete()){
          this.btn_class = 'loading';
          var params = {
            cep: {
              // Comp section
                comp_section: 'pc',
              // In
                project_code: this.cep_data.project_code,
            }
          };
          var inst = this;
          return new Promise(resolve => {
            inst.axios.put('/api/ceps/'+inst.cep.id, params).then(response => {
              var obj = response.data;
              if(obj.data.success){
                inst.btn_class = '';
                inst.toggleBoxShown();
                inst.success_banner_class = 'shown';
                setTimeout(() => location.reload(), 1000);
                resolve({data: {status: 'Ok', message: 'L\'operazione è stata eseguita con successo'}});
              }
              else{
                inst.btn_class = '';
                inst.toggleBoxShown();
                inst.error_banner_class = 'shown';
                setTimeout(() => inst.error_banner_class = '', 3000);
                resolve({data: {status: 'Error', message: 'Si è verificato un errore'}});
              }
            });
          });
        }
      },
    }
  }
</script>