<template>
  <div class="custom-input-search">
    <input :id="id" :name="name" type="text" :placeholder="placeholder" :value="modelValue" :readonly="readonly" autofocus @input="emitValue">
  </div>
</template>

<script>
  export default {
    props: {
      id: String,
      name: String,
      placeholder: String,
      readonly: Boolean,
      modelValue: String,
    },

    data(){
      return {

      }
    },

    methods: {
      emitValue($event){
        var emitted_value = $event.target.value!=="" ? $event.target.value : null;
        this.$emit('update:modelValue', emitted_value);
      },
    }
  }
</script>
