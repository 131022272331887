<template>
  <div>
    <div class="row">
      <div class="col-12">
        <p class="section-ot">Informazioni generali</p>
        <div class="form-box-wrapper grey">
          <div class="row">
            <div class="col-12 col-md-8">
              <template v-if="cep_data.scenery_id">
                <div class="col-12">
                  <div class="heading">
                    <div class="row align-items-center">
                      <div class="col-12">
                        <p>Scenario</p>
                      </div>
                    </div>
                  </div>
                  <div class="value-row">
                    <div class="row">
                      <div class="col-12 col-md-4">
                        <label for="project_code">Codice</label>
                        <span class="visual-value">{{$findObjInArray(sceneries, 'id', cep_data.scenery_id).code}}</span>
                      </div>
                      <div class="col-12 col-md-8">
                        <label for="project_code">Descrizione</label>
                        <span class="visual-value">{{$findObjInArray(sceneries, 'id', cep_data.scenery_id).desc}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <div class="heading" :class="cep_data.scenery_id ? 'wmt' : ''">
                <div class="row align-items-center">
                  <div class="col-12">
                    <p>Gestione progetto</p>
                  </div>
                </div>
              </div>
              <div class="value-row">
                <div class="row">
                  <template v-if="!cep_data.scenery_id || (cep_data.scenery_id && $findObjInArray(sceneries, 'id', cep_data.scenery_id).ea_visible)">
                    <div class="col-12 col-md-4">
                      <label for="ea_id">EA</label>
                      <template v-if="cep_data.ea_id">
                        <span class="visual-value">{{$findObjInArray(eas, 'value', cep_data.ea_id).localized_label}}</span>
                      </template>
                      <template v-else>
                        <span class="visual-value">-</span>
                      </template>
                    </div>
                  </template>
                  <template v-if="!cep_data.scenery_id || (cep_data.scenery_id && $findObjInArray(sceneries, 'id', cep_data.scenery_id).pl_visible)">
                    <div class="col-12 col-md-4">
                      <label for="pl_id">PL</label>
                      <template v-if="cep_data.pl_id">
                        <span class="visual-value">{{$findObjInArray(pls, 'value', cep_data.pl_id).localized_label}}</span>
                      </template>
                      <template v-else>
                        <span class="visual-value">-</span>
                      </template>
                    </div>
                  </template>
                  <template v-if="!cep_data.scenery_id || (cep_data.scenery_id && $findObjInArray(sceneries, 'id', cep_data.scenery_id).pc_visible)">
                    <div class="col-12 col-md-4">
                      <label for="pc_id">PC</label>
                      <template v-if="cep_data.pc_id">
                        <span class="visual-value">{{$findObjInArray(pcs, 'value', cep_data.pc_id).localized_label}}</span>
                      </template>
                      <template v-else>
                        <span class="visual-value">-</span>
                      </template>
                    </div>
                  </template>
                </div>
              </div>
              <div class="heading wmt">
                <div class="row align-items-center">
                  <div class="col-12">
                    <p>Altre informazioni</p>
                  </div>
                </div>
              </div>
              <div class="value-row">
                <div class="row">
                  <div class="col-12 col-md-4">
                    <label for="status">Stato</label>
                    <span class="visual-value status" :class="cep_data.status">{{$getEnumLabel(cep_statuses, cep_data.status)}}</span>
                  </div>
                  <div class="col-12 col-md-4">
                    <label for="interloc">Livello interlocutore</label>
                    <span class="visual-value">{{$getEnumLabel(cep_interlocs, cep_data.interloc)}}</span>
                  </div>
                  <div class="col-12 col-md-4" v-if="!cep.drive_url">
                    <div class="create-drive-folder-btn-wrapper">
                      <span class="create-drive-folder-btn" :class="create_drive_folder_btn_class" @click="createDriveFolder()">Crea cartella su Drive</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="status-btn-wrapper">
                      <template v-if="cep_data.status=='draft' && can_change_status">
                        <span class="status-btn" @click="showChangeStatus(1)">Interessato</span>
                      </template>
                      <template v-if="cep_data.status=='interessato' && can_change_status">
                        <span class="status-btn" @click="showChangeStatus(2)">In trattativa</span>
                        <span class="status-btn neg" @click="showChangeStatus(5)">Perso</span>
                      </template>
                      <template v-if="cep_data.status=='trattativa' && can_change_status">
                        <span class="status-btn" @click="showChangeStatus(3)">Consolidato</span>
                        <span class="status-btn neg" @click="showChangeStatus(5)">Perso</span>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <template v-if="quote_file || cep.motivation">
                <div class="heading wmt">
                  <div class="row align-items-center">
                    <div class="col-12">
                      <p>Documenti e motivazione</p>
                    </div>
                  </div>
                </div>
                <div class="value-row">
                  <div class="row">
                    <template v-if="quote_file">
                      <div class="col-12 col-md-4">
                        <label for="file_url">Documento allegato</label>
                        <span class="visual-value oe"><a :href="quote_file.file_url" target="_blank">{{quote_file.file_name}}</a></span>
                      </div>
                    </template>
                    <template v-if="cep.motivation">
                      <div class="col-12 col-md-4">
                        <label for="motivation">Motivazione</label>
                        <span class="visual-value">{{cep.motivation}}</span>
                      </div>
                    </template>
                  </div>
                </div>
              </template>
            </div>
            <div class="col-12 col-md-4">
              <div class="ctm-heading">
                <div class="row align-items-center">
                  <div class="col-12">
                    <p>Informazioni cliente</p>
                  </div>
                </div>
              </div>
              <div class="value-row">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <label for="name">Nome cliente</label>
                    <span class="visual-value">{{$niceObjField($findObjInArray(clients, 'id', cep_data.client_id), 'name')}}</span>
                  </div>
                  <div class="col-12 col-md-6">
                    <label for="code">Codice</label>
                    <span class="visual-value">{{$niceObjField($findObjInArray(clients, 'id', cep_data.client_id), 'code')}}</span>
                  </div>
                </div>
              </div>
              <template v-if="cep_bill_info">
                <div class="ctm-heading">
                  <div class="row align-items-center">
                    <div class="col-12">
                      <p class="itm">Informazioni di fatturazione</p>
                    </div>
                  </div>
                </div>
                <div class="value-row">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <label for="name">Denominazione azienda</label>
                      <span class="visual-value">{{$niceObjField(cep_bill_info, 'business_name')}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <label for="vat_code">P.Iva</label>
                      <span class="visual-value">{{$niceObjField(cep_bill_info, 'vat_code')}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <label for="address">Indirizzo</label>
                      <span class="visual-value">{{$niceObjField(cep_bill_info, 'address')}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <label for="sdi">SDI</label>
                      <span class="visual-value">{{$niceObjField(cep_bill_info, 'sdi')}}</span>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="cep_contact">
                <div class="ctm-heading">
                  <div class="row align-items-center">
                    <div class="col-12">
                      <p class="itm">Informazioni contatto</p>
                    </div>
                  </div>
                </div>
                <div class="value-row">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <label for="name">Nome</label>
                      <span class="visual-value">{{$niceObjField(cep_contact, 'name')}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <label for="telephone">Telefono</label>
                      <span class="visual-value">{{$niceObjField(cep_contact, 'telephone')}}</span>
                    </div>
                    <div class="col-12">
                      <label for="email">E-mail</label>
                      <span class="visual-value">{{$niceObjField(cep_contact, 'email')}}</span>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="change-status-popup-wrapper" v-if="status_change_to">
      <div class="box-wrapper">
        <span class="close" @click="resetChangeStatus()"></span>
        <h4>Cambia stato in <strong>{{cep_statuses[status_change_to].localized_label}}</strong></h4>
        <template v-if="status_change_to==1">
          <p>Questa operazione comporterà la notifica del cambio di stato a tutti e l'inserimento di questo CEP nella CEA. <br>Sei sicuro di voler procedere?</p>
          <div class="form-wrapper">
            <label for="cea_id">Seleziona la CEA in cui inserire questo CEP</label>
            <CSelect id="cea_id" name="cea_id" placeholder="Seleziona una CEA" :options="ceas_options" :disabled="false" v-model.number="change_status_data.cea_id"></CSelect>
          </div>
        </template>
        <template v-if="status_change_to==2">
          <p>Questa operazione comporterà la notifica del cambio di stato a tutti e l'aggiornamento di questo CEP nella CEA. <br>Sei sicuro di voler procedere?</p>
        </template>
        <template v-if="status_change_to==3">
          <p>Questa operazione comporterà la notifica del cambio di stato a tutti e l'aggiornamento di questo CEP nella CEA. <br>Sei sicuro di voler procedere?</p>
          <div class="form-wrapper">
            <label for="quote_file">Carica il documento di conferma ricevuto dal cliente (PDF)</label>
            <CInputFile id="quote_file" name="quote_file" placeholder="..." accept="application/pdf" v-model="change_status_data.quote_file"></CInputFile>
            <label for="motivation">Specifica la motivazione</label>
            <CTextarea id="motivation" name="motivation" placeholder="..." :readonly="false" v-model="change_status_data.motivation"></CTextarea>
          </div>
        </template>
        <template v-if="status_change_to==5">
          <p>Questa operazione comporterà la notifica del cambio di stato a tutti e l'aggiornamento di questo CEP nella CEA. <br>Sei sicuro di voler procedere?</p>
          <div class="form-wrapper">
            <label for="motivation">Specifica la motivazione</label>
            <CTextarea id="motivation" name="motivation" placeholder="..." :readonly="false" v-model="change_status_data.motivation"></CTextarea>
          </div>
        </template>
        <div>
          <span class="save-btn" :class="btn_class" @click="changeStatus()">Procedi</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CSelect from '../form/CSelect.vue'
  import CTextarea from '../form/CTextarea.vue'
  import CInputFile from '../form/CInputFile.vue'

  import * as helperFunctions from "../../helpers/helpers"

  export default {
    components:{
      CSelect,
      CTextarea,
      CInputFile,
    },

    props: {
      can_change_status: Boolean,
      cep: Object,
      quote_file: Object,
      users: Array,
      sceneries: Array,
      clients: Array,
      cep_statuses: Array,
      cep_interlocs: Array,
      cep_contact: Object,
      cep_bill_info: Object,
      ceas: Array,
    },

    data(){
      return {
        cep_data: {
          scenery_id: this.cep.scenery_id,
          ea_id: this.cep.ea_id,
          pl_id: this.cep.pl_id,
          pc_id: this.cep.pc_id,
          client_id: this.cep.client_id,
          project_code: this.cep.project_code,
          status: this.cep.status,
          interloc: this.cep.interloc,
        },

        change_status_data: {
          status: this.cep.status,
          cea_id: null,
          quote_file: null,
          motivation: null,
        },

        btn_class: '',
        status_change_to: null,

        create_drive_folder_btn_class: '',
      }
    },

    computed: {
      eas: function(){
        return this.users.filter((item) => { return item.is_ea; }).map((item) => { return {label: this.$niceUserFullName(item), value: item.id, localized_label: this.$niceUserFullName(item)}; });
      },
      pls: function(){
        return this.users.filter((item) => { return item.is_pl; }).map((item) => { return {label: this.$niceUserFullName(item), value: item.id, localized_label: this.$niceUserFullName(item)}; });
      },
      pcs: function(){
        return this.users.filter((item) => { return item.is_pc; }).map((item) => { return {label: this.$niceUserFullName(item), value: item.id, localized_label: this.$niceUserFullName(item)}; });
      },
      ceas_options: function(){
        return this.ceas.map((item) => { return {label: item.year, value: item.id, localized_label: item.year}; });
      },
    },

    methods: {
      ...helperFunctions,

      resetChangeStatus(){
        this.status_change_to = null;
      },

      showChangeStatus(status){
        this.status_change_to = status;
      },

      changeStatus(){
        if(this.status_change_to==1 && !this.change_status_data.cea_id){
          alert('Seleziona una CEA');
        }
        else{
          this.btn_class = 'loading';
          var params = new FormData();
          params.append('cep[status]', this.status_change_to);
          if(this.change_status_data.quote_file){
            params.append('cep[quote_file]', this.change_status_data.quote_file);
          }
          if(this.change_status_data.motivation){
            params.append('cep[motivation]', this.change_status_data.motivation);
          }
          if(this.change_status_data.cea_id){
            params.append('cep[cea_id]', this.change_status_data.cea_id);
          }
          this.axios.put('/api/ceps/'+this.cep.id+'/update_status', params, {headers: {'Content-Type': 'multipart/form-data'}}).then(response => {
            var obj = response.data;
            if(obj.data.success){
              window.location.href = '/ceps/'+obj.data.cep_id;
            }
            else{
              this.btn_class = '';
              console.log(obj);
            }
          });
        }
      },

      createDriveFolder(){
        this.create_drive_folder_btn_class = 'loading';
        this.axios.put('/api/ceps/'+this.cep.id+'/create_drive_folder').then(response => {
          var obj = response.data;
          if(obj.data.success){
            location.reload();
          }
          else{
            this.create_drive_folder_btn_class = '';
            console.log(obj);
          }
        });
      },
    }
  }
</script>