<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-8">
        <p class="section-ot">Dettaglio costi diretti</p>
        <div class="form-box-wrapper purple" :class="expanded_boxes.includes('sales_01') ? 'expanded' : ''">
          <span class="expand-btn" @click="toggleExpandBox('sales_01')"></span>
          <div class="heading">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p><strong>0.1</strong> Sales <br><span>Fee commerciali standard</span></p>
              </div>
              <div class="col-12 col-md-4">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceMoney(cep.calcd_01_tot_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceIntPerc(cep.calcd_01_tot_perc)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="expanded_boxes.includes('sales_01')">
            <hr>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="calcd_01_prezzo_senza">Prezzo senza 0.1, 2.4 e 2.5</label>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(cep.calcd_01_prezzo_senza)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="calcd_01_fee_comm">Fee commerciale</label>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(cep.calcd_01_fee_comm)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_01_new_sell_ext_who_partner_id">New sell esterno (Chiunque tranne EA di progetto o Twig)</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$niceObjField($findObjInArray(new_sell_partners, 'value', cep.in_01_new_sell_ext_who_partner_id), 'localized_label')}}</span>
                    </div>
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$getEnumLabel(item_general_kinds, cep.in_01_new_sell_ext_kind)}}</span>
                    </div>
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$nicePerc(cep.in_01_new_sell_ext_perc)}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(cep.calcd_01_new_sell_ext_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(cep.calcd_01_new_sell_tot_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_01_new_sell_oth_who_id">New sell interno (Altri: chiunque di Twig tranne EA di progetto)</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$niceObjField($findObjInArray(new_sell_oth_who_list, 'value', cep.in_01_new_sell_oth_who_id), 'localized_label')}}</span>
                    </div>
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$getEnumLabel(item_general_kinds, cep.in_01_new_sell_oth_kind)}}</span>
                    </div>
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$nicePerc(cep.in_01_new_sell_oth_perc)}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(cep.calcd_01_new_sell_oth_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_01_new_sell_int_who_pl_id">New sell interno (EA: quando è anche EA di progetto)</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$niceObjField($findObjInArray(eas, 'value', cep.in_01_new_sell_int_who_pl_id), 'localized_label')}}</span>
                    </div>
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$getEnumLabel(item_general_kinds, cep.in_01_new_sell_int_kind)}}</span>
                    </div>
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$nicePerc(cep.in_01_new_sell_int_perc)}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(cep.calcd_01_new_sell_int_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_01_upsell_int_who_pl_id">Upsell interno (EA/PL)</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$niceObjField($findObjInArray(null_or_ea, 'value', cep.in_01_upsell_int_who_pl_id), 'localized_label')}}</span>
                    </div>
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$getEnumLabel(item_general_kinds, cep.in_01_upsell_int_kind)}}</span>
                    </div>
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$nicePerc(cep.in_01_upsell_int_perc)}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(cep.calcd_01_upsell_int_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6"></div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="form-box-wrapper purple" :class="expanded_boxes.includes('sales_02') ? 'expanded' : ''">
          <span class="expand-btn" @click="toggleExpandBox('sales_02')"></span>
          <div class="heading">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p><strong>0.2</strong> Sales <br><span>Plusvalore per partner</span></p>
              </div>
              <div class="col-12 col-md-4">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceMoney(cep.calcd_02_extra_margine_tot_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceIntPerc(cep.calcd_02_extra_margine_tot_perc)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="expanded_boxes.includes('sales_02')">
            <hr>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_02_extra_margine_partner_who_partner_id">Extra-margine per partner</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$niceObjField($findObjInArray(extra_margine_partners, 'value', cep.in_02_extra_margine_partner_who_partner_id), 'localized_label')}}</span>
                    </div>
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$getEnumLabel(item_general_kinds, cep.in_02_extra_margine_partner_kind)}}</span>
                    </div>
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$niceMoney(cep.in_02_extra_margine_partner_eur)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="form-box-wrapper purple" :class="expanded_boxes.includes('sales_03') ? 'expanded' : ''">
          <span class="expand-btn" @click="toggleExpandBox('sales_03')"></span>
          <div class="heading">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p><strong>0.3</strong> Sales <br><span>Extra-margine</span></p>
              </div>
              <div class="col-12 col-md-4">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceMoney(cep.calcd_03_extra_margine_bonus_tot_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceIntPerc(cep.calcd_03_extra_margine_bonus_tot_perc)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="expanded_boxes.includes('sales_03')">
            <hr>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_03_extra_margine_bonus_who_user_id">Bonus 25% sull'extra-margine (EA)</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$niceObjField($findObjInArray(null_or_ea, 'value', cep.in_03_extra_margine_bonus_who_user_id), 'localized_label')}}</span>
                    </div>
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$getEnumLabel(item_general_kinds, cep.in_03_extra_margine_bonus_kind)}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(cep.calcd_03_extra_margine_bonus_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6"></div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="form-box-wrapper light-blue" :class="expanded_boxes.includes('direzione_11') ? 'expanded' : ''">
          <span class="expand-btn" @click="toggleExpandBox('direzione_11')"></span>
          <div class="heading">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p><strong>1.1</strong> Direzione <br><span>Executive Advisor</span></p>
              </div>
              <div class="col-12 col-md-4">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceMoney(cep.calcd_11_direzione_tot_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceIntPerc(cep.calcd_11_direzione_tot_perc)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="expanded_boxes.includes('direzione_11')">
            <hr>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_11_direzione_team_fisso_kind">Team fisso</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$getEnumLabel(item_general_kinds, cep.in_11_direzione_team_fisso_kind)}}</span>
                    </div>
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$nicePerc(cep.in_11_direzione_team_fisso_input_perc)}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(cep.calcd_11_direzione_team_fisso_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(cep.calcd_11_direzione_team_fisso_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_11_direzione_team_piva_kind">Team a p.iva</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$getEnumLabel(item_general_kinds, cep.in_11_direzione_team_piva_kind)}}</span>
                    </div>
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$niceIntPerc(cep.in_11_direzione_team_piva_input_perc)}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(cep.calcd_11_direzione_team_piva_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(cep.calcd_11_direzione_team_piva_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_11_direzione_fornit_kind">Fornitori</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$getEnumLabel(item_general_kinds, cep.in_11_direzione_fornit_kind)}}</span>
                    </div>
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$niceIntPerc(cep.in_11_direzione_fornit_input_perc)}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(cep.calcd_11_direzione_fornit_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(cep.calcd_11_direzione_fornit_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_11_direzione_consul_kind">CT - Consulenza</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$getEnumLabel(item_general_kinds, cep.in_11_direzione_consul_kind)}}</span>
                    </div>
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$niceMoney(cep.in_11_direzione_consul_input_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$niceNumber(cep.in_11_direzione_consul_input_h_qty)}} h</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(cep.calcd_11_direzione_consul_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(cep.calcd_11_direzione_consul_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="form-box-wrapper green" :class="expanded_boxes.includes('produzione_21') ? 'expanded' : ''">
          <span class="expand-btn" @click="toggleExpandBox('produzione_21')"></span>
          <div class="heading">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p><strong>2.1</strong> Produzione <br><span>Team a stipendio</span></p>
              </div>
              <div class="col-12 col-md-4">
                <div class="row">
                  <div class="col-12 col-md-4">
                    <span class="value">{{$niceNumber(cep.calcd_21_prod_team_stip_tot_h)}} h</span>
                  </div>
                  <div class="col-12 col-md-4">
                    <span class="value">{{$niceMoney(cep.calcd_21_prod_team_stip_tot_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-4">
                    <span class="value">{{$niceIntPerc(cep.calcd_21_prod_team_stip_tot_perc)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="expanded_boxes.includes('produzione_21')">
            <template v-if="cep_lines.prod_team_stip_lines && cep_lines.prod_team_stip_lines.length>0">
              <hr>
              <template v-for="(prod_team_stip_line, index) in cep_lines.prod_team_stip_lines">
                <div class="value-row">
                  <div class="row align-items-center">
                    <div class="col-12 col-md-8">
                      <template v-if="index==0">
                        <label for="in_21_prod_team_stip_act_type">Attività</label>
                      </template>
                      <div class="row">
                        <div class="col-12 col-md-4">
                          <span class="visual-value">{{$niceField(prod_team_stip_line.in_21_prod_team_stip_act_type)}}</span>
                        </div>
                        <div class="col-12 col-md-4">
                          <span class="visual-value">{{$niceField(prod_team_stip_line.in_21_prod_team_stip_who_str)}}</span>
                        </div>
                        <div class="col-12 col-md-4">
                          <span class="visual-value">{{$niceField(prod_team_stip_line.in_21_prod_team_stip_kind)}}</span>
                        </div>
                        <div class="col-12 col-md-4">
                          <span class="visual-value">{{$niceMoney(prod_team_stip_line.in_21_prod_team_stip_h_cost_eur)}}</span>
                        </div>
                        <div class="col-12 col-md-4">
                          <span class="visual-value">{{$niceNumber(prod_team_stip_line.in_21_prod_team_stip_h_qty)}} h</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <div class="row">
                        <div class="col-12 col-md-4"></div>
                        <div class="col-12 col-md-4">
                          <span class="value">{{$niceMoney(prod_team_stip_line.calcd_21_prod_team_stip_eur)}}</span>
                        </div>
                        <div class="col-12 col-md-4">
                          <span class="value">{{$niceIntPerc(prod_team_stip_line.calcd_21_prod_team_stip_perc)}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </template>
        </div>
        <div class="form-box-wrapper green" :class="expanded_boxes.includes('produzione_22') ? 'expanded' : ''">
          <span class="expand-btn" @click="toggleExpandBox('produzione_22')"></span>
          <div class="heading">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p><strong>2.2</strong> Produzione <br><span>Team a p.iva</span></p>
              </div>
              <div class="col-12 col-md-4">
                <div class="row">
                  <div class="col-12 col-md-4">
                    <span class="value">{{$niceNumber(cep.calcd_22_prod_team_piva_tot_h)}} h</span>
                  </div>
                  <div class="col-12 col-md-4">
                    <span class="value">{{$niceMoney(cep.calcd_22_prod_team_piva_tot_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-4">
                    <span class="value">{{$niceIntPerc(cep.calcd_22_prod_team_piva_tot_perc)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="expanded_boxes.includes('produzione_22')">
            <template v-if="cep_lines.prod_team_piva_lines && cep_lines.prod_team_piva_lines.length>0">
              <hr>
              <template v-for="(prod_team_piva_line, index) in cep_lines.prod_team_piva_lines">
                <div class="value-row">
                  <div class="row align-items-center">
                    <div class="col-12 col-md-8">
                      <template v-if="index==0">
                        <label for="in_22_prod_team_piva_act_type">Attività</label>
                      </template>
                      <div class="row">
                        <div class="col-12 col-md-4">
                          <span class="visual-value">{{$niceField(prod_team_piva_line.in_22_prod_team_piva_act_type)}}</span>
                        </div>
                        <div class="col-12 col-md-4">
                          <span class="visual-value">{{$findObjInArray(prod_team_piva_users, 'value', prod_team_piva_line.in_22_prod_team_piva_who_user_id).localized_label}}</span>
                        </div>
                        <div class="col-12 col-md-4">
                          <span class="visual-value">{{$niceField(prod_team_piva_line.in_22_prod_team_piva_kind)}}</span>
                        </div>
                        <div class="col-12 col-md-4">
                          <span class="visual-value">{{$niceMoney(prod_team_piva_line.in_22_prod_team_piva_h_cost_eur)}}</span>
                        </div>
                        <div class="col-12 col-md-4">
                          <span class="visual-value">{{$niceNumber(prod_team_piva_line.in_22_prod_team_piva_h_qty)}} h</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <div class="row">
                        <div class="col-12 col-md-4"></div>
                        <div class="col-12 col-md-4">
                          <span class="value">{{$niceMoney(prod_team_piva_line.calcd_22_prod_team_piva_eur)}}</span>
                        </div>
                        <div class="col-12 col-md-4">
                          <span class="value">{{$niceIntPerc(prod_team_piva_line.calcd_22_prod_team_piva_perc)}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </template>
        </div>
        <div class="form-box-wrapper green" :class="expanded_boxes.includes('produzione_23') ? 'expanded' : ''">
          <span class="expand-btn" @click="toggleExpandBox('produzione_23')"></span>
          <div class="heading">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p><strong>2.3</strong> Produzione <br><span>Fornitori a p.iva</span></p>
              </div>
              <div class="col-12 col-md-4">
                <div class="row">
                  <div class="col-12 col-md-4">
                  </div>
                  <div class="col-12 col-md-4">
                    <span class="value">{{$niceMoney(cep.calcd_23_prod_fornit_piva_tot_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-4">
                    <span class="value">{{$niceIntPerc(cep.calcd_23_prod_fornit_piva_tot_perc)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="expanded_boxes.includes('produzione_23')">
            <template v-if="cep_lines.prod_fornit_piva_lines && cep_lines.prod_fornit_piva_lines.length>0">
              <hr>
              <template v-for="(prod_fornit_piva_line, index) in cep_lines.prod_fornit_piva_lines">
                <div class="value-row">
                  <div class="row align-items-center">
                    <div class="col-12 col-md-8">
                      <template v-if="index==0">
                        <label for="in_23_prod_fornit_piva_act_desc">Attività</label>
                      </template>
                      <div class="row">
                        <div class="col-12 col-md-3">
                          <span class="visual-value">{{$niceField(prod_fornit_piva_line.in_23_prod_fornit_piva_act_desc)}}</span>
                        </div>
                        <div class="col-12 col-md-3">
                          <span class="visual-value">{{$niceField(prod_fornit_piva_line.in_23_prod_fornit_piva_who_str)}}</span>
                        </div>
                        <div class="col-12 col-md-3">
                          <span class="visual-value">{{$niceField(prod_fornit_piva_line.in_23_prod_fornit_piva_kind)}}</span>
                        </div>
                        <div class="col-12 col-md-3">
                          <span class="visual-value">{{$niceMoney(prod_fornit_piva_line.in_23_prod_fornit_piva_eur)}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <div class="row">
                        <div class="col-12 col-md-4"></div>
                        <div class="col-12 col-md-4"></div>
                        <div class="col-12 col-md-4">
                          <span class="value">{{$niceIntPerc(prod_fornit_piva_line.calcd_23_prod_fornit_piva_perc)}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </template>
        </div>
        <div class="form-box-wrapper green" :class="expanded_boxes.includes('produzione_24') ? 'expanded' : ''">
          <span class="expand-btn" @click="toggleExpandBox('produzione_24')"></span>
          <div class="heading">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p><strong>2.4</strong> Produzione <br><span>Spazi media</span></p>
              </div>
              <div class="col-12 col-md-4">
                <div class="row">
                  <div class="col-12 col-md-4">
                  </div>
                  <div class="col-12 col-md-4">
                    <span class="value">{{$niceMoney(cep.calcd_24_prod_media_tot_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-4">
                    <span class="value">{{$niceIntPerc(cep.calcd_24_prod_media_tot_perc)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="expanded_boxes.includes('produzione_24')">
            <template v-if="cep_lines.prod_media_lines && cep_lines.prod_media_lines.length>0">
              <hr>
              <template v-for="(prod_media_line, index) in cep_lines.prod_media_lines">
                <div class="value-row">
                  <div class="row align-items-center">
                    <div class="col-12 col-md-8">
                      <template v-if="index==0">
                        <label for="in_24_prod_media_desc">Attività</label>
                      </template>
                      <div class="row">
                        <div class="col-12 col-md-3">
                          <span class="visual-value">{{$niceField(prod_media_line.in_24_prod_media_desc)}}</span>
                        </div>
                        <div class="col-12 col-md-3">
                          <span class="visual-value">{{$niceField(prod_media_line.in_24_prod_media_desc1)}}</span>
                        </div>
                        <div class="col-12 col-md-3">
                          <span class="visual-value">{{$niceField(prod_media_line.in_24_prod_media_kind)}}</span>
                        </div>
                        <div class="col-12 col-md-3">
                          <span class="visual-value">{{$niceMoney(prod_media_line.in_24_prod_media_eur)}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <div class="row">
                        <div class="col-12 col-md-4"></div>
                        <div class="col-12 col-md-4"></div>
                        <div class="col-12 col-md-4">
                          <span class="value">{{$niceIntPerc(prod_media_line.calcd_24_prod_media_perc)}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </template>
        </div>
        <div class="form-box-wrapper green" :class="expanded_boxes.includes('produzione_25') ? 'expanded' : ''">
          <span class="expand-btn" @click="toggleExpandBox('produzione_25')"></span>
          <div class="heading">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p><strong>2.5</strong> Produzione <br><span>Altri</span></p>
              </div>
              <div class="col-12 col-md-4">
                <div class="row">
                  <div class="col-12 col-md-4">
                  </div>
                  <div class="col-12 col-md-4">
                    <span class="value">{{$niceMoney(cep.calcd_25_prod_others_tot_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-4">
                    <span class="value">{{$niceIntPerc(cep.calcd_25_prod_others_tot_perc)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="expanded_boxes.includes('produzione_25')">
            <template v-if="cep_lines.prod_others_lines && cep_lines.prod_others_lines.length>0">
              <hr>
              <template v-for="(prod_others_line, index) in cep_lines.prod_others_lines">
                <div class="value-row">
                  <div class="row align-items-center">
                    <div class="col-12 col-md-8">
                      <template v-if="index==0">
                        <label for="in_25_prod_others_type">Attività</label>
                      </template>
                      <div class="row">
                        <div class="col-12 col-md-3">
                          <span class="visual-value">{{$niceField(prod_others_line.in_25_prod_others_type)}}</span>
                        </div>
                        <div class="col-12 col-md-3">
                          <span class="visual-value">{{$niceField(prod_others_line.in_25_prod_others_desc)}}</span>
                        </div>
                        <div class="col-12 col-md-3">
                          <span class="visual-value">{{$niceField(prod_others_line.in_25_prod_others_kind)}}</span>
                        </div>
                        <div class="col-12 col-md-3">
                          <span class="visual-value">{{$niceMoney(prod_others_line.in_25_prod_others_eur)}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <div class="row">
                        <div class="col-12 col-md-4"></div>
                        <div class="col-12 col-md-4"></div>
                        <div class="col-12 col-md-4">
                          <span class="value">{{$niceIntPerc(prod_others_line.calcd_25_prod_others_perc)}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </template>
        </div>
        <p class="section-ot">Dettaglio costi indiretti</p>
        <div class="form-box-wrapper blue" :class="expanded_boxes.includes('gestione_12') ? 'expanded' : ''">
          <span class="expand-btn" @click="toggleExpandBox('gestione_12')"></span>
          <div class="heading">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p class="ns"><strong>1.2</strong> Gestione</p>
              </div>
              <div class="col-12 col-md-4">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceMoney(cep.calcd_12_gest_tot_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceIntPerc(cep.calcd_12_gest_tot_perc)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="expanded_boxes.includes('gestione_12')">
            <hr>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_11_direzione_team_fisso_kind">Allocazione team fisso/p.iva</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$getEnumLabel(item_general_kinds, cep.in_12_gest_alloc_fisso_piva_kind)}}</span>
                    </div>
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$nicePerc(cep.in_12_gest_alloc_fisso_piva_input_perc)}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(cep.calcd_12_gest_alloc_fisso_piva_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(cep.calcd_12_gest_alloc_fisso_piva_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_11_direzione_team_fisso_kind">Supervisione fornitori</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$getEnumLabel(item_general_kinds, cep.in_12_gest_superv_fornit_kind)}}</span>
                    </div>
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$nicePerc(cep.in_12_gest_superv_fornit_input_perc)}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(cep.calcd_12_gest_superv_fornit_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(cep.calcd_12_gest_superv_fornit_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_11_direzione_team_fisso_kind">Supervisione produzioni/eventi</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$getEnumLabel(item_general_kinds, cep.in_12_gest_superv_event_kind)}}</span>
                    </div>
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$niceMoney(cep.in_12_gest_superv_event_eur)}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6"></div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(cep.calcd_12_gest_superv_event_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_11_direzione_team_fisso_kind">Compensazione da plusvalenza</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$getEnumLabel(item_general_kinds, cep.in_12_gest_comp_plus_kind)}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(cep.calcd_12_gest_comp_plus_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(cep.calcd_12_gest_comp_plus_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="form-box-wrapper yellow" :class="expanded_boxes.includes('struttura_30') ? 'expanded' : ''">
          <span class="expand-btn" @click="toggleExpandBox('struttura_30')"></span>
          <div class="heading">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p class="ns"><strong>3.0</strong> Struttura</p>
              </div>
              <div class="col-12 col-md-4">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceMoney(cep.calcd_3_struct_tot_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceIntPerc(cep.calcd_3_struct_tot_perc)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="expanded_boxes.includes('struttura_30')">
            <hr>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_11_direzione_team_fisso_kind">Direzione aziendale</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$getEnumLabel(item_general_kinds, cep.in_3_struct_dir_az_kind)}}</span>
                    </div>
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$nicePerc(cep.in_3_struct_dir_az_input_perc)}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(cep.calcd_3_struct_dir_az_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(cep.calcd_3_struct_dir_az_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_11_direzione_team_fisso_kind">Sede operativa e servizi</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$getEnumLabel(item_general_kinds, cep.in_3_struct_sede_kind)}}</span>
                    </div>
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$nicePerc(cep.in_3_struct_sede_input_perc)}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(cep.calcd_3_struct_sede_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(cep.calcd_3_struct_sede_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_11_direzione_team_fisso_kind">Costi di rappresentanza</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$getEnumLabel(item_general_kinds, cep.in_3_struct_rappr_kind)}}</span>
                    </div>
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$nicePerc(cep.in_3_struct_rappr_input_perc)}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(cep.calcd_3_struct_rappr_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(cep.calcd_3_struct_rappr_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_11_direzione_team_fisso_kind">Anticipi finanziari</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$getEnumLabel(item_general_kinds, cep.in_3_struct_ant_fin_kind)}}</span>
                    </div>
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$nicePerc(cep.in_3_struct_ant_fin_input_perc)}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(cep.calcd_3_struct_ant_fin_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(cep.calcd_3_struct_ant_fin_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_11_direzione_team_fisso_kind">Compensazione da plusvalenza</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$getEnumLabel(item_general_kinds, cep.in_3_struct_comp_plus_kind)}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(cep.calcd_3_struct_comp_plus_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(cep.calcd_3_struct_comp_plus_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="form-box-wrapper red" :class="expanded_boxes.includes('brand_40') ? 'expanded' : ''">
          <span class="expand-btn" @click="toggleExpandBox('brand_40')"></span>
          <div class="heading">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p class="ns"><strong>4.0</strong> Brand</p>
              </div>
              <div class="col-12 col-md-4">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceMoney(cep.calcd_4_brand_tot_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceIntPerc(cep.calcd_4_brand_tot_perc)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="expanded_boxes.includes('brand_40')">
            <hr>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_11_direzione_team_fisso_kind">Coordinamento delle vendite</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$getEnumLabel(item_general_kinds, cep.in_4_brand_coo_sales_kind)}}</span>
                    </div>
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$nicePerc(cep.in_4_brand_coo_sales_input_perc)}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(cep.calcd_4_brand_coo_sales_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(cep.calcd_4_brand_coo_sales_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_11_direzione_team_fisso_kind">Coordinamento della comunicazione</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$getEnumLabel(item_general_kinds, cep.in_4_brand_coo_com_kind)}}</span>
                    </div>
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$nicePerc(cep.in_4_brand_coo_com_input_perc)}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(cep.calcd_4_brand_coo_com_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(cep.calcd_4_brand_coo_com_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_11_direzione_team_fisso_kind">Compensazione da plusvalenza</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$getEnumLabel(item_general_kinds, cep.in_4_brand_comp_plus_kind)}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(cep.calcd_4_brand_comp_plus_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(cep.calcd_4_brand_comp_plus_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <p class="section-ot">Margine</p>
        <div class="form-box-wrapper orange" :class="expanded_boxes.includes('margine_50') ? 'expanded' : ''">
          <span class="expand-btn" @click="toggleExpandBox('margine_50')"></span>
          <div class="heading">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p class="ns"><strong>5.0</strong> Margine</p>
              </div>
              <div class="col-12 col-md-4"></div>
            </div>
          </div>
          <template v-if="expanded_boxes.includes('margine_50')">
            <hr>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_mar_extra_margine_eur">Extra-Margine</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$niceMoney(cep.in_mar_extra_margine_eur)}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6"></div>
                    <div class="col-12 col-md-6"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_11_direzione_team_fisso_kind">Bonus plusvalenza all'EA</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$getEnumLabel(item_general_kinds, cep.in_mar_bonus_plus_kind)}}</span>
                    </div>
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$nicePerc(cep.in_mar_bonus_plus_perc)}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(cep.calcd_mar_bonus_plus_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_mar_acc_gest_kind">Accantonamento per gestione (1.2)</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$getEnumLabel(item_general_kinds, cep.in_mar_acc_gest_kind)}}</span>
                    </div>
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$nicePerc(cep.in_mar_acc_gest_perc)}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(cep.calcd_mar_acc_gest_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_mar_acc_struct_kind">Accantonamento per struttura (3)</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$getEnumLabel(item_general_kinds, cep.in_mar_acc_struct_kind)}}</span>
                    </div>
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$nicePerc(cep.in_mar_acc_struct_perc)}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(cep.calcd_mar_acc_struct_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_mar_acc_brand_kind">Accantonamento per brand (4)</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$getEnumLabel(item_general_kinds, cep.in_mar_acc_brand_kind)}}</span>
                    </div>
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$nicePerc(cep.in_mar_acc_brand_perc)}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(cep.calcd_mar_acc_brand_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6"></div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="form-box-wrapper orange" :class="expanded_boxes.includes('margine_51') ? 'expanded' : ''">
          <span class="expand-btn" @click="toggleExpandBox('margine_51')"></span>
          <div class="heading">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p><strong>5.1</strong> Margine <br><span>Attività</span></p>
              </div>
              <div class="col-12 col-md-4">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceMoney(cep.calcd_51_mar_tot_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceIntPerc(cep.calcd_51_mar_tot_perc)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="expanded_boxes.includes('margine_51')">
            <hr>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_51_mar_team_fisso_kind">Margine su team fisso</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$getEnumLabel(item_general_kinds, cep.in_51_mar_team_fisso_kind)}}</span>
                    </div>
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$nicePerc(cep.in_51_mar_fisso_input_perc)}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(cep.calcd_51_mar_team_fisso_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(cep.calcd_51_mar_team_fisso_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_51_mar_team_piva_kind">Margine su team a p.iva</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$getEnumLabel(item_general_kinds, cep.in_51_mar_team_piva_kind)}}</span>
                    </div>
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$nicePerc(cep.in_51_mar_team_piva_input_perc)}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(cep.calcd_51_mar_team_piva_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(cep.calcd_51_mar_team_piva_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_51_mar_fornit_kind">Margine su fornitori</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$getEnumLabel(item_general_kinds, cep.in_51_mar_fornit_kind)}}</span>
                    </div>
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$nicePerc(cep.in_51_mar_fornit_input_perc)}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(cep.calcd_51_mar_fornit_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(cep.calcd_51_mar_fornit_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="form-box-wrapper orange" :class="expanded_boxes.includes('margine_53') ? 'expanded' : ''">
          <span class="expand-btn" @click="toggleExpandBox('margine_53')"></span>
          <div class="heading">
            <div class="row align-items-center">
              <div class="col-12 col-md-8">
                <p><strong>5.3</strong> Margine <br><span>Fee commerciale</span></p>
              </div>
              <div class="col-12 col-md-4">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceMoney(cep.calcd_53_mar_fee_tot_eur)}}</span>
                  </div>
                  <div class="col-12 col-md-6">
                    <span class="value">{{$niceIntPerc(cep.calcd_53_mar_fee_tot_perc)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="expanded_boxes.includes('margine_53')">
            <hr>
            <div class="value-row">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <label for="in_53_mar_fee_comm_kind">Margine su fee commerciale</label>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <span class="visual-value">{{$getEnumLabel(item_general_kinds, cep.in_53_mar_fee_comm_kind)}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceMoney(cep.calcd_53_mar_fee_comm_eur)}}</span>
                    </div>
                    <div class="col-12 col-md-6">
                      <span class="value">{{$niceIntPerc(cep.calcd_53_mar_fee_comm_perc)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="recap-box-wrapper">
          <h3 class="rpb">Composizione per soggetti produttivi</h3>
          <div class="custom-perc-chart-title">
            <h4>Media dei progetti</h4>
          </div>
          <div>
            <div class="custom-perc-chart-wrapper half avg" :style="'width:calc('+$roundOneDecimal(avg_calcd_csp_fissi_tot_perc)+'% - 6px)'">
              <span class="fissi-a" :style="'width:'+getRelativePerc('avg_fissi', avg_values.avg_fissi_prod_int_tot_perc)+'%'"></span>
              <span class="fissi-b" :style="'width:'+getRelativePerc('avg_fissi', avg_values.avg_fissi_mol_tot_perc)+'%'"></span>
            </div>
            <div class="custom-perc-chart-wrapper half avg" :style="'width:calc('+$roundOneDecimal(avg_calcd_csp_var_tot_perc)+'% - 6px)'">
              <span class="variabili-c" :style="'width:'+getRelativePerc('avg_variabili', avg_values.avg_var_segnal_tot_perc)+'%'"></span>
              <span class="variabili-d" :style="'width:'+getRelativePerc('avg_variabili', avg_values.avg_var_ea_tot_perc)+'%'"></span>
              <span class="variabili-e" :style="'width:'+getRelativePerc('avg_variabili', avg_values.avg_var_prod_int_piva_tot_perc)+'%'"></span>
              <span class="variabili-f" :style="'width:'+getRelativePerc('avg_variabili', avg_values.avg_var_prod_est_piva_tot_perc)+'%'"></span>
              <span class="variabili-g" :style="'width:'+getRelativePerc('avg_variabili', avg_values.avg_var_prod_other_est_perc)+'%'"></span>
            </div>
          </div>
          <div>
            <div class="custom-perc-chart-wrapper half" :style="'width:calc('+$roundOneDecimal(cep.calcd_csp_fissi_tot_perc)+'% - 6px)'">
              <span class="fissi-a" :style="'width:'+getRelativePerc('fissi', cep.calcd_csp_fissi_prod_int_tot_perc)+'%'">A</span>
              <span class="fissi-b" :style="'width:'+getRelativePerc('fissi', cep.calcd_csp_fissi_mol_tot_perc)+'%'">B</span>
            </div>
            <div class="custom-perc-chart-wrapper half" :style="'width:calc('+$roundOneDecimal(cep.calcd_csp_var_tot_perc)+'% - 6px)'">
              <span class="variabili-c" :style="'width:'+getRelativePerc('variabili', cep.calcd_csp_var_segnal_tot_perc)+'%'">C</span>
              <span class="variabili-d" :style="'width:'+getRelativePerc('variabili', cep.calcd_csp_var_ea_tot_perc)+'%'">D</span>
              <span class="variabili-e" :style="'width:'+getRelativePerc('variabili', cep.calcd_csp_var_prod_int_piva_tot_perc)+'%'">E</span>
              <span class="variabili-f" :style="'width:'+getRelativePerc('variabili', cep.calcd_csp_var_prod_est_piva_tot_perc)+'%'">F</span>
              <span class="variabili-g" :style="'width:'+getRelativePerc('variabili', cep.calcd_csp_var_prod_other_est_perc)+'%'">G</span>
            </div>
          </div>
          <div class="custom-perc-chart-wrapper">
            <span class="fissi" :style="'width:'+cep.calcd_csp_fissi_tot_perc+'%'">
              <span class="tooltip">{{$niceIntPerc(cep.calcd_csp_fissi_tot_perc)}}</span>
            </span>
            <span class="variabili" :style="'width:'+cep.calcd_csp_var_tot_perc+'%'">
              <span class="tooltip">{{$niceIntPerc(cep.calcd_csp_var_tot_perc)}}</span>
            </span>
          </div>
          <div class="custom-chart-legend-wrapper">
            <h4>Fissi<span class="fissi"></span></h4>
            <div class="values-row">
              <span class="label">A. Produzione interna</span>
              <span class="hour-value">{{$niceNumber(cep.calcd_csp_fissi_prod_int_tot_h)}}h</span>
              <span class="eur-value">{{$niceMoney(cep.calcd_csp_fissi_prod_int_tot_eur)}}</span>
              <span class="perc-value fissi">{{$niceIntPerc(cep.calcd_csp_fissi_prod_int_tot_perc)}}</span>
            </div>
            <div class="values-row">
              <span class="label">B. MOL</span>
              <span class="hour-value"></span>
              <span class="eur-value">{{$niceMoney(cep.calcd_csp_fissi_mol_tot_eur)}}</span>
              <span class="perc-value fissi">{{$niceIntPerc(cep.calcd_csp_fissi_mol_tot_perc)}}</span>
            </div>
            <h4>Variabili<span class="variabili"></span></h4>
            <div class="values-row">
              <span class="label">C. Segnalatori</span>
              <span class="hour-value"></span>
              <span class="eur-value">{{$niceMoney(cep.calcd_csp_var_segnal_tot_eur)}}</span>
              <span class="perc-value variabili">{{$niceIntPerc(cep.calcd_csp_var_segnal_tot_perc)}}</span>
            </div>
            <div class="values-row">
              <span class="label">D. Executive Advisor</span>
              <span class="hour-value"></span>
              <span class="eur-value">{{$niceMoney(cep.calcd_csp_var_ea_tot_eur)}}</span>
              <span class="perc-value variabili">{{$niceIntPerc(cep.calcd_csp_var_ea_tot_perc)}}</span>
            </div>
            <div class="values-row">
              <span class="label">E. Produzione interna p.iva</span>
              <span class="hour-value">{{$niceNumber(cep.calcd_csp_var_prod_piva_tot_h)}}h</span>
              <span class="eur-value">{{$niceMoney(cep.calcd_csp_var_prod_int_piva_tot_eur)}}</span>
              <span class="perc-value variabili">{{$niceIntPerc(cep.calcd_csp_var_prod_int_piva_tot_perc)}}</span>
            </div>
            <div class="values-row">
              <span class="label">F. Produzione esterna p.iva</span>
              <span class="hour-value"></span>
              <span class="eur-value">{{$niceMoney(cep.calcd_csp_var_prod_est_piva_tot_eur)}}</span>
              <span class="perc-value variabili">{{$niceIntPerc(cep.calcd_csp_var_prod_est_piva_tot_perc)}}</span>
            </div>
            <div class="values-row">
              <span class="label">G. Altri costi di produzione esterna</span>
              <span class="hour-value"></span>
              <span class="eur-value">{{$niceMoney(cep.calcd_csp_var_prod_other_est_eur)}}</span>
              <span class="perc-value variabili">{{$niceIntPerc(cep.calcd_csp_var_prod_other_est_perc)}}</span>
            </div>
          </div>
          <hr>
          <h3>Composizione per voci di bilancio</h3>
          <div class="outer-chart-wrapper">
            <div class="pie-chart-wrapper" ref="pie_chart"></div>
            <span class="chart-big-value">{{$niceMoney(cep.calcd_prezzo_vendita_tot_eur)}}</span>
          </div>
          <div class="chart-legend-wrapper">
            <div>
              <span class="label comm">Costi commerciali</span>
              <span class="eur-value">{{$niceMoney(cep.calcd_cvb_comm_tot_eur)}}</span>
              <span class="perc-value comm">{{$niceIntPerc(cep.calcd_cvb_comm_tot_perc)}}</span>
            </div>
            <div>
              <span class="label dir">Costi di direzione</span>
              <span class="eur-value">{{$niceMoney(cep.calcd_cvb_dir_tot_eur)}}</span>
              <span class="perc-value dir">{{$niceIntPerc(cep.calcd_cvb_dir_tot_perc)}}</span>
            </div>
            <div>
              <span class="label prod">Costi di produzione</span>
              <span class="eur-value">{{$niceMoney(cep.calcd_cvb_prod_tot_eur)}}</span>
              <span class="perc-value prod">{{$niceIntPerc(cep.calcd_cvb_prod_tot_perc)}}</span>
            </div>
            <div>
              <span class="label mol">MOL</span>
              <span class="eur-value">{{$niceMoney(cep.calcd_cvb_mol_tot_eur)}}</span>
              <span class="perc-value mol">{{$niceIntPerc(cep.calcd_cvb_mol_tot_perc)}}</span>
            </div>
          </div>
          <hr>
          <div class="outer-chart-wrapper">
            <div class="radar-chart-wrapper" ref="radar_chart"></div>
          </div>
          <div class="chart-legend-wrapper">
            <div>
              <span class="label proj">Progetto corrente</span>
            </div>
            <div>
              <span class="label avg">Media dei progetti</span>
            </div>
          </div>
          <hr>
          <h3>Prezzo di vendita</h3>
          <span class="big-value">{{$niceMoney(cep.calcd_prezzo_vendita_tot_eur)}}</span>
          <p style="font-size:12px;color:red !important">TDB: alert & warnings</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as helperFunctions from "../../helpers/helpers"

  import * as am5 from '@amcharts/amcharts5'
  import * as am5percent from '@amcharts/amcharts5/percent'
  import * as am5radar from '@amcharts/amcharts5/radar'
  import * as am5xy from "@amcharts/amcharts5/xy";
  import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'

  export default {
    props: {
      cep: Object,
      users: Array,
      cep_lines: Object,
      item_general_kinds: Array,
      stip_act_types: Array,
      prod_others_types: Array,
      partners: Array,
      avg_values: Object,
    },

    data(){
      return {
        expanded_boxes: [],

        pie_chart: null,
        radar_chart: null,
      }
    },

    created(){
      if(this.cep.calcd_01_tot_eur>0){
        this.expanded_boxes.push('sales_01');
      }
      if(this.cep.calcd_02_extra_margine_tot_eur>0){
        this.expanded_boxes.push('sales_02');
      }
      if(this.cep.calcd_03_extra_margine_bonus_tot_eur>0){
        this.expanded_boxes.push('sales_03');
      }
      if(this.cep.calcd_11_direzione_tot_eur>0){
        this.expanded_boxes.push('direzione_11');
      }
      if(this.cep.calcd_21_prod_team_stip_tot_eur>0){
        this.expanded_boxes.push('produzione_21');
      }
      if(this.cep.calcd_22_prod_team_piva_tot_eur>0){
        this.expanded_boxes.push('produzione_22');
      }
      if(this.cep.calcd_23_prod_fornit_piva_tot_eur>0){
        this.expanded_boxes.push('produzione_23');
      }
      if(this.cep.calcd_24_prod_media_tot_eur>0){
        this.expanded_boxes.push('produzione_24');
      }
      if(this.cep.calcd_25_prod_others_tot_eur>0){
        this.expanded_boxes.push('produzione_25');
      }
      if(this.cep.calcd_12_gest_tot_eur>0){
        this.expanded_boxes.push('gestione_12');
      }
      if(this.cep.calcd_3_struct_tot_eur>0){
        this.expanded_boxes.push('struttura_30');
      }
      if(this.cep.calcd_4_brand_tot_eur>0){
        this.expanded_boxes.push('brand_40');
      }
      if(this.cep.in_mar_extra_margine_eur>0){
        this.expanded_boxes.push('margine_50');
      }
      if(this.cep.calcd_51_mar_tot_eur>0){
        this.expanded_boxes.push('margine_51');
      }
      if(this.cep.calcd_53_mar_fee_tot_eur>0){
        this.expanded_boxes.push('margine_53');
      }
    },

    mounted(){
      this.renderPieChart();
      this.renderRadarChart();
    },

    computed: {
      eas: function(){
        return this.users.filter((item) => { return item.is_ea; }).map((item) => { return {label: this.$niceUserFullName(item), value: item.id, localized_label: this.$niceUserFullName(item)}; });
      },
      pls: function(){
        return this.users.filter((item) => { return item.is_pl; }).map((item) => { return {label: this.$niceUserFullName(item), value: item.id, localized_label: this.$niceUserFullName(item)}; });
      },

      eas_pls: function(){
        var t_arr = this.eas.concat(this.pls);
        return t_arr.filter((item, pos) => t_arr.indexOf(item)===pos);
      },

      null_or_ea: function(){
        var id = null;
        if(this.cep.ea_id){
          id = this.cep.ea_id;
        }
        else if(this.cep.pl_id){
          id = this.cep.pl_id;
        }
        var t_arr = this.eas_pls.filter((item, pos) => item.value===id);
        return t_arr;
      },

      prod_team_piva_users: function(){
        return this.users.filter((item) => { return item.is_prod_team_piva; }).map((item) => { return {label: this.$niceUserFullName(item), value: item.id, localized_label: this.$niceUserFullName(item)}; });
      },

      extra_margine_partners: function(){
        return this.partners.filter((item) => { return item.has_extra_margin; }).map((item) => { return {label: item.name, value: item.id, localized_label: item.name}; });
      },

      new_sell_partners: function(){
        return this.partners.filter((item) => { return item.is_new_sell_external; }).map((item) => { return {label: item.name, value: item.id, localized_label: item.name}; });
      },

      new_sell_oth_who_list: function(){
        return this.users.filter((item) => { return item.is_new_sell_oth_internal; }).map((item) => { return {label: this.$niceUserFullName(item), value: item.id, localized_label: this.$niceUserFullName(item)}; });
      },

      avg_calcd_csp_fissi_tot_perc: function(){
        return this.avg_values.avg_fissi_prod_int_tot_perc + this.avg_values.avg_fissi_mol_tot_perc;
      },

      avg_calcd_csp_var_tot_perc: function(){
        return this.avg_values.avg_var_segnal_tot_perc + this.avg_values.avg_var_ea_tot_perc + this.avg_values.avg_var_prod_int_piva_tot_perc + this.avg_values.avg_var_prod_est_piva_tot_perc + this.avg_values.avg_var_prod_other_est_perc;
      },
    },

    methods: {
      ...helperFunctions,

      getRelativePerc(type, value){
        var perc = 0;
        switch(type){
          case 'fissi':
            if(this.cep.calcd_csp_fissi_tot_perc!=0){
              perc = value/this.cep.calcd_csp_fissi_tot_perc*100;
            }
            break;
          case 'variabili':
            if(this.cep.calcd_csp_var_tot_perc!=0){
              perc = value/this.cep.calcd_csp_var_tot_perc*100;
            }
            break;
          case 'avg_fissi':
            if(this.avg_calcd_csp_fissi_tot_perc!=0){
              perc = value/this.avg_calcd_csp_fissi_tot_perc*100;
            }
            break;
          case 'avg_variabili':
            if(this.avg_calcd_csp_var_tot_perc!=0){
              perc = value/this.avg_calcd_csp_var_tot_perc*100;
            }
            break;
          default:break;
        }
        return this.$roundOneDecimal(perc);
      },

      renderPieChart(){
        if(this.pie_chart){
          this.pie_chart.dispose();
        }

        var pie_chart = am5.Root.new(this.$refs.pie_chart);
        pie_chart.setThemes([am5themes_Animated.new(pie_chart)]);

        var chart = pie_chart.container.children.push(
          am5percent.PieChart.new(pie_chart, {
            radius: am5.percent(70),
            innerRadius: am5.percent(85)
          })
        );

        // Define data
        var data = [
          {
            category: 'Costi commerciali',
            value: this.cep.calcd_cvb_comm_tot_perc,
          },
          {
            category: 'Costi di direzione',
            value: this.cep.calcd_cvb_dir_tot_perc,
          },
          {
            category: 'Costi di produzione',
            value: this.cep.calcd_cvb_prod_tot_perc,
          },
          {
            category: 'MOL',
            value: this.cep.calcd_cvb_mol_tot_perc,
          },
        ];

        var series = chart.series.push(
          am5percent.PieSeries.new(pie_chart, {
            name: 'Series',
            categoryField: 'category',
            valueField: 'value'
          })
        );

        series.get('colors').set('colors', [
          am5.color(0x9B49ED),
          am5.color(0x88A9F2),
          am5.color(0x42C38C),
          am5.color(0xE8B62E)
        ]);

        series.data.setAll(data);

        series.labels.template.set('visible', false);
        series.ticks.template.set('visible', false);

        this.pie_chart = pie_chart;
      },

      renderRadarChart(){
        if(!this.is_test){
          if(this.radar_chart){
            this.radar_chart.dispose();
          }

          var radar_chart = am5.Root.new(this.$refs.radar_chart);
          radar_chart.setThemes([am5themes_Animated.new(radar_chart)]);

          var chart = radar_chart.container.children.push(
            am5radar.RadarChart.new(radar_chart, {
              panX: false,
              panY: false,
              radius: am5.percent(90),
              innerRadius: am5.percent(10),
            })
          );

          var xRenderer = am5radar.AxisRendererCircular.new(radar_chart, {});
          xRenderer.labels.template.setAll({
            radius: 10
          });

          var xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(radar_chart, {
              maxDeviation: 0,
              categoryField: 'category',
              renderer: xRenderer
            })
          );

          xAxis.get('renderer').labels.template.adapters.add('fill', (fill, target) => {
            var color = '#9B49ED';
            if(target.dataItem && target.dataItem.dataContext){
              var category = target.dataItem.dataContext.category;
              switch(category){
                case 'Costi commerciali':
                  color = '#9B49ED';
                  break;
                case 'Costi di direzione':
                  color = '#88A9F2';
                  break;
                case 'Costi di produzione':
                  color = '#42C38C';
                  break;
                case 'MOL':
                  color = '#E8B62E';
                  break;
                default:break;
              }
            }
            return color;
          });

          var yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(radar_chart, {
              renderer: am5radar.AxisRendererRadial.new(radar_chart, {})
            })
          );

          yAxis.get('renderer').labels.template.setAll({
            visible: false
          });

          // Define data
          var data = [
            {
              category: 'Costi commerciali',
              value: parseFloat(this.cep.calcd_cvb_comm_tot_perc),
              avg_value: this.avg_values.avg_comm_tot_perc,
            },
            {
              category: 'Costi di direzione',
              value: parseFloat(this.cep.calcd_cvb_dir_tot_perc),
              avg_value: this.avg_values.avg_dir_tot_perc,
            },
            {
              category: 'Costi di produzione',
              value: parseFloat(this.cep.calcd_cvb_prod_tot_perc),
              avg_value: this.avg_values.avg_prod_tot_perc,
            },
            {
              category: 'MOL',
              value: parseFloat(this.cep.calcd_cvb_mol_tot_perc),
              avg_value: this.avg_values.avg_mol_tot_perc,
            },
          ];

          var series = chart.series.push(
            am5radar.RadarLineSeries.new(radar_chart, {
              name: 'Series',
              xAxis: xAxis,
              yAxis: yAxis,
              categoryXField: 'category',
              valueYField: 'value',
              connectEnds: true,
              stroke: am5.color('#981869'),
              tooltip: am5.Tooltip.new(radar_chart, {
                labelText: "{categoryX}: {valueY}"
              })
            })
          );

          series.strokes.template.setAll({
            strokeWidth: 2
          });

          var avg_series = chart.series.push(
            am5radar.RadarLineSeries.new(radar_chart, {
              name: 'AVG Series',
              xAxis: xAxis,
              yAxis: yAxis,
              categoryXField: 'category',
              valueYField: 'avg_value',
              connectEnds: true,
              stroke: am5.color('#aaaaaa'),
              tooltip: am5.Tooltip.new(radar_chart, {
                labelText: "{categoryX}: {valueY}"
              })
            })
          );

          avg_series.strokes.template.setAll({
            strokeWidth: 2
          });

          series.data.setAll(data);
          avg_series.data.setAll(data);
          xAxis.data.setAll(data);

          this.radar_chart = radar_chart;
        }
      },

      toggleExpandBox(box){
        var index = this.expanded_boxes.indexOf(box);
        if(index>=0){
          this.expanded_boxes.splice(index, 1);
        }
        else{
          this.expanded_boxes.push(box);
        }
      },
    }
  }
</script>