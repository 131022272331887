<template>
  <div>
    <h1>
      Bentornato, <br>ecco la CEA {{cea.year}}
    </h1>
    <div class="tabs-h-wrapper">
      <span :class="active_tab=='co' ? 'active' : ''" @click="selectTab('co')">Consuntivo</span>
      <span :class="active_tab=='an' ? 'active' : ''" @click="selectTab('an')">Andamento</span>
      <span :class="active_tab=='bi' ? 'active' : ''" @click="selectTab('bi')">Bilancino</span>
      <span :class="active_tab=='cm' ? 'active' : ''" @click="selectTab('cm')">Commesse</span>
    </div>
    <div class="tabs-c-wrapper">
      <div :class="active_tab=='co' ? 'active-tab' : ''">
        <cea-co
          :cea="cea"
          :ceps="ceps"
          :comp_bi_values="comp_bi_values"
          :users="users"
          ref="cea_co"
        ></cea-co>
      </div>
      <div :class="active_tab=='an' ? 'active-tab' : ''">
        <cea-an
          :cea="cea"
          :h_values="h_values"
          :comp_co_values="comp_co_values"
          ref="cea_an"
        ></cea-an>
      </div>
      <div :class="active_tab=='bi' ? 'active-tab' : ''">
        <cea-bi
          :cea="cea"
          :bi_values="bi_values"
          :comp_co_values="comp_co_values"
          @updateValues="updateBiValues()"
          ref="cea_bi"
        ></cea-bi>
      </div>
      <div :class="active_tab=='cm' ? 'active-tab' : ''">
        <cea-cm
          :cea="cea"
          :users="users"
          :cep_statuses="cep_statuses"
          ref="cea_cm"
        ></cea-cm>
      </div>
    </div>
  </div>
</template>

<script>
  import * as helperFunctions from "../../helpers/helpers"

  export default {
    props: {
      cea: Object,
      ceps: Array,
      h_values: Array,
      bi_values: Array,
      users: Array,
      cep_statuses: Array,
    },

    data(){
      return {
        active_tab: 'co',

        comp_co_values: {
          calcd_cons_tot_eur: 0,
          calcd_det_cons_segn_eur: 0,
          calcd_det_cons_ea_eur: 0,
          calcd_det_cons_prod_int_piva_eur: 0,
          calcd_det_cons_prod_ext_piva_eur: 0,
          calcd_det_cons_oth_prod_ext_eur: 0,
          calcd_det_cons_prod_int_eur: 0,
          calcd_det_cons_mol_eur: 0,
        },

        comp_bi_values: {
          calcd_fixed_costs: 0,
        },
      }
    },

    mounted(){
      this.updateBiValues();
      this.updateCoValues();
    },

    methods: {
      ...helperFunctions,

      selectTab(tab){
        if(this.active_tab!=tab){
          this.active_tab = tab;
        }
      },

      updateCoValues(){
        // For BI
          this.comp_co_values.calcd_det_cons_segn_eur = this.$refs.cea_co.calcd_det_cons_segn_eur;
          this.comp_co_values.calcd_det_cons_ea_eur = this.$refs.cea_co.calcd_det_cons_ea_eur;
          this.comp_co_values.calcd_det_cons_prod_int_piva_eur = this.$refs.cea_co.calcd_det_cons_prod_int_piva_eur;
          this.comp_co_values.calcd_det_cons_prod_ext_piva_eur = this.$refs.cea_co.calcd_det_cons_prod_ext_piva_eur;
          this.comp_co_values.calcd_det_cons_oth_prod_ext_eur = this.$refs.cea_co.calcd_det_cons_oth_prod_ext_eur;
          this.comp_co_values.calcd_det_cons_prod_int_eur = this.$refs.cea_co.calcd_det_cons_prod_int_eur;
          this.comp_co_values.calcd_det_cons_mol_eur = this.$refs.cea_co.calcd_det_cons_mol_eur;
        // For AN
          this.comp_co_values.calcd_cons_tot_eur = this.$refs.cea_co.calcd_cons_tot_eur;
          this.comp_co_values.calcd_det_cons_t_tot_perc = this.$refs.cea_co.calcd_det_cons_t_tot_perc;
          this.comp_co_values.calcd_comm_effort_tot_eur = this.$refs.cea_co.calcd_comm_effort_tot_eur;
          this.comp_co_values.calcd_cons_impon_eur = this.$refs.cea_co.calcd_cons_impon_eur;
          this.comp_co_values.calcd_det_cons_mol_perc = this.$refs.cea_co.calcd_det_cons_mol_perc;
          this.comp_co_values.calcd_trat_impon_eur = this.$refs.cea_co.calcd_trat_impon_eur;
          this.comp_co_values.calcd_det_cons_avg_daily_rate_eur = this.$refs.cea_co.calcd_det_cons_avg_daily_rate_eur;
          this.comp_co_values.calcd_conv_rate = this.$refs.cea_co.calcd_conv_rate;
          this.comp_co_values.calcd_inte_impon_eur = this.$refs.cea_co.calcd_inte_impon_eur;
      },

      updateBiValues(){
        this.comp_bi_values.calcd_fixed_costs = this.$refs.cea_bi.calcd_fixed_costs;
      },
    }
  }
</script>